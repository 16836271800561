import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DrOption from './DrOption';

const propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  selectOption: PropTypes.func,
  options: PropTypes.array,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
};

const defaultProps = {
  open: false,
  options: null,
};
function DropdownMenu(props) {
  // receive options from Dropdown component
  const {
    open,
    selected,
    selectOption,
    children,
    placeholder,
    options,
    heading,
    ...attributes
  } = props;

  const menuClass = classNames(
    `prism-select__menu-${open ? 'open' : 'closed'}`
  );

  const normalizeOptions = (options, placeholder, selected, selectOption) => options.map((option) => (
    <DrOption
      selectOption={selectOption}
      selected={selected}
      group={placeholder}
      name={option.name}
      value={option.value}
      key={option.name}
      disabled={option.disabled}
    />
  ));

  const childrenWithProps = React.Children.map(children, (child) => React.cloneElement(child, {
    selectOption,
    selected,
    label: child.props.label || child.props.name || placeholder,
    disabled: child.props.disabled,
    id: child.props.id || null,
    children: child.props.children,
  }));

  return (
    <div role="listbox" className={menuClass} {...attributes}>
      {(options && placeholder) || (
        <span className="prism-select__menu-heading">{placeholder}</span>
      )}
      {childrenWithProps}
    </div>
  );
}

DropdownMenu.propTypes = propTypes;
DropdownMenu.defaultProps = defaultProps;
export default DropdownMenu;
