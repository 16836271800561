import Accordion from './Accordion';
import AccordionInput from './AccordionInput';
import AccordionItem from './AccordionItem';

Accordion.Input = AccordionInput;
Accordion.Item = AccordionItem;

export {
  AccordionInput,
  AccordionItem,
};

export default Accordion;
