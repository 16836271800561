import React from 'react';
import classnames from 'classnames';
import { nanoid } from 'nanoid';

export function useCard(props) {
  const {
    border = true,
    height,
    width,
    className,
    horizontal = false,
    mediaWidth,
    media,
    mediaContent,
    mediaGutter,
    compact = false,
    dark = false,
    children,
    title = undefined,
    content = undefined,
    ...HTMLProps
  } = props;

  /**
   * @remark - This allows us to pass single keys of the actions prop object without having to
   * strinctly null check each instance at runtime. We keep this as a memo to avoid
   * arbitrary callbacks leading to a render loop. - Darin Cassler 2021-09-26
   */
  const actions = React.useMemo(
    () => ({
      left: [],
      right: [],
      media: [],
      body: [],
      ...props.actions,
    }),
    [props.actions]
  );

  /**
   * @remark - Compute some helper values to provide via our hook and that will be helpful
   * when creating our props in `elementProps` - Darin Cassler 2021-09-26
   */
  const flags = {
    hasMedia: !!media,
    hasBody: !!props.children || !!content || !!title,
    hasFooter: [...actions.left, ...actions.right].length > 0,
    mediaGutter: props.mediaGutter || props.gutter || 'none',
  };

  const padStyle = { padding: `${compact ? '4px' : '8px'}` };

  /**
   * @remark - Here we are building out props to spread across our child components.
   * The goal is to consolidate these computed values into a single object that can be
   * accessed via our hook. - Darin Cassler 2021-09-26
   */
  const elementProps = {
    // Properties to spread across the parent element of a card including any HTML attributes
    card: {
      className: classnames('card', className, {
        vertical: !horizontal,
        horizontal: !!horizontal,
        'has-media': !!media,
        compact: !!compact,
        'bg-dark': dark,
      }),
      style: {
        borderWidth: border ? 1 : 0,
        borderColor: typeof border === 'string' ? border : undefined,
        height,
        width,
      },
      ...HTMLProps,
    },
    // Properties to spread across the Card Header element of a card.
    header: {
      className: classnames('card-header', { 'text-white': dark }),
      style: padStyle,
    },
    body: {
      className: classnames('card-body', { 'text-white': dark }),
      style: padStyle,
      title: props.title,
      hasBody: flags.hasBody,
      gutter: props.gutter,
      actions: actions.body || [],
    },
    // Properties to spread across the Card Layout element of a card.
    layout: {
      className: classnames('card-layout', {
        'text-white': dark,
        'layout-vertical': !horizontal,
        'layout-horizontal': !!horizontal,
      }),
    },
    // Properties to spread across the Card Footer element of a card
    footer: {
      className: classnames('card-footer', { 'text-white': dark }),
      style: padStyle,
      hasFooter: flags.hasFooter,
      actionsLeft: actions.left || [],
      actionsRight: actions.right || [],
    },
    media: {
      mediaIsBackground: false,
      src: media,
      hasMedia: !!media,
      mediaGutter: flags.mediaGutter,
      actions: actions.media || [],
      children: <>{mediaContent}</>,
      className: 'card-media',
      style: { width: horizontal ? mediaWidth || '12rem' : 'auto' },
    },
  };

  /**
   * @remark - Introduce the NanoID library to Prism. This is a unique ID generator with an
   * import cost of 359 bytes gziped. - Darin Cassler 2021-09-26
   */
  const id = React.useRef(props.id || `prism-card-${nanoid()}`);

  /**
   * @remark - Quickly determine our body content showing children first and then content.
   * Content can be any ReactNode or a string. Strings will be wrapped in a `<p>` tag.
   * - Darin Cassler 2021-09-26
   */
  const propContent = typeof props.content === 'string' ? <p>{props.content}</p> : props.content;
  const innerContent = children || propContent;

  return {
    elementProps,
    flags,
    id: id.current,
    innerContent: innerContent || null,
    mediaContent: mediaContent || null,
    HTMLProps,
    ...props,
  };
}

export default useCard;
