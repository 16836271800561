import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIsomorphicLayoutEffect } from 'react-use';
import Tooltip from './Tooltip';

const TooltipWrapper = ({ disabled, target, ...props }) => {
  const [computedTarget, setComputedTarget] = useState();

  useIsomorphicLayoutEffect(() => {
    let newComputedTarget;
    if (target.current) {
      newComputedTarget = target.current;
    } else if (typeof target === 'function') {
      newComputedTarget = target();
    } else if (typeof target === 'string') {
      newComputedTarget = document.getElementById(target);
    } else {
      newComputedTarget = target;
    }
    setComputedTarget(newComputedTarget);
  }, [target]);

  return !disabled && computedTarget ? (
    <Tooltip target={computedTarget} {...props} />
  ) : null;
};

TooltipWrapper.propTypes = {
  disabled: PropTypes.bool,
  target: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node,
    PropTypes.shape({ current: PropTypes.element }),
  ]),
};

TooltipWrapper.defaultProps = { disabled: false, target: null };

export default TooltipWrapper;
