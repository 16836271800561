import React from 'react';
import PropTypes from 'prop-types';

export const Space = (props) => {
  const { style, size, vertical, children, ...attributes } = props;
  const computedStyles = {
    display: 'flex',
    flexDirection: vertical ? 'column' : 'row',
    gap: `${size * 8}px`,
    ...style
  };
  return (
    <div className={vertical ? 'column' : 'row'} {...attributes} style={computedStyles}>
      {children}
    </div>
  );
};
Space.propTypes = {
  vertical: PropTypes.bool,
  size: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.object]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Space.defaultProps = {
  vertical: false,
  size: 2,
  children: null,
  style: null,
};

export default Space;
