import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import {
  AsYouType,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from 'libphonenumber-js';
import { FormFeedback } from '@prism/form';
import Label from './Label';
import Input from './Input';

const PhoneInput = (props) => {
  const {
    ariaLabel,
    className,
    icon,
    id,
    invalid,
    label,
    onChange,
    readonly,
    ...attributes
  } = props;

  const [isValid, setIsValid] = useState(!invalid);
  const [value, setValue] = useState('');
  const [formatted, setFormatted] = useState('');

  const validatePhoneNumber = (strict = false) => {
    if (!isPossiblePhoneNumber(value, 'US')) {
      setIsValid(strict ? false : value.length < 10);
    } else {
      setValue(formatted);
      setIsValid(isValidPhoneNumber(formatted, 'US'));
    }
  };

  useEffect(() => {
    validatePhoneNumber();
  }, [value, formatted]);

  const handleChange = (e) => {
    // Before there are 10 characters, do nothing
    const inputValue = new AsYouType('US').input(`${e.target.value}`);
    // convert "(123) 456-7890" to "123-456-7890"
    const manValue = inputValue
      .replace('(', '')
      .replace(')', '')
      .replace(' ', '-');
    setFormatted(manValue);
    setValue(e.target.value);
    onChange(e);
  };

  const handleBlur = () => {
    validatePhoneNumber(true);
  };

  const inputClassName = classNames({
    'input-phone': true,
    'form-control': true,
    [className]: className,
    'text-input__has-icon-left': !readonly && icon,
  });

  return (
    <>
      {label && <Label htmlFor={id}>{label}</Label>}
      <div className="input-group align-items-center mb-3">
        {(!readonly && icon) && <i className="icon text-input__icon-left prism-icon-mobile" />}
        <Input
          {...attributes}
          aria-label={ariaLabel}
          className={inputClassName}
          id={id}
          invalid={!isValid}
          onBlur={handleBlur}
          onChange={handleChange}
          readonly={readonly}
          type="phone"
          valid={false}
          value={value}
        />
        <FormFeedback valid={isValid}>
          This is not a valid 10-digit phone number.
        </FormFeedback>
      </div>
    </>
  );
};

PhoneInput.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.bool,
  id: PropTypes.string,
  invalid: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
};

PhoneInput.defaultProps = {
  ariaLabel: 'phoneNumber',
  className: '',
  icon: true,
  id: uuid(),
  invalid: false,
  label: undefined,
  onChange: () => {},
  readonly: false,
};

PhoneInput.displayName = 'PhoneInput';

export default PhoneInput;
