import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import Checkbox from '@prism/checkbox';
import Chip, { ChipColors } from '@prism/chip';
import { SearchableContext } from './context/SearchableContext';

function SearchableList({
  data,
  selectAll,
  heading,
  icon,
  onChange,
  disabled,
  chipColor,
  deSelectAll,
  ...attributes
}) {
  const { searchValue } = React.useContext(SearchableContext);
  const [items, setItems] = React.useState(() => data.map((i, idx) => ({ 
    ...i,
    // add indeces at first render
    idx,
  })));

  function withIndex(arr) {
    return arr.map((i, idx) => ({ 
      ...i, 
      idx 
    }))
  }
  
  
  React.useEffect(() => {
    // Check that we have data, so we don't try to iterate through undefined.
    if (!data) return null;
    // If data is updated by the parent, add indeces.
    setItems(withIndex(data));
  }, [data]);

  // Handle Checkbox interaction, returns both the updated data item
  // and the updated list to the parent component via callback.
  const handleCheckChange = (e, idx) => {
    // If there's no item w/ matching index, return item, otherwise toggle item's checked property
    const newList = items.map((l, i) => (i === idx ? { ...l, checked: !l.checked } : l));
    // Update the list. Without this, focus changes on the search will revert items to initial state
    setItems(newList);
    // OUT - send new list state to parent
    onChange(e, newList);
  };

  // provide filtered values as const so they persist even when hidden
  const filteredItems = React.useMemo(() => items.filter((item) => {
    const sV = searchValue.toLowerCase();
    return item.label.toLowerCase().indexOf(sV) > -1 || item.checked;
  }), [items, searchValue]);

  return (
    <div className="searchable-list__container" role="list" {...attributes}>
      <div className="searchable-list__heading">
        {icon && !searchValue.length > 0 && (
          <span
            className={`searchable-list__heading-icon icon prism-icon-${icon}`}
          />
        )}
        {heading && !searchValue.length > 0 && heading}
      </div>
      <div className="check-list">
        {filteredItems && filteredItems.map((item) => (
          <div key={uuid()} className="check-list__item" role="listitem">
            <Checkbox
              disabled={disabled || item.disabled}
              defaultChecked={item.checked || selectAll}
              onChange={(e) => handleCheckChange(e, item.idx)}
              className="check-list__item"
              role="checkbox"
              {...item}
            />
            {item.count && item.count > 0 && (
            <Chip color={chipColor} className="filter-count">
              {item.count}
            </Chip>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}


SearchableList.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func,
  heading: PropTypes.string,
  icon: PropTypes.string,
  selectAll: PropTypes.bool,
  deSelectAll: PropTypes.bool,
  disabled: PropTypes.bool,
  chipColor: PropTypes.oneOf(ChipColors),
};

SearchableList.defaultProps = {
  onChange: () => {},
  data: [],
  heading: null,
  icon: null,
  selectAll: null,
  deSelectAll: null,
  disabled: false,
  chipColor: ChipColors[1], // "medium"
};

export default SearchableList;
