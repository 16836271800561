/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { useAvatar } from './useAvatar';

const Avatar = (props) => {
  const { Tag, tagProps, avatarContent, textContent } = useAvatar(props);
  return (
    <Tag {...tagProps}>
      {avatarContent}
      {textContent}
    </Tag>
  );
};

Avatar.propTypes = {
  size: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  name: PropTypes.string,
  initials: PropTypes.string,
  image: PropTypes.string,
  href: PropTypes.string,
  inlineText: PropTypes.string,
};

Avatar.defaultProps = {
  firstName: '',
  lastName: '',
  name: undefined,
  initials: undefined,
  image: undefined,
  href: undefined,
  size: 'sm',
  inlineText: undefined,
};

Avatar.displayName = 'avatar';

export default Avatar;
