import React from 'react';
import PropTypes from 'prop-types';
import Button from '@prism/button';

const propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  index: PropTypes.number,
  currentSlide: PropTypes.object,
};

const defaultProps = {
  className: '',
  onClick: () => {},
};

const MediaViewerButton = (props) => {
  const { icon, onClick, className, index } = props;
  const { currentSlide, ...htmlProps } = props;

  const handleClick = (e) => {
    if (!onClick) {
      return;
    }
    e.data = { ...currentSlide, index };
    onClick(e);
  };

  return (
    <Button
      icononly
      variant="textonly"
      {...htmlProps}
      className={`btn-media ${className}`}
      onClick={handleClick}
    >
      <i className={`icon prism-icon-${icon}`} />
    </Button>
  );
};

MediaViewerButton.propTypes = propTypes;
MediaViewerButton.defaultProps = defaultProps;

export default MediaViewerButton;
