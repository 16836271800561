import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FLAG_CANADA = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAICAMAAADHqI+lAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABBVBMVEX+AAL+AQP/AAD4FBX86+v//////v78+Pj8+Pj//v786+v4FBX+AQP+AQP4FBX86+r8+fnxeHjxeHj8+fn86ur4FBX+AQP+AQP/AAD4FBb86Oj67Ozxw8P3Cwv3Cwvxw8P67Ov76Oj4FBb+AQP/AAD4FRb56envMDH1Fxj6ExX6ExX1FxjvMDH56en4FRb/AAD+AAL+AQP/AAD4ExT78O/zaWr8AAD/AgL/AgL9AADzaWr77+/4ExX/AAD4FBX86ur+///wX2D0IiP0IiPwX2D+///86ur4ExX4FBX86+v+//713Nz13Nz+//786+v4FBX4FBX86+v56ur66ur86+v4FBUAAABZmXChAAAAAWJLR0RWCg3piQAAAAlwSFlzAAAOwwAADsMBx2+oZAAAAAd0SU1FB+MFHA0tKJNsxZ0AAACASURBVAjXY2BgZGJmYWVj5+Bk5eJm4mFgYOBl4uNnFRAUEmYVEWUSAwqIS0hKScvIyskrKCoxKQMFGFVU1dQ1NLW0dXT19IFaDAyNjE1MzcwtLK2sbWzFwGbY2Ts4Ojm7uLq5gw1lZPLwZPXy9vFl9fOHCgQEsrIGBbOyhoQCBQAYUA7fLifVRgAAAEF0RVh0Y29tbWVudABDUkVBVE9SOiBnZC1qcGVnIHYxLjAgKHVzaW5nIElKRyBKUEVHIHY4MCksIHF1YWxpdHkgPSA3MAqw+vGNAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE5LTA1LTI4VDEzOjQ1OjQwLTA0OjAw5vPQ8wAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOS0wNS0yOFQxMzo0NTo0MC0wNDowMJeuaE8AAAAASUVORK5CYII=';

const IndicatorFlag = (props) => {
  const { type, label, color, customText } = props;

  const infoLabels = [
    'NEW',
    'BETA',
    'UPDATED',
    'CERTIFIED',
    'SPECIAL',
    'REDUCED',
    'PRIOR',
  ];
  const dangerLabels = ['SALVAGE', 'AS-IS'];

  const isCustomLabel = infoLabels.indexOf(label) < 0 && dangerLabels.indexOf(label) < 0;

  const cname = 'prism-indicator--flag';
  const className = classNames({
    [cname]: true,
    [`${cname}--corner`]: type === 'TYPE_CORNER',
    [`${cname}--ribbon`]: type === 'TYPE_RIBBON',
    info: (infoLabels.indexOf(label) >= 0) || (isCustomLabel && !color),
    danger: dangerLabels.indexOf(label) >= 0,
    'has-flag': label === 'PRIOR',
    [color]: isCustomLabel && color,
  });

  const computedLabel = isCustomLabel ? customText : label;

  return (
    <div className={`${cname}--wrapper`} role="note">
      <div className={className}>
        {computedLabel}
        {label === 'PRIOR' && <span className="flag"><img src={FLAG_CANADA} alt="Canadian flag" /></span>}
      </div>
    </div>
  );
};

export const IndicatorFlagLabels = [
  'NEW',
  'BETA',
  'UPDATED',
  'CERTIFIED',
  'SPECIAL',
  'REDUCED',
  'SALVAGE',
  'AS-IS',
  'PRIOR',
  'CUSTOM',
];

export const IndicatorFlagTypes = ['TYPE_RIBBON', 'TYPE_CORNER'];

IndicatorFlag.propTypes = {
  color: PropTypes.string,
  customText: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(IndicatorFlagTypes),
};

IndicatorFlag.defaultProps = {
  color: undefined,
  customText: undefined,
  label: null,
  type: 'TYPE_RIBBON',
};

IndicatorFlag.TYPE_RIBBON = 'TYPE_RIBBON';
IndicatorFlag.TYPE_CORNER = 'TYPE_CORNER';
IndicatorFlag.LABEL_NEW = 'NEW';
IndicatorFlag.LABEL_BETA = 'BETA';
IndicatorFlag.LABEL_UPDATED = 'UPDATED';
IndicatorFlag.LABEL_CERTIFIED = 'CERTIFIED';
IndicatorFlag.LABEL_SPECIAL = 'SPECIAL';
IndicatorFlag.LABEL_REDUCED = 'REDUCED';
IndicatorFlag.LABEL_SALVAGE = 'SALVAGE';
IndicatorFlag.LABEL_AS_IS = 'AS-IS';
IndicatorFlag.LABEL_PRIOR = 'PRIOR';
IndicatorFlag.LABEL_CUSTOM = 'CUSTOM';

export default IndicatorFlag;
