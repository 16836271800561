import React, { createContext } from 'react';
import { string } from 'prop-types';
import { visibleFilmstripSlides } from '@prism/filmstrip/utils';
import { naturalCountingNumber } from '../ImageViewer/utils';
import { FIRST_INDEX, FILMSTRIP } from '../../types/constants';

const MultimediaViewerCtx = createContext({
  updateCurrentSlide: () => {},
  currentSlide: 0,
  filmstripCarouselStore: null,
  imageViewerCarouselStore: null,
  testLoad: () => {},
  selectedFilmstripSlide: 0,
});

export class MultimediaViewerProvider extends React.Component {
  // TODO: this.setState triggers a console warning
  constructor(props) {
    super(props);
    this.testLoad = this.testLoad.bind(this);
    this.updateCurrentSlide = this.updateCurrentSlide.bind(this);
    this.state = {
      currentSlide: 0,
      updateCurrentSlide: this.updateCurrentSlide,
      testLoad: this.testLoad,
      filmstripCarouselStore: null,
      imageViewerCarouselStore: null,
      selectedFilmstripSlide: 0,
      filmstripRef: React.createRef(),
    };
  }

  testLoad(carouselStoreState) {
    if (
      !this.state.imageViewerCarouselStore
      && carouselStoreState.hasOwnProperty('imageViewerCarouselStore')
    ) {
      this.setState(carouselStoreState);
    }
    if (
      !this.state.filmstripCarouselStore
      && carouselStoreState.hasOwnProperty('filmstripCarouselStore')
    ) {
      this.setState(carouselStoreState);
    }
  }

  updateCurrentSlide(index, stores = ['imageViewerCarouselStore']) {
    const { carouselType } = this.props;
    const { filmstripRef } = this.state;
    const {
      totalSlides,
      currentSlide,
    } = this.state.filmstripCarouselStore.getStoreState();
    const slideToTransitionTo = (totalSlides + index) % totalSlides;
    this.setState({ currentSlide: slideToTransitionTo });
    stores.forEach((store) => {
      if (this.state[store]) {
        this.state[store].setStoreState({ currentSlide: slideToTransitionTo });
      }
    });
    const htmlVisibleFilmstripSlides = visibleFilmstripSlides(
      carouselType,
      filmstripRef.current.firstChild
    );

    const totalSlidesExceedsVisibleSlides = totalSlides > htmlVisibleFilmstripSlides;
    const selectedFilmstripSlideIsLastSlide = this.state.selectedFilmstripSlide === totalSlides - 1;
    const selectingLastSlide = naturalCountingNumber(slideToTransitionTo) === totalSlides;
    const selectedFirstSlide = this.state.selectedFilmstripSlide === FIRST_INDEX;
    const selectingFirstSlide = slideToTransitionTo === FIRST_INDEX;
    const selectedSlideIsOutOfViewOnTheRight = currentSlide + htmlVisibleFilmstripSlides <= slideToTransitionTo;
    const selectedSlideIsOutOfViewOnTheLeft = currentSlide - 1 >= slideToTransitionTo;
    if (totalSlidesExceedsVisibleSlides) {
      const newCurrentSlideState = {};
      if (selectingLastSlide && selectedFirstSlide) {
        newCurrentSlideState.currentSlide = totalSlides - htmlVisibleFilmstripSlides;
      } else if (selectedFilmstripSlideIsLastSlide && selectingFirstSlide) {
        newCurrentSlideState.currentSlide = FIRST_INDEX;
      } else if (selectedSlideIsOutOfViewOnTheRight) {
        const maximumCurrentSlide = totalSlides - htmlVisibleFilmstripSlides;
        const selectedSlide = currentSlide + htmlVisibleFilmstripSlides;
        newCurrentSlideState.currentSlide = Math.min(
          maximumCurrentSlide,
          selectedSlide
        );
      } else if (selectedSlideIsOutOfViewOnTheLeft) {
        const newCurrentSlide = currentSlide - htmlVisibleFilmstripSlides;
        newCurrentSlideState.currentSlide = Math.max(
          FIRST_INDEX,
          newCurrentSlide
        );
      }

      if (typeof newCurrentSlideState.currentSlide !== 'undefined') {
        this.state.filmstripCarouselStore.setStoreState(newCurrentSlideState);
      }
    }

    this.setState({ selectedFilmstripSlide: slideToTransitionTo });
  }

  render() {
    return (
      <MultimediaViewerCtx.Provider value={this.state}>
        {this.props.children}
      </MultimediaViewerCtx.Provider>
    );
  }
}

const defaultProps = { carouselType: FILMSTRIP };

MultimediaViewerProvider.propTypes = { carouselType: string };
MultimediaViewerProvider.defaultProps = defaultProps;

export const MultiMediaViewerConsumer = MultimediaViewerCtx.Consumer;
