import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Table = (props) => {
  const {
    condensed,
    getTableProps,
    headerStyle,
    headerSticky,
    responsive,
    ...attributes
  } = props;

  const className = classNames({
    table: true,
    'table-hover': true,
    'table-sm': condensed,
    [`table-head-${headerStyle}`]: headerStyle,
    'table-head-sticky': headerSticky,
    'table-responsive': responsive,
  });

  return <table className={className} {...getTableProps()} {...attributes} />;
};

Table.propTypes = {
  condensed: PropTypes.bool,
  getTableProps: PropTypes.func,
  headerSticky: PropTypes.bool,
  headerStyle: PropTypes.oneOf(['light', 'dark', 'color']),
  responsive: PropTypes.bool,
};

Table.defaultProps = {
  condensed: false,
  getTableProps: () => {},
  headerSticky: false,
  headerStyle: undefined,
  responsive: false,
};

Table.displayName = 'Table';

export default Table;
