import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import {
  CarouselProvider,
  Slide,
  Slider,
  WithStore,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { MultiMediaViewerConsumer } from '../MultimediaViewer/index';
import { MediaAssetsType } from '../../types';
import { VISIBLE_FILMSTRIP_SLIDES, FILMSTRIP } from '../../types/constants';
import BackForthButton from './BackForthButton';
import Thumbnail from './Thumbnail';
import { getTabIndex, trayProps } from './utils';

const Filmstrip = React.forwardRef((props, ref) => {
  const {
    disabled,
    mediaAssets: propsMediaAssets,
    carouselStore: filmstripCarouselStore,
  } = props;

  const mediaAssets = useMemo(() => propsMediaAssets.map((asset) => ({
    ...asset,
    id: asset.id || uuid(),
  })), [propsMediaAssets]);
  const numberOfAssets = mediaAssets.length;
  const showArrowButtons = numberOfAssets > 1;

  return (
    <div className="filmstrip">
      <MultiMediaViewerConsumer>
        {({ updateCurrentSlide, testLoad, selectedFilmstripSlide }) => (
          <>
            {showArrowButtons && (
              <BackForthButton
                direction="previous"
                disabled={disabled}
                {...{ updateCurrentSlide, selectedFilmstripSlide }}
              />
            )}
            {!!numberOfAssets && (
              <Slider
                className="filmstrip-slider"
                role="list"
                ref={ref}
                onLoad={testLoad({ filmstripCarouselStore })}
                disableKeyboard
                trayProps={trayProps(
                  updateCurrentSlide,
                  filmstripCarouselStore,
                  selectedFilmstripSlide
                )}
              >
                {mediaAssets.map((mediaAsset, index) => (
                  <Slide
                    data-testid="filmstrip-slide"
                    className="filmstrip-slide"
                    role="listitem"
                    index={index}
                    key={mediaAsset.id}
                    tabIndex={getTabIndex(
                      index,
                      filmstripCarouselStore.getStoreState().currentSlide
                    )}
                  >
                    <Thumbnail
                      {...{
                        updateCurrentSlide,
                        testLoad,
                        selectedFilmstripSlide,
                        mediaAsset,
                        disabled,
                        filmstripCarouselStore,
                        index,
                      }}
                      prefix={FILMSTRIP}
                      arrowButtonOffsetText={showArrowButtons}
                    />
                  </Slide>
                ))}
              </Slider>
            )}
            {showArrowButtons && (
              <BackForthButton
                direction="forward"
                disabled={disabled}
                {...{ updateCurrentSlide, selectedFilmstripSlide }}
              />
            )}
          </>
        )}
      </MultiMediaViewerConsumer>
    </div>
  );
});

Filmstrip.propTypes = {
  /**
   * If true, disables back & next arrow buttons and all thumbnails
   */
  disabled: PropTypes.bool,
  /**
   * Array of all media to be displayed in the filmstrip
   */
  mediaAssets: MediaAssetsType,
  /**
   * Context object
   */
  carouselStore: PropTypes.shape({ getStoreState: PropTypes.func }).isRequired,
  /**
   * Function to update the current slide on interation
   */
  updateCurrentSlide: PropTypes.oneOfType([PropTypes.func]),
  /**
   * Function to set the carousel state in carouselStore
   */
  testLoad: PropTypes.oneOfType([PropTypes.func]),
  /**
   * Index of the currently selected slide
   */
  selectedFilmstripSlide: PropTypes.number,
};

Filmstrip.defaultProps = {
  disabled: false,
  mediaAssets: [],
  updateCurrentSlide: null,
  testLoad: null,
  selectedFilmstripSlide: 0,
};

const FilmstripWithCarousel = (props) => {
  const mapCarouselStateToProps = (mediaAssets) => ({
    mediaAssets,
    currentSlide: 0,
    ...props,
  });

  const { mediaAssets } = props;
  const numberOfAssets = mediaAssets.length;

  const FilmstripWithCarouselState = WithStore(
    Filmstrip,
    mapCarouselStateToProps
  );

  return (
    <MultiMediaViewerConsumer>
      {({ filmstripRef }) => (
        <div className="filmstrip-carousel-provider-wrapper" ref={filmstripRef}>
          <CarouselProvider
            className="filmstrip-carousel-provider"
            role="listbox"
            aria-label="filmstrip"
            naturalSlideWidth={102}
            naturalSlideHeight={72}
            visibleSlides={VISIBLE_FILMSTRIP_SLIDES}
            totalSlides={numberOfAssets}
            infinite="true"
            disableKeyboard
          >
            <FilmstripWithCarouselState />
          </CarouselProvider>
        </div>
      )}
    </MultiMediaViewerConsumer>
  );
};

FilmstripWithCarousel.propTypes = {
  /**
   * Array of all media to be displayed in the filmstrip
   */
  mediaAssets: MediaAssetsType,
};

FilmstripWithCarousel.defaultProps = { mediaAssets: [] };

export default FilmstripWithCarousel;
