import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '@prism/pagination';

const TableBottom = (props) => {
  const { gotoPage, pagination } = props;

  if (!pagination || pagination.pageCount < 2) {
    return null;
  }

  const handlePaginationChange = (pageData) => {
    gotoPage(pageData.index - 1 || 0);
  };

  const { pageIndex = 0, pageCount } = pagination;
  const spacer = <span style={{ flexGrow: 1, height: 0 }} />;

  return (
    <div className="table-bottom">
      {spacer}
      <Pagination
        currentPage={pageIndex + 1}
        totalPages={pageCount}
        onChange={handlePaginationChange}
      />
      {spacer}
    </div>
  );
};

TableBottom.propTypes = {
  gotoPage: PropTypes.func,
  pagination: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pageIndex: PropTypes.number,
      pageCount: PropTypes.number,
    }),
  ]),
};

TableBottom.defaultProps = {
  gotoPage: () => {},
  pagination: false,
};

TableBottom.displayName = 'TableBottom';

export default TableBottom;
