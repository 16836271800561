import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormFeedback, FormGroup } from '@prism/form';
import Icon from '@prism/icon';
import { Label } from '@prism/input';
import InputGroup from '@prism/inputgroup';
import ReactDatePicker from 'react-datepicker';
import useDatePicker from '../useDatePicker';

const DatePicker = (props) => {
  const { label, size, readonly, disabled, invalid } = props;

  const datePickerRef = useRef(null);
  const hasMessage = typeof invalid === 'string';

  const {
    className: computedClassName,
    triggerClassName: computedTriggerClassName,
    datePickerProps,
  } = useDatePicker({ ...props, datePickerRef, hasMessage });

  const handleClickIcon = (e) => {
    e.preventDefault();
    // Open date picker
    datePickerRef.current.setOpen(true);
  };

  return (
    <FormGroup className={computedClassName} aria-label="date-picker">
      {label && <Label fontSize={size}>{label}</Label>}
      <InputGroup>
        <ReactDatePicker {...datePickerProps} />
        {!readonly && (
          <button
            type="button"
            className={computedTriggerClassName}
            onClick={handleClickIcon}
            disabled={disabled}
          >
            <Icon glyph="calendar" />
          </button>
        )}
        {invalid && hasMessage && (
          <FormFeedback>
            <Icon glyph="notification-circle" className="small mr-1" />
            {invalid}
          </FormFeedback>
        )}
      </InputGroup>
    </FormGroup>
  );
};

export const DatePickerSizes = ['sm', 'lg'];

DatePicker.propTypes = {
  afterClickOutside: PropTypes.func,
  afterCalendarClose: PropTypes.func,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  disabled: PropTypes.bool,
  endDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.instanceOf(null),
  ]),
  forceShowDate: PropTypes.bool,
  inputClassName: PropTypes.string,
  invalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  label: PropTypes.string,
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  nullAllowed: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  selectsRange: PropTypes.bool,
  size: PropTypes.oneOf(DatePickerSizes),
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.instanceOf(null),
  ]),
  triggerClassName: PropTypes.string,
  valid: PropTypes.bool,
  readonly: PropTypes.bool,
};

DatePicker.defaultProps = {
  afterClickOutside: null,
  afterCalendarClose: null,
  className: undefined,
  defaultValue: null,
  disabled: false,
  endDate: null,
  forceShowDate: false,
  inputClassName: undefined,
  invalid: false,
  label: undefined,
  maxDate: new Date('12/31/9999'),
  minDate: new Date(null),
  nullAllowed: false,
  onChange: () => {},
  placeholder: undefined,
  selectsRange: false,
  size: 'lg',
  startDate: null,
  triggerClassName: undefined,
  valid: false,
  readonly: false,
};

DatePicker.displayName = 'DatePicker';

export default DatePicker;
