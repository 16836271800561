/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import classNames from 'classnames';

function DropToggle(props) {
  const {
    children,
    heading,
    toggleMenu,
    isFocused,
    disabled,
    readonly,
    invalid,
    hasSelection,
    valid,
    ...attributes
  } = props;

  const isValid = valid || valid === 'true';
  const isInvalid = invalid || invalid === 'true';
  const isDisabled = disabled || disabled === 'true';
  const isReadonly = readonly || readonly === 'true';

  const buttonClass = classNames(
    'prism-select__toggle',
    'form-control',
    isFocused && 'focus',
    isDisabled && 'is-disabled',
    isInvalid && 'is-invalid',
    isValid && 'is-valid',
    isReadonly && 'is-readonly'
  );

  function handleClick() {
    toggleMenu();
  }
  return (
    <button
      aria-controls="listbox"
      type="button"
      disabled={isDisabled}
      className={buttonClass}
      onClick={() => handleClick()}
      {...attributes}
    >
      <div
        className="prism-select__label"
        style={{ opacity: hasSelection ? 1 : 0.5 }}
        title={children || heading}
      >
        {children || heading}
      </div>
    </button>
  );
}
export default DropToggle;
