export const CATEGORIES = {
  EXTERIOR: 'EXT',
  INTERIOR: 'INT',
  MISCELLANEOUS: 'MISC',
  DAMAGE: 'DMG',
  FEATURES: 'FEATURES',
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  FYUSION_360: '360',
};

export const OPTIONAL_CATEGORIES = {
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  FYUSION_360: '360',
  FEATURES: 'FEATURES',
};

export const CATEGORIES_LIST = Object.keys(CATEGORIES).map((i) => CATEGORIES[i]);
export const OPTIONAL_CATEGORIES_LIST = Object.keys(OPTIONAL_CATEGORIES).map((i) => OPTIONAL_CATEGORIES[i]);
export const IMAGE_VIEWER_CONTROLS_HIDDEN_CLASS = 'imageviewer-controls-hidden';
export const VISIBLE_FILMSTRIP_SLIDES = 5;
export const FIRST_INDEX = 0;

export const PREVIOUS = -1;
export const NEXT = 1;

export const LEFT_ARROW = 37;
export const RIGHT_ARROW = 39;
export const LETTER_K = 75;
export const LETTER_M = 77;

export const TIMEOUT_VALUE_IN_MS = 8000;

export const TAB_FOCUSABLE = 0;
export const NOT_TAB_FOCUSABLE = -1;

export const FILMSTRIP = 'filmstrip';
export const IMAGE_GRID = 'image-grid';

export const FILMSTRIP_THUMBNAIL_WIDTH = 102;
export const GRID_THUMBNAIL_WIDTH = 1;
export const DEFAULT_CAROUSEL_WIDTH = 510;
