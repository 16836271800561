import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Anchor = (props) => {
  const {
    children,
    className,
    color,
    float,
    icon,
    innerRef,
    size,
    tag: Tag,
    ...attributes
  } = props;

  const classes = classNames({
    [className]: className,
    link: true,
    [`link-${size}`]: size,
    [`link-${color}`]: color,
    'link__has-icon': icon,
  });

  const iconClasses = classNames(className, 'icon', `prism-icon-${icon}`, `icon__${float}`);

  return (
    <Tag role="link" ref={innerRef} className={classes} {...attributes}>
      {icon && float === 'left' && <span className={iconClasses} />}
      {children && typeof children === 'string' ? (
        <span className="link-text">{children}</span>
      ) : (
        children
      )}
      {icon && float === 'right' && <span className={iconClasses} />}
    </Tag>
  );
};

Anchor.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  float: PropTypes.string,
  icon: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(React.Element) }),
  ]),
  size: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

Anchor.defaultProps = {
  children: null,
  className: 'prism-anchor',
  color: undefined,
  float: 'left',
  icon: '',
  innerRef: null,
  size: 'md',
  tag: 'a',
};

export default Anchor;
