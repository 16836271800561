import React from 'react';
import PropTypes from 'prop-types';

function Refinements({ children }) {
  return <div className="refinements">{children}</div>;
}

Refinements.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Refinements.defaultProps = { children: null };

export default Refinements;
