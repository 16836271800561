import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@prism/button';
import ButtonGroup from '@prism/buttongroup';
import Pagination from '@prism/pagination';
import SingleSort from '@prism/single-sort';
import { v4 as uuid } from 'uuid';
import useTable from './useTable';

const Table = (props) => {
  const { paginated, perPageOptions, tableControls, ...rest } = props;

  const { className, sortFields, tableBody, tableFoot, tableHead } = useTable(rest);

  const [rowsPerPage, setRowsPerPage] = useState(perPageOptions[0] || 10);
  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState(tableBody);

  const getVisibleRows = () => (paginated
    ? rows.slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage)
    : rows);

  const handleSortedRows = (sortedRows) => {
    setRows(sortedRows);
    setCurrentPage(1);
  };

  const handleRowsPerPageClick = (numRows) => {
    setRowsPerPage(numRows);
    setCurrentPage(1);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-item-md-center table-per-page">
        {tableControls}
        <div>
          {paginated && (
            <>
              <small>Results Per Page</small>
              <ButtonGroup>
                {perPageOptions.map((value) => (
                  <Button
                    key={`size-${value}-page`}
                    active={rowsPerPage === value}
                    size="sm"
                    variant="outline"
                    onClick={() => handleRowsPerPageClick(value)}
                  >
                    {value}
                  </Button>
                ))}
              </ButtonGroup>
            </>
          )}
        </div>
        {!!sortFields.length && (
          <div className="form-group d-flex align-items-center">
            <SingleSort sortFields={sortFields} dataRows={rows} sortedCallback={handleSortedRows} />
          </div>
        )}
      </div>

      <div className="table-scroller">
        <table className={className}>
          {!!tableHead?.length && (
            <thead>
              <tr>
                {tableHead.map((header) => (
                  <th key={header.label} {...header.attributes}>
                    {header.content}
                  </th>
                ))}
              </tr>
            </thead>
          )}

          {!!rows?.length && (
            <tbody>
              {getVisibleRows().map((row) => (
                <tr key={uuid()}>
                  {row.map((cell, i) => (
                    <td key={`${tableHead[i].label}-data`} {...cell.attributes}>
                      {cell.content}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}

          {!!tableFoot?.length && (
            <tfoot>
              <tr>
                {tableFoot.map((footer, i) => (
                  <td key={`${tableHead[i].label}-footer`} {...footer.attributes}>
                    {footer.content}
                  </td>
                ))}
              </tr>
            </tfoot>
          )}
        </table>
      </div>

      {paginated && (
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(rows.length / rowsPerPage)}
          onChange={(pageData) => setCurrentPage(pageData.index)}
        />
      )}
    </div>
  );
};

Table.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  colorHeader: PropTypes.bool,
  condensed: PropTypes.bool,
  darkHeader: PropTypes.bool,
  headerSticky: PropTypes.bool,
  lightHeader: PropTypes.bool,
  paginated: PropTypes.bool,
  perPageOptions: PropTypes.arrayOf(PropTypes.number),
  tableControls: PropTypes.node,
};

Table.defaultProps = {
  children: null,
  colorHeader: false,
  condensed: false,
  darkHeader: false,
  headerSticky: false,
  lightHeader: false,
  paginated: false,
  perPageOptions: [10, 25, 50],
  tableControls: null,
};

export default Table;
