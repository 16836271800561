import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Collapse from '@prism/collapse';
import { SelectableContext } from './context/SelectableContext';
import { AccordionContext } from './AccordionCtx';

function AccordionItem({
  children,
  triggerClassName,
  title,
  variant,
  defaultOpen,
  onClear,
  clearable,
  toggle,
  id,
}) {
  const { toggleSelected } = useContext(SelectableContext) || {};
  const { openItem, toggleItem } = useContext(AccordionContext) || {};
  const isFilter = variant === 'filter';

  const [isOpen, toggleOpen] = React.useState(defaultOpen);
  // const isOpen = openItem.indexOf(id) >= 0;

  React.useEffect(() => {
    if (toggleOpen && openItem) {
      toggleOpen(openItem.indexOf(id) >= 0);
    }
  }, [openItem]);

  const clsx = {
    trigger: classNames(
      triggerClassName,
      'accordion-trigger',
      isOpen && 'accordion-trigger--active',
      isFilter && 'accordion-trigger--filter'
    ),
    caratDefault: classNames(
      'accordion-trigger__carat',
      isOpen && 'accordion-trigger__carat--active',
      isFilter && 'accordion-trigger__carat--filter'
    ),
    appendIcon: classNames(
      isFilter && clearable && 'icon prism-icon-cancel-circle accordion-filter__cancel-icon',
      !isFilter && 'icon prism-icon-chevron-down accordion-carat',
    ),
    caratFilter: classNames(
      clearable && 'accordion-trigger__carat accordion-filter__cancel'
    ),
    wrap: classNames(
      'accordion-item__wrapper',
      isFilter && 'accordion-item__wrapper--filter'
    ),
  };

  useEffect(() => {
    if (defaultOpen) {
      toggleItem(id);
    }
  }, []);
  function handleClear(e) {
    onClear(e);
    toggleSelected(false);
  }

  return (
    <div id={id} className={clsx.wrap}>
      <span
        role="presentation"
        aria-label="Accordion trigger"
        className={clsx.trigger}
        onClick={() => toggle(id)}
      >
        {isFilter && (
          <span className={clsx.caratDefault}>
            <i className="icon prism-icon-chevron-right-light accordion-carat" />
          </span>
        )}
        <span className="accordion-trigger-label">{title}</span>
        {!!clsx.appendIcon && !isFilter && (
          <span
            role="presentation"
            aria-label="Accordion Clear"
            onClick={handleClear}
            className={isFilter ? clsx.caratFilter : clsx.caratDefault}
          >
            <i className={clsx.appendIcon} />
          </span>
        )}
      </span>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </div>
  );
}
const propTypes = {
  // isOpen: PropTypes.bool,
  id: PropTypes.string,
  triggerClassName: PropTypes.string,
  children: PropTypes.node,
  defaultOpen: PropTypes.bool,
  title: PropTypes.string,
  variant: PropTypes.string,
  onClear: PropTypes.func,
  toggle: PropTypes.func,
  clearable: PropTypes.bool,
};

AccordionItem.propTypes = propTypes;
AccordionItem.defaultProps = {
  id: undefined,
  children: null,
  defaultOpen: false,
  title: undefined,
  variant: undefined,
  onClear: () => {},
  triggerClassName: undefined,
  toggle: undefined,
  clearable: false,
};

export default AccordionItem;
