import React from 'react';
import { IconSvg } from '@prism/icon';
import Spinner from '@prism/spinner';
import classnames from 'classnames';

const usePanel = (props) => {
  const {
    adaTimeoutGlyph,
    adaTimeoutLabel,
    className,
    color,
    glyph,
    inline,
    isStatic,
    label,
    size,
    showMask,
    targetRef,
  } = props;

  let computedSize;
  if (size) {
    computedSize = size;
  } else if (targetRef) {
    computedSize = targetRef.current.tagName.match(/body|html/i) ? 'xl' : 'lg';
  } else if (inline) {
    computedSize = 'xs';
  } else {
    computedSize = 'md';
  }

  const panelClassName = classnames({
    [className]: className,
    interstitial: true,
    'interstitial-dark': color === 'dark',
    'interstitial-inline': inline,
    [`interstitial-${computedSize}`]: computedSize,
    'interstitial-panel': true,
    'interstitial-panel-dark': color === 'dark',
    'interstitial-panel-light': color === 'light',
    [`interstitial-panel-${computedSize}`]: computedSize,
  });

  const maskClassName = classnames({
    'interstitial-mask': true,
    'interstitial-mask-dark': color === 'light',
    'interstitial-mask-light': color === 'dark',
  });

  const iconSizes = {
    xs: '15px',
    sm: '19px',
    md: '25px',
    lg: '33px',
    xl: '47px',
    xxl: '47px',
  };

  const iconHeight = iconSizes[computedSize] || iconSizes.xs;

  const timeoutGraphic = (
    <IconSvg glyph={adaTimeoutGlyph} color={null} height={iconHeight} />
  );

  const graphic = isStatic ? (
    <IconSvg glyph={glyph} color={null} height={iconHeight} />
  ) : (
    <Spinner color={null} size={computedSize} />
  );

  return {
    panelClassName,
    graphic,
    showMask,
    maskClassName,
    showLabel: !inline,
    label,
    timeout: {
      graphic: timeoutGraphic,
      label: adaTimeoutLabel,
    },
  };
};

export default usePanel;
