import React from 'react';
import PropTypes from 'prop-types';
import {
  LEFT_ARROW, RIGHT_ARROW, PREVIOUS, NEXT,
} from '../../types/constants';

const BackForthButton = ({ disabled, selectedFilmstripSlide, updateCurrentSlide, direction }) => {
  const isBack = direction === 'previous';
  const { icon, increment, ...attrs } = isBack ? {
    className: 'filmstrip-prev',
    'aria-label': 'back-button',
    'aria-roledescription': 'Previous Slide in filmstrip',
    icon: 'icon prism-icon-chevron-left-light',
    increment: PREVIOUS,
  } : {
    className: 'filmstrip-next',
    'aria-label': 'next-button',
    'aria-roledescription': 'Next Slide in filmstrip',
    icon: 'icon prism-icon-chevron-right-light',
    increment: NEXT,
  };
  const handleOnClick = () => updateCurrentSlide(selectedFilmstripSlide + increment);

  const handleKeyDown = (e) => {
    if (e.keyCode === LEFT_ARROW) {
      updateCurrentSlide(selectedFilmstripSlide + PREVIOUS);
    }
    if (e.keyCode === RIGHT_ARROW) {
      updateCurrentSlide(selectedFilmstripSlide + NEXT);
    }
  };

  return (
    <button
      onClick={handleOnClick}
      onKeyDown={handleKeyDown}
      disabled={disabled}
      type="button"
      {...attrs}
    >
      <span className={icon} />
    </button>
  );
};

BackForthButton.propTypes = {
  disabled: PropTypes.bool,
  selectedFilmstripSlide: PropTypes.number,
  updateCurrentSlide: PropTypes.oneOfType([PropTypes.func]),
  direction: PropTypes.oneOf(['next', 'previous']),
};

BackForthButton.defaultProps = {
  disabled: false,
  direction: 'previous',
  selectedFilmstripSlide: 0,
  updateCurrentSlide: null,
};

export default BackForthButton;
