import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ModalBody = (props) => {
  const { className, tag: Tag, ...attributes } = props;

  const classes = classNames('modal-body', className);

  return <Tag {...attributes} className={classes} />;
};

ModalBody.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
};

ModalBody.defaultProps = {
  tag: 'div',
  className: '',
};

ModalBody.COMPONENT_CNAME = 'modal-body';

export default ModalBody;
