import React from 'react';
import { ButtonBack } from 'pure-react-carousel';
import classnames from 'classnames';
import { MultiMediaViewerConsumer } from '../MultimediaViewer';
import {
  IMAGE_VIEWER_CONTROLS_HIDDEN_CLASS,
  PREVIOUS,
} from '../../types/constants';
import { calculateCurrentSlide } from './utils';

const BackButton = React.forwardRef(
  (
    { carouselStore, controlsHidden, multipleMediaAssetsExist, onClick },
    ref
  ) => {
    const classes = classnames(
      'imageviewer-prev',
      controlsHidden ? IMAGE_VIEWER_CONTROLS_HIDDEN_CLASS : ''
    );
    if (!multipleMediaAssetsExist) return null;
    return (
      <div className={classes} onClick={onClick}>
        <MultiMediaViewerConsumer>
          {({ updateCurrentSlide, currentSlide }) => (
            <ButtonBack
              className="no-button-styling"
              onClick={() => updateCurrentSlide(
                calculateCurrentSlide(carouselStore, PREVIOUS)
              )}
            >
              <span className="icon prism-icon-chevron-left-light" />
            </ButtonBack>
          )}
        </MultiMediaViewerConsumer>
      </div>
    );
  }
);

export default BackButton;
