import React from 'react';
import PropTypes from 'prop-types';
import Button from '@prism/button';
import InputGroupAddon from './InputGroupAddon';
import { warnOnce } from '../Utilities/utils';

const InputGroupButton = (props) => {
  warnOnce(
    'The "InputGroupButton" component has been deprecated.\nPlease use component "Inp'
      + 'utGroupAddon".'
  );

  const {
    children,
    groupClassName,
    groupAttributes,
    ...propsWithoutGroup
  } = props;

  if (typeof children === 'string') {
    const { tag, addonType, ...attributes } = propsWithoutGroup;

    const allGroupAttributes = {
      ...groupAttributes,
      tag,
      addonType,
    };

    return (
      <InputGroupAddon {...allGroupAttributes} className={groupClassName}>
        <Button {...attributes}>{children}</Button>
      </InputGroupAddon>
    );
  }

  return <InputGroupAddon {...props}>{children}</InputGroupAddon>;
};

InputGroupButton.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  addonType: PropTypes.oneOf(['prepend', 'append']).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  groupClassName: PropTypes.string,
  groupAttributes: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.string,
};

InputGroupButton.defaultProps = {
  tag: 'div',
  children: null,
  groupClassName: '',
  groupAttributes: [],
  className: '',
};

export default InputGroupButton;
