import React from 'react';
import PropTypes from 'prop-types';

const TableContainer = (props) => {
  const {
    children,
    contain,
    headerStyle,
    height,
    isScrollable,
    width,
    ...attributes
  } = props;

  return (contain || isScrollable)
    ? <div className="table-container" style={{ height, width }} {...attributes}>{children}</div>
    : <>{children}</>;
};

TableContainer.propTypes = {
  children: PropTypes.node,
  contain: PropTypes.bool,
  headerStyle: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isScrollable: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TableContainer.defaultProps = {
  children: null,
  contain: false,
  headerStyle: undefined,
  height: undefined,
  isScrollable: false,
  width: '100%',
};

TableContainer.displayName = 'TableContainer';

export default TableContainer;
