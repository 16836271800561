import {
  Highlighter as AutocompleteHighlighter,
  Menu as AutocompleteMenu,
  MenuItem as AutocompleteMenuItem,
  Token as AutocompleteToken,
} from 'react-bootstrap-typeahead';
import Autocomplete from './Autocomplete';

Autocomplete.Highlighter = AutocompleteHighlighter;
Autocomplete.Menu = AutocompleteMenu;
Autocomplete.MenuItem = AutocompleteMenuItem;
Autocomplete.Token = AutocompleteToken;

export {
  AutocompleteHighlighter,
  AutocompleteMenu,
  AutocompleteMenuItem,
  AutocompleteToken,
};
export default Autocomplete;
