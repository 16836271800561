/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';

const SlideOutHeader = ({ children }) => <div aria-label="slide-out-header">{children}</div>;

SlideOutHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

SlideOutHeader.defaultProps = { children: null };

export default SlideOutHeader;
