import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const InputGroup = (props) => {
  const {
    children, className, tag: Tag, size, readonly, ...attributes
  } = props;

  const classes = classNames(className, 'input-group', size ? `input-group-${size}` : null);

  // Add readonly prop to children if InputGroup is readonly
  const computedChildren = readonly ? React.Children.map(children, (child) => {
    const newChild = { ...child };
    newChild.props = { ...newChild.props, readonly };
    return newChild;
  }) : children;

  return <Tag {...attributes} className={classes} readOnly={readonly}>{computedChildren}</Tag>;
};

InputGroup.propTypes = {
  children: PropTypes.node,
  tag: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  size: PropTypes.string,
  className: PropTypes.string,
  readonly: PropTypes.bool,
};

InputGroup.defaultProps = {
  children: null,
  tag: 'div',
  size: 'md',
  className: '',
  readonly: false,
};

export default InputGroup;
