/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { useCard } from '../useCard';
import {
  CardBody, CardMedia, CardFooter,
} from '.';

export const Card = (props) => {
  const { elementProps, mediaContent, id, innerContent } = useCard(props);
  const { card, layout, media, body, footer } = elementProps;

  return (
    <div {...card} id={id}>
      <div {...layout}>
        <CardMedia {...media}>{mediaContent}</CardMedia>
        {body.hasBody && <CardBody {...body}>{innerContent}</CardBody>}
      </div>
      <CardFooter {...footer} />
    </div>
  );
};

Card.propTypes = {
  /**
   * Insert left-aligned <Action /> in footer.
   */
  actions: PropTypes.shape({
    left: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.node, PropTypes.object])
    ),
    right: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.node, PropTypes.object])
    ),
    body: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.node, PropTypes.object])
    ),
    media: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.node, PropTypes.object])
    ),
  }),
  /**
   * Toggle a border around the card.
   */
  border: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * Optionally include your own CSS classes to style the card.
   */
  className: PropTypes.string,
  /**
   * URI of media asset to be used as `src` attribute OR a JSX element.
   */
  media: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Optionally include a caption beneath the media.
   */
  mediaContent: PropTypes.string,
  /**
   * Optionally set a fixed width of media - useful for horizontal layouts.
   */
  mediaWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Tweak gutter sizing for media. `full`, `thin`, `none`
   */
  mediaGutter: PropTypes.oneOf([null, 'full', 'thin', 'none']),
  /**
   * Tweak gutter sizing for the card. `full`, `thin`, `none`
   */
  gutter: PropTypes.oneOf([null, 'full', 'thin', 'none']),
  /**
   * Optionally, set an explicit height for the card. Applies as a CSS `height` property.
   */
  height: PropTypes.number,
  /**
   * Main presentational title for the card
   */
  title: PropTypes.string,
  /**
   * Optionally pass body content as a prop rather than children.
   */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Optionally, set an explicit width for the card. Applied as a CSS `width` property.
   */
  width: PropTypes.number,
  compact: PropTypes.bool,
  dark: PropTypes.bool,
};

Card.defaultProps = {
  border: true,
  actions: undefined,
  compact: false,
  dark: false,
  gutter: 'full',
  className: '',
  mediaContent: undefined,
  mediaWidth: undefined,
  mediaGutter: 'none',
  media: undefined,
  height: undefined,
  title: undefined,
  content: undefined,
  width: undefined,
};

Card.displayName = 'Card';

export default Card;
