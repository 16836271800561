import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FormGroup = (props) => {
  const {
    children,
    className,
    row,
    disabled,
    check,
    inline,
    tag: Tag,
    readonly,
    ...attributes
  } = props;

  const classes = classNames(
    className, {
      'form-row': row,
      'form-check': check,
      'form-group': !check,
      'form-check-inline': (check && inline),
      'align-items-center form-group-inline': inline,
      disabled: (check && disabled),
    }
  );

  // Add readonly prop to children if FormGroup is readonly
  const computedChildren = readonly ? React.Children.map(children, (child) => {
    const newChild = { ...child };
    newChild.props = { ...newChild.props, readonly };
    return newChild;
  }) : children;

  return <Tag {...attributes} className={classes} readOnly={readonly}>{computedChildren}</Tag>;
};

FormGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  row: PropTypes.bool,
  check: PropTypes.bool,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
  tag: PropTypes.string,
  className: PropTypes.string,
  readonly: PropTypes.bool,
};

FormGroup.defaultProps = {
  tag: 'div',
  children: null,
  row: false,
  check: false,
  inline: false,
  disabled: false,
  className: '',
  readonly: false,
};

export default FormGroup;
