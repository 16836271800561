/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Icon from '@prism/icon';
import Button from '@prism/button';
import usePagination from './usePagination';

const Pagination = (props) => {
  const {
    currentIndex,
    totalPages,
    getControlClasses,
    updateIndex,
    range,
  } = usePagination(props);
  const btnProps = { variant: 'textonly', size: 'sm' };
  const handleFirstPageClick = () => updateIndex(1);
  const handlePrevPageClick = () => updateIndex(currentIndex - 1);
  const handleNextPageClick = () => updateIndex(currentIndex + 1);

  const handlePageClick = (page) => {
    updateIndex(page);
  };

  return (
    <div className="pagination">
      <Button
        disabled={currentIndex === 1}
        onClick={handleFirstPageClick}
        className={getControlClasses('start')}
        title="Go to page 1"
        {...btnProps}
      >
        <Icon glyph="back-to-start" />
      </Button>
      <Button
        disabled={currentIndex === 1}
        onClick={handlePrevPageClick}
        className={getControlClasses('prev')}
        title="Go to previous page"
        {...btnProps}
      >
        <Icon glyph="chevron-left-light" />
      </Button>
      {range
        && range.map((item) => (
          <Button
            key={item.pageItem}
            disabled={item.pageItem === currentIndex}
            title={
              item.pageItem === currentIndex
                ? `You are on page ${item.pageItem}`
                : `Go to page ${item.pageItem}`
            }
            className={item.pageNumberClasses}
            onClick={() => handlePageClick(item.pageItem)}
            {...btnProps}
          >
            {item.pageItem}
          </Button>
        ))}
      <Button
        disabled={currentIndex === totalPages}
        onClick={handleNextPageClick}
        className={getControlClasses('next')}
        title="Go to next page"
        {...btnProps}
      >
        <Icon glyph="chevron-right-light" />
      </Button>
    </div>
  );
};

Pagination.defaultProps = {
  currentPage: 1,
  totalPages: 1,
  onChange: () => {},
};

export default Pagination;
