/* eslint-disable react/jsx-filename-extension */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@prism/button';
import { CSSTransition } from 'react-transition-group';
import { useClickAway, useKeyPressEvent } from 'react-use';
import SlideOutHeader from './SlideOutHeader';
import { Icon } from "../Icon";

const SlideOutContent = (props) => {
  const {
    children,
    headerText,
    isOpen,
    maxWidth,
    placement,
    toggleClose,
  } = props;

  const slideOutRef = useRef();
  useClickAway(slideOutRef, () => toggleClose());
  useKeyPressEvent('Escape', () => toggleClose());

  return (
    <CSSTransition
      in={isOpen}
      appear
      timeout={250}
      classNames={`slide-out-content--${placement}`}
      className={`slide-out-content slide-out-content--${placement}`}
      aria-label="slide-out-content"
      unmountOnExit
    >
      <div style={{ maxWidth }} ref={slideOutRef}>
        <Button close className="slide-out-close" onClick={toggleClose}>
          <Icon glyph="cross" />
        </Button>
        {headerText && (
          <SlideOutHeader>
            <h1>{headerText}</h1>
          </SlideOutHeader>
        )}
        <div className="slide-out-body">{children}</div>
      </div>
    </CSSTransition>
  );
};

SlideOutContent.propTypes = {
  children: PropTypes.node,
  headerText: PropTypes.string,
  isOpen: PropTypes.bool,
  maxWidth: PropTypes.string,
  placement: PropTypes.string,
  toggleClose: PropTypes.func,
};

SlideOutContent.defaultProps = {
  children: null,
  headerText: undefined,
  isOpen: false,
  maxWidth: undefined,
  placement: 'left',
  toggleClose: () => {},
};

export default SlideOutContent;
