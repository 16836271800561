import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from '@prism/icon';

const SortIcon = ({ isSorted, isSortedDesc }) => {
  let glyph;
  if (isSorted) {
    glyph = isSortedDesc ? 'arrow-down' : 'arrow-up';
  } else {
    glyph = 'arrows-up-down'; // off
  }

  const className = classNames({
    'table--sort-icon': true,
    'table--sort-icon--active': isSorted,
    'table--sort-icon--asc': isSorted && !isSortedDesc,
    'table--sort-icon--desc': isSorted && isSortedDesc,
  });

  return (
    <span className={className}>
      <Icon glyph={glyph} />
    </span>
  );
};

SortIcon.propTypes = {
  isSorted: PropTypes.bool,
  isSortedDesc: PropTypes.bool,
};

SortIcon.defaultProps = {
  isSorted: false,
  isSortedDesc: false,
};

SortIcon.displayName = 'SortIcon';

export default SortIcon;
