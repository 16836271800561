import React from 'react';
import {
  Menu,
  MenuItem,
  Highlighter,
} from 'react-bootstrap-typeahead';
import { useMedia } from 'react-use-media';

export function useAutocomplete(props) {
  const { innerRef, renderMenu, options, ...attributes } = props;

  const sortResults = (results, args) => {
    const { labelKey, text } = args;

    const toVal = (item) => (typeof item === 'string' ? item : item[labelKey].toString());

    return text
      ? results.sort((a, b) => {
        const aVal = toVal(a).toLowerCase();
        const bVal = toVal(b).toLowerCase();
        const matchA = aVal.indexOf(text.toLowerCase()) !== -1 ? aVal : -1;
        const matchB = bVal.indexOf(text.toLowerCase()) !== -1 ? bVal : -1;

        return matchA < matchB ? -1 : 1;
      })
      : results;
  };

  const isWide = useMedia({ minWidth: 480 });

  const renderResults = (results, menuProps, propsFromEl) => (
    <Menu {...menuProps}>
      {
      sortResults(results, propsFromEl).map((result, index) => {
        const keyId = index;
        return (
          <MenuItem key={keyId} option={result} position={index}>
            <Highlighter search={propsFromEl.text}>
              {result[propsFromEl.labelKey] || result}
            </Highlighter>
          </MenuItem>
        );
      })
      }
    </Menu>
  );
  return {
    renderResults,
    innerRef,
    renderMenu,
    options,
    isWide,
    ...attributes,
  };
}

export default useAutocomplete;
