import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import {
  CarouselProvider,
  Slide,
  Slider,
  WithStore,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { MultiMediaViewerConsumer } from '../MultimediaViewer';
import { MediaAssetsType } from '../../types';
import { IMAGE_GRID } from '../../types/constants';
import Thumbnail from './Thumbnail';
import { getTabIndex, trayProps } from './utils';

const propTypes = {
  carouselStore: PropTypes.shape({ getStoreState: PropTypes.func }),
  disabled: PropTypes.bool,
  mediaAssets: MediaAssetsType,
};

const defaultProps = {
  carouselStore: { getStoreState: () => { } },
  disabled: false,
  mediaAssets: [],
};

const ImageGrid = React.forwardRef((
  {
    disabled,
    carouselStore,
    mediaAssets: propsMediaAssets,
  },
  ref
) => {
  const mediaAssets = propsMediaAssets.map((asset) => ({
    ...asset,
    id: asset.id || uuid(),
  }));
  const numberOfAssets = mediaAssets.length;
  const filmstripCarouselStore = carouselStore;
  const mediaAssetKey = (m, i) => `${m.id}-imgg-${i}`;

  const availablePhotosPropsTypes = {
    updateCurrentSlide: PropTypes.func,
    testLoad: PropTypes.func,
    selectedFilmstripSlide: PropTypes.number,
  };

  const availablePhotosDefaultProps = {
    updateCurrentSlide: () => { },
    testLoad: () => { },
    selectedFilmstripSlide: 0,
  };

  const AvailablePhotos = ({
    updateCurrentSlide,
    testLoad,
    selectedFilmstripSlide,
  }) => !!numberOfAssets && (
    <Slider
      className="image-grid-slider"
      classNameAnimation="image-grid-slider-no-animation"
      ref={ref}
      onLoad={testLoad({ filmstripCarouselStore })}
      disableKeyboard
      trayProps={trayProps(
        updateCurrentSlide,
        filmstripCarouselStore,
        selectedFilmstripSlide
      )}
    >
      {mediaAssets.map((mediaAsset, index) => (
        <Slide
          className="image-grid-slide"
          index={index}
          key={mediaAssetKey(index)}
          tabIndex={getTabIndex(
            index,
            filmstripCarouselStore.getStoreState().currentSlide
          )}
        >
          <Thumbnail
            {...{
              updateCurrentSlide,
              testLoad,
              selectedFilmstripSlide,
              mediaAsset,
              disabled,
              filmstripCarouselStore,
              index,
            }}
            prefix={IMAGE_GRID}
          />
        </Slide>
      ))}
    </Slider>
  );

  AvailablePhotos.propTypes = availablePhotosPropsTypes;

  AvailablePhotos.defaultProps = availablePhotosDefaultProps;

  return (
    <div className="image-grid">
      <MultiMediaViewerConsumer>
        {({ updateCurrentSlide, testLoad, selectedFilmstripSlide }) => (
          <>
            <AvailablePhotos
              {...{ updateCurrentSlide, testLoad, selectedFilmstripSlide }}
            />
          </>
        )}
      </MultiMediaViewerConsumer>
    </div>
  );
});

ImageGrid.propTypes = propTypes;
ImageGrid.defaultProps = defaultProps;

const ImageGridWithCarousel = React.forwardRef(({
  disabled,
  ...props
}, ref) => {
  const mapCarouselStateToProps = (mediaAssets) => ({
    mediaAssets,
    currentSlide: 0,
    ref,
    disabled,
    ...props,
  });

  const numberOfAssets = props.mediaAssets.length;

  const ImageGridWithCarouselState = WithStore(
    ImageGrid,
    mapCarouselStateToProps
  );

  return (
    <CarouselProvider
      className="image-grid-carousel-provider"
      naturalSlideWidth={4}
      naturalSlideHeight={3}
      visibleSlides={numberOfAssets}
      totalSlides={numberOfAssets}
      infinite="true"
      disableKeyboard
      dragEnabled={false}
    >
      <ImageGridWithCarouselState />
    </CarouselProvider>
  );
});

ImageGridWithCarousel.propTypes = propTypes;
ImageGridWithCarousel.defaultProps = defaultProps;
export default ImageGridWithCarousel;
