import React from 'react';
import PropTypes from 'prop-types';
import { WithStore } from 'pure-react-carousel';
import classNames from 'classnames';
import { mapCarouselStateToProps, naturalCountingNumber } from './utils';
import { IMAGE_VIEWER_CONTROLS_HIDDEN_CLASS } from '../../types/constants';
import FullScreenToggle from './FullScreenToggle';
import ZoomControls from './ZoomControls';
import Description from './Description';
import MediaViewerButton from './MediaViewerButton';
import { MediaAssetsType } from '../../types';

const MediaCount = React.forwardRef(
  (
    {
      controlsHidden,
      currentSlide,
      mediaAssets,
      totalSlides,
      fullScreenHidden,
      handleFullScreenClick,
      handleExitFullScreenClick,
      customButtons,
      showFullScreenControls,
    },
    ref
  ) => {
    const mediaAssetsExist = mediaAssets.length > 0;
    const slide = mediaAssets[currentSlide] || undefined;

    if (!mediaAssetsExist || !slide) return null;

    const { src, description } = slide;
    const isHidden = controlsHidden || !!src.video || !!src.audio || !!src['360'];
    const countClass = classNames([
      'imageviewer-mediacount',
      'shift-up',
      isHidden && IMAGE_VIEWER_CONTROLS_HIDDEN_CLASS,
    ]);

    const buttons = customButtons.map((btn) => (
      <MediaViewerButton
        {...btn}
        key={currentSlide}
        currentSlide={slide}
        index={currentSlide}
      />
    ));

    const titleText = `${naturalCountingNumber(currentSlide)} of ${totalSlides}`;

    const handleClick = fullScreenHidden
      ? handleFullScreenClick
      : handleExitFullScreenClick;
    return (
      <div className="imageviewer-controls" ref={ref}>
        <div className={countClass}>
          <div className="imageviewer-mediacount-content">
            <Description description={description} />
            <span className="imageviewer-mediacount-text">
              <span className="mr-2">{titleText}</span>
              {buttons}
              <ZoomControls zoomMax={3} />
              {showFullScreenControls && (
                <FullScreenToggle
                  isClosed={fullScreenHidden}
                  onToggle={handleClick}
                />
              )}
            </span>
          </div>
          <div className="mediacount-description mediacount-description-hidden">
            {description}
          </div>
        </div>
      </div>
    );
  }
);
MediaCount.propTypes = {
  mediaAssets: PropTypes.arrayOf(PropTypes.shape(MediaAssetsType)),
  controlsHidden: PropTypes.Bool,
  fullScreenHidden: PropTypes.Bool,
  handleFullScreenClick: PropTypes.func,
  handleExitFullScreenClick: PropTypes.func,
  customButtons: PropTypes.arrayOf(PropTypes.shape({})),
  showFullScreenControls: PropTypes.Bool,
  currentSlide: PropTypes.number,
  totalSlides: PropTypes.number,
};
MediaCount.defaultProps = {
  mediaAssets: [],
  fullScreenHidden: true,
  handleFullScreenClick: () => {},
  handleExitFullScreenClick: () => {},
  customButtons: [],
  controlsHidden: true,
  showFullScreenControls: false,
  currentSlide: 0,
  totalSlides: 0,
};
const MediaCountWithCarouselState = WithStore(
  MediaCount,
  mapCarouselStateToProps
);

export { MediaCount };
export default MediaCountWithCarouselState;
