import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { getConstrainedBounds } from './utils';

const rangeLength = 5;
const usePagination = (props) => {
  const { currentPage, totalPages, onChange } = props;
  const [currentIndex, setCurrentIndex] = useState(currentPage);
  const [range, setRange] = useState([]);

  const getRange = (page) => {
    const { rightBound, leftBound } = getConstrainedBounds(
      page,
      totalPages,
      rangeLength
    );
    const length = rightBound - leftBound + 1;
    const returnArray = [];
    for (let i = 0; i < length; i += 1) {
      const pageItem = i + leftBound;
      const pageNumberClasses = classNames(
        'pagination__control',
        'pagination__control--page',
        [`pagination__control--page-${pageItem}`],
        pageItem === page && 'selected',
        totalPages === 0 && 'd-none'
      );

      returnArray.push({
        pageNumberClasses,
        pageItem,
      });
    }
    return returnArray;
  };

  const updateIndex = (newIndex) => {
    const updatedIndex = {
      prevIndex: currentIndex,
      index: newIndex,
    };
    setCurrentIndex(newIndex);
    const rangeArray = getRange(newIndex);
    setRange(rangeArray);
    onChange(updatedIndex);
  };

  useEffect(() => {
    if (currentPage !== currentIndex) {
      setCurrentIndex(currentPage);
    }
    const rangeArray = getRange(currentPage);
    setRange(rangeArray);
  }, [currentPage]);

  const getControlClasses = (control) => classNames(
    'pagination__control',
    control && `pagination__control--${control}`,
    totalPages === 0 && 'd-none'
  );
  return {
    updateIndex,
    getControlClasses,
    totalPages,
    currentIndex,
    range,
  };
};
export default usePagination;
