import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import TableRow from './TableRow';
import TableHeadCell from './TableHeadCell';

const TableHead = ({ headerGroups, singleSort }) => (
  <thead className="table-head">
    {headerGroups.map((headerGroup) => (
      <TableRow key={uuid()} headerGroup={headerGroup}>
        {headerGroup.headers.map((column) => {
          const {
            canSort,
            getHeaderProps,
            getSortByToggleProps,
            headerClassName,
            isSorted,
            isSortedDesc,
            onBeforeSort,
            onAfterSort,
            render,
          } = column;

          return (
            <TableHeadCell
              key={uuid()}
              canSort={canSort && !singleSort}
              isSorted={canSort && isSorted}
              isSortedDesc={canSort && isSortedDesc}
              sortByProps={canSort ? getSortByToggleProps() : null}
              onBeforeSort={onBeforeSort}
              onAfterSort={onAfterSort}
              className={headerClassName}
              {...getHeaderProps()}
            >
              {render('Header')}
            </TableHeadCell>
          );
        })}
      </TableRow>
    ))}
  </thead>
);

TableHead.propTypes = {
  headerGroups: PropTypes.arrayOf(
    PropTypes.shape({
      headers: PropTypes.arrayOf(
        PropTypes.shape({
          canSort: PropTypes.bool,
          getHeaderProps: PropTypes.func,
          getSortByToggleProps: PropTypes.func,
          headerClassName: PropTypes.string,
          isSorted: PropTypes.bool,
          isSortedDesc: PropTypes.bool,
          onBeforeSort: PropTypes.func,
          onAfterSort: PropTypes.func,
          render: PropTypes.func,
        })
      ),
    })
  ),
  singleSort: PropTypes.bool,
};

TableHead.defaultProps = {
  headerGroups: [],
  singleSort: false,
};

TableHead.displayName = 'TableHead';

export default TableHead;
