import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import TableDataCell from './TableDataCell';
import TableRow from './TableRow';

const TableBody = (props) => {
  const { getTableBodyProps, prepareRow, numVisibleColumns, rows } = props;

  return (
    <tbody className="table-body" {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);

        return (
          <TableRow
            key={uuid()}
            row={row}
            numVisibleColumns={numVisibleColumns}
            {...row.getRowProps()}
          >
            {row.cells.map((cell) => {
              const computedClassName = classNames(
                cell.column.className,
                { 'row-expander': cell.column.id === 'expander' }
              );

              return (
                <TableDataCell
                  key={uuid()}
                  className={computedClassName}
                  headerLabel={cell.column.render('Header')}
                  {...cell.getCellProps()}
                >
                  {cell.render('Cell')}
                </TableDataCell>
              );
            })}
          </TableRow>
        );
      })}
    </tbody>
  );
};

TableBody.propTypes = {
  getTableBodyProps: PropTypes.func,
  prepareRow: PropTypes.func,
  numVisibleColumns: PropTypes.number,
  rows: PropTypes.arrayOf(PropTypes.shape({
    getRowProps: PropTypes.func,
    cells: PropTypes.arrayOf(PropTypes.shape({
      column: PropTypes.shape({
        className: PropTypes.string,
        id: PropTypes.string,
        render: PropTypes.func,
      }),
      getCellProps: PropTypes.func,
      render: PropTypes.func,
    })),
  })),

};

TableBody.defaultProps = {
  getTableBodyProps: () => {},
  prepareRow: () => {},
  numVisibleColumns: undefined,
  rows: [],

};

TableBody.displayName = 'TableBody';

export default TableBody;
