/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import Fade from '@prism/fade';
import Icon from '@prism/icon';
import { useMessage } from './useMessage';

function Message(props) {
  const {
    tagProps,
    closeAriaLabel,
    closeClasses,
    toggle,
    title,
    textClasses,
    children,
    color,
  } = useMessage(props);

  function getIcon() {
    let icon;
    switch (color) {
      case 'success':
        icon = 'checkmark-circle'; break;
      case 'danger':
      case 'warning':
        icon = 'notification-circle'; break;
      default:
        icon = 'info';
    }
    return icon;
  }

  return (
    <Fade {...tagProps}>
      {toggle ? (
        <button
          type="button"
          className={closeClasses}
          aria-label={closeAriaLabel}
          onClick={toggle}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      ) : null}
      <div className="prism-message__body">
        <Icon glyph={getIcon()} />
        {title && <span className="prism-message__title">{title}</span>}
        <span className={textClasses}>{children}</span>
      </div>
    </Fade>
  );
}

Message.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  closeClassName: PropTypes.string,
  closeAriaLabel: PropTypes.string,
  color: PropTypes.string,
  fade: PropTypes.bool,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  transition: PropTypes.shape(Fade.propTypes),
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.func,
  ]),
};

Message.defaultProps = {
  isOpen: true,
  tag: 'div',
  closeAriaLabel: 'Close',
  fade: true,
  color: 'success',
  transition: {
    ...Fade.defaultProps,
    unmountOnExit: true,
  },
  children: null,
  className: '',
  closeClassName: '',
  toggle: null,
  innerRef: null,
};

export default Message;
