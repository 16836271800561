import React from 'react';
import PropTypes from 'prop-types';

const LoadingScreen = ({ text, classNamePrefix }) => (
  <div className={`${classNamePrefix}-loading`}>
    <div className="icon prism-icon-spinner" />
    <div className={`${classNamePrefix}-loading-text`}>{text}</div>
  </div>
);

LoadingScreen.propTypes = {
  classNamePrefix: PropTypes.string,
  text: PropTypes.string,
};
LoadingScreen.defaultProps = { text: '', classNamePrefix: '' };
export default LoadingScreen;
