import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IndicatorAuctionLight = (props) => {
  const { id, lit, type } = props;

  const cname = 'prism-indicator--light';
  const className = classNames({
    [cname]: true,
    [`${cname}--as-is`]: type === 'TYPE_AS_IS',
    [`${cname}--caution`]: type === 'TYPE_CAUTION',
    [`${cname}--guarantee`]: type === 'TYPE_GUARANTEE',
    [`${cname}--title-absent`]: type === 'TYPE_TITLE_ABSENT',
    [`${cname}--illuminated`]: lit,
  });

  let tooltip;
  switch (type) {
    case 'TYPE_AS_IS':
      tooltip = 'As Is';
      break;
    case 'TYPE_CAUTION':
      tooltip = 'Caution';
      break;
    case 'TYPE_GUARANTEE':
      tooltip = 'Guarantee';
      break;
    case 'TYPE_TITLE_ABSENT':
      tooltip = 'Title Absent';
      break;
    default:
      break;
  }

  return <div id={id} className={className} tooltip={tooltip} role="note" />;
};

export const IndicatorAuctionLightTypes = [
  'TYPE_AS_IS', 'TYPE_CAUTION', 'TYPE_GUARANTEE', 'TYPE_TITLE_ABSENT',
];

IndicatorAuctionLight.propTypes = {
  id: PropTypes.string,
  lit: PropTypes.bool,
  type: PropTypes.oneOf(IndicatorAuctionLightTypes).isRequired,
};

IndicatorAuctionLight.defaultProps = {
  id: undefined,
  lit: false,
};

IndicatorAuctionLight.TYPE_AS_IS = 'TYPE_AS_IS';
IndicatorAuctionLight.TYPE_CAUTION = 'TYPE_CAUTION';
IndicatorAuctionLight.TYPE_GUARANTEE = 'TYPE_GUARANTEE';
IndicatorAuctionLight.TYPE_TITLE_ABSENT = 'TYPE_TITLE_ABSENT';

export default IndicatorAuctionLight;
