import React, {
  useContext, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import AccordionItem from './AccordionItem';
import { SelectableContext } from './context/SelectableContext';
import { AccordionContext } from './AccordionCtx';

function AccordionInput({
  id,
  children,
  defaultOpen,
  onClear,
  title,
  name,
  onChange,
  clearable,
  onSubmit,
}) {
  function renderChildren() {
    return React.Children.map(children, (child) => React.cloneElement(child, { onChange }));
  }

  const formEl = useRef(null);
  const selectContext = useContext(SelectableContext);
  const { setOpenItem, toggleItem } = useContext(AccordionContext);
  const { ctxClear } = selectContext || false;

  const handleClear = (e) => {
    onClear(e);
    if (e) {
      formEl.current.reset();
    }
  };

  useEffect(() => {
    renderChildren();
    if (defaultOpen) {
      setOpenItem([id]);
    }
  }, []);

  const accordionItemProps = {
    onClear: handleClear,
    onChange: onChange && onChange,
    defaultOpen: true,
    triggerTag: 'div',
    variant: 'filter',
    title,
    clearable,
    deselect: ctxClear,
    toggle: () => toggleItem(id),
    children: renderChildren(),
    id,
  };

  const formProps = { id, onSubmit, name: name || title };

  return (
    <form aria-label="Accordion form" {...formProps} ref={formEl}>
      <AccordionItem {...accordionItemProps} />
    </form>
  );
}

AccordionInput.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  onClear: PropTypes.func,
  defaultOpen: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  onSubmit: PropTypes.func,
};
AccordionInput.defaultProps = {
  id: undefined,
  defaultOpen: true,
  onSubmit: (e) => { e.preventDefault(); },
  onClear: () => {},
  onChange: () => {},
  children: null,
  clearable: true,
  name: '',
  title: '',

};
export default AccordionInput;
