import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Form = (props) => {
  const {
    className,
    inline,
    tag: Tag,
    innerRef,
    ...attributes
  } = props;
  const classes = classNames(className, { 'form-inline': inline });
  return <Tag {...attributes} ref={innerRef} className={classes} />;
};

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  inline: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  className: PropTypes.string,
};

Form.defaultProps = {
  tag: 'form',
  children: null,
  className: '',
  inline: false,
  innerRef: null,
};

export default Form;
