import AuctionLight, { IndicatorAuctionLightTypes as AuctionLightTypes } from './IndicatorAuctionLight';
import Flag, { IndicatorFlagLabels as FlagLabels, IndicatorFlagTypes as FlagTypes } from './IndicatorFlag';
import Task from './IndicatorTask';

const Indicator = { AuctionLight, Flag, Task };

export {
  AuctionLight, Flag, Task, AuctionLightTypes, FlagLabels, FlagTypes,
};
export default Indicator;
