import React from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';
import { v4 as uuid } from 'uuid';
import { useAutocomplete } from './useAutocomplete';

function Autocomplete(props) {
  const {
    renderResults, innerRef, renderMenu, options, isWide, ...attributes
  } = useAutocomplete(props);

  return (
    <div className="row align-items-center">
      <div className=" autocomplete-input-container pr-5 flex-grow-1  ">
        <Typeahead
          maxResults={isWide ? 8 : 5}
          options={options}
          ref={innerRef}
          {...attributes}
          renderMenu={renderMenu || renderResults}
        />
      </div>
      <span className="autocomplete-search-icon icon prism-icon-search" />
    </div>
  );
}

Autocomplete.propTypes = {
  bsSize: PropTypes.string,
  caseSensitive: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  defaultOpen: PropTypes.bool,
  defaultSelected: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
  ignoreDiacritics: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  labelKey: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  minLength: PropTypes.number,
  multiple: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onInputChange: PropTypes.func,
  onMenuHide: PropTypes.func,
  onMenuShow: PropTypes.func,
  onMenuToggle: PropTypes.func,
  options: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
    PropTypes.array,
  ]),
  paginate: PropTypes.bool,
  placeholder: PropTypes.string,
  clearButton: PropTypes.bool,
  id: PropTypes.string,
  shouldSelect: PropTypes.bool,
  renderMenu: PropTypes.string,
};

Autocomplete.defaultProps = {
  clearButton: true,
  bsSize: '',
  disabled: false,
  id: uuid(),
  minLength: 3,
  paginate: false,
  shouldSelect: true,
  caseSensitive: false,
  children: null,
  className: '',
  defaultOpen: false,
  defaultSelected: [],
  ignoreDiacritics: false,
  innerRef: null,
  isInvalid: false,
  isValid: false,
  labelKey: 'label',
  multiple: false,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  onKeyDown: () => {},
  onInputChange: () => {},
  onMenuHide: () => {},
  onMenuShow: () => {},
  onMenuToggle: () => {},
  options: [],
  placeholder: '',
  renderMenu: '',
};

export default Autocomplete;
