import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useClickAway } from 'react-use';
import Button from '@prism/button';
import SortFieldSelect from './SortFieldSelect';

const SingleSort = (props) => {
  const { activeSortField, dataRows, sortedCallback, sortFields, sortOrder } = props;

  const [showPopup, setShowPopup] = useState(false);
  const [sortField, setSortField] = useState(
    activeSortField || (sortFields.length ? sortFields[0] : null)
  );
  const [ordering, setOrdering] = useState(sortOrder);

  const popupRef = useRef();
  useClickAway(popupRef, () => {
    setShowPopup(false);
  });

  const togglePopup = (e) => {
    e.preventDefault();
    setShowPopup(!showPopup);
  };

  // Sorting helper function - extracts the value from the row
  const getSortValue = (row, newSortField) => {
    const { children } = row.props || {};
    const item = children ? children[newSortField.index] : row[newSortField.index];
    const cellValue = children
      ? item.props.sortvalue || item.props.children
      : item.content;

    const isNumericalSort = sortField.sorttype && sortField.sorttype === 'number';

    return isNumericalSort
      ? Number.parseFloat(cellValue) || 0
      : cellValue.toUpperCase() || '';
  };

  const sortRows = (newSortField, newOrdering) => {
    if (!newSortField || !dataRows.length) {
      return dataRows;
    }

    // `dataRows` comes from props and should not be mutated
    const sortableRows = [...dataRows];
    return sortableRows.sort((rowA, rowB) => {
      const a = getSortValue(rowA, newSortField);
      const b = getSortValue(rowB, newSortField);
      if (a < b) {
        return newOrdering === 'DESC' ? 1 : -1;
      }
      if (a > b) {
        return newOrdering === 'DESC' ? -1 : 1;
      }
      return 0;
    });
  };

  const handleArrowClick = (newSortField, newOrdering) => {
    setShowPopup(false);
    setSortField(newSortField);
    setOrdering(newOrdering);
    if (sortedCallback) {
      sortedCallback(sortRows(newSortField, newOrdering));
    }
  };

  return (
    <div className="text-sm sortControls popup-container mb-0 single-sort" aria-label="single-sort">
      <span className="mr-2">Sort by</span>
      {sortField && (
        <Button variant="actionLink" onClick={togglePopup} aria-label="current-sort">
          {sortField.label}
          <i className={`mx-2 icon prism-icon-arrow-${ordering === 'ASC' ? 'up' : 'down'}`} />
        </Button>
      )}

      <div ref={popupRef} className={`popup ${showPopup ? 'open' : ''}`} aria-label="popup">
        {sortFields.map((field, i) => (
          <SortFieldSelect
            key={field.index}
            activeArrow={sortField.index === field.index ? ordering : ''}
            className={i > 0 ? 'mt-3' : ''}
            label={field.label}
            onArrowClick={(newOrdering) => handleArrowClick(field, newOrdering)}
          />
        ))}
      </div>
    </div>
  );
};

// PropTypes helper
const sortFieldShape = PropTypes.shape({
  label: PropTypes.string,
  index: PropTypes.number,
  sorttype: PropTypes.oneOf(['number', 'alpha']),
});

SingleSort.propTypes = {
  activeSortField: sortFieldShape,
  dataRows: PropTypes.arrayOf(PropTypes.node),
  sortedCallback: PropTypes.func,
  sortFields: PropTypes.arrayOf(sortFieldShape),
  sortOrder: PropTypes.oneOf(['ASC', 'DESC']),
};

SingleSort.defaultProps = {
  activeSortField: null,
  dataRows: [],
  sortedCallback: () => {},
  sortFields: [],
  sortOrder: 'ASC',
};

export default SingleSort;
