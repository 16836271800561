import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@prism/button';
import ButtonGroup from '@prism/buttongroup';

const SortFieldSelect = (props) => {
  const { activeArrow, className, label, onArrowClick } = props;

  const computedClassName = classNames(
    'd-flex',
    'flex-row',
    'justify-content-between',
    'align-items-center',
    className
  );

  return (
    <div className={computedClassName}>
      <p className="para-md m-0 pr-5 text-nowrap">{label}</p>
      <div>
        <ButtonGroup className="m-0">
          <Button
            aria-label={`${label.toLowerCase().split(' ').join('-')}-ascending-button`}
            icononly
            variant="outline"
            size="sm"
            active={activeArrow === 'ASC'}
            onClick={() => onArrowClick('ASC')}
          >
            <i className="mx-2 icon prism-icon-arrow-up" />
          </Button>

          <Button
            aria-label={`${label.toLowerCase().split(' ').join('-')}-descending-button`}
            icononly
            variant="outline"
            size="sm"
            active={activeArrow === 'DESC'}
            onClick={() => onArrowClick('DESC')}
          >
            <i className="mx-2 icon prism-icon-arrow-down" />
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

SortFieldSelect.propTypes = {
  activeArrow: PropTypes.oneOf(['ASC', 'DESC', '']),
  className: PropTypes.string,
  label: PropTypes.string,
  onArrowClick: PropTypes.func,
};

SortFieldSelect.defaultProps = {
  activeArrow: undefined,
  className: undefined,
  label: '',
  onArrowClick: () => {},
};

export default SortFieldSelect;
