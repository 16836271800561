import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useRanger } from 'react-ranger';

const Slider = ({
  onChange,
  onChangeCommitted,
  disabled = false,
  defaultValue = 0,
  max = 100,
  min = 0,
  step = 1,
  labelUnitOfMeasure = '',
  labelAlignment = 'left',
  label,
  invertedTrack,
  style,
  className,
}) => {
  const asKey = (str, idx) => `${str || 'item'}-${idx.toString()}`;
  const isInverted = !disabled && invertedTrack;
  const isRange = typeof defaultValue !== 'number' && defaultValue.length > 0;
  const initialValue = isRange ? [...defaultValue] : [defaultValue];
  const refs = initialValue.map(() => React.useRef());
  const [values, setValues] = React.useState(initialValue);
  const getValueLabel = () => `${values.map((value) => Number(value).toLocaleString()).join(' - ')} ${labelUnitOfMeasure}`;
  const parentClass = classNames('slider', { invertedTrack: isInverted, disabled });
  const headingClass = classNames('slider-labelcontainer', { disabled });
  const descriptionClass = `slider-descriptionlabel ${labelAlignment}`;
  const { getTrackProps, handles, segments } = useRanger({
    min,
    max,
    stepSize: step,
    values,
    onDrag: (v) => { setValues(v); onChange(v); },
    onChange: (v) => { setValues(v); onChangeCommitted(v); },
  });

  const Heading = () => (
    <div role="heading" aria-level="1" className={headingClass} style={{ width: '100%' }}>
      <div role="term" className={descriptionClass}>
        <span>{label}</span>
      </div>
      <div className="slider-valuelabel" role="definition">
        <span>{getValueLabel()}</span>
      </div>
    </div>
  );

  // eslint-disable-next-line
  const Thumb = React.forwardRef(({ active, getHandleProps }, ref) => (
    <button
      type="button"
      ref={ref}
      disabled={disabled}
      className={classNames('slider-thumb', { active, focus: active })}
      {...getHandleProps()}
    />
  ));
  const Segment = ({ ...segmentProps }) => {
    const { i } = segmentProps;
    const isDefaultTrack = (!isRange && i === 0) || (isRange && i === 1);
    if (isDefaultTrack === invertedTrack) return null;
    return <span className="slider-track" role="status" {...segmentProps} />;
  };

  return (
    <div style={style} className={className}>
      <Heading />
      <div {...getTrackProps()} role="menu" className={parentClass}>
        <span className="slider-rail" role="presentation" />
        {segments.map(({ getSegmentProps }, i) => <Segment key={asKey('segment', i)} i={i} {...getSegmentProps()} />)}
        {handles.map((handle, i) => <Thumb {...handle} ref={refs[i]} key={asKey('handle', i)} />)}
      </div>
    </div>
  );
};

const props = {
  propTypes: {
    /**
     * Text to be displayed above slider.
     */
    label: PropTypes.string,
    /**
     * Optionally align the label "left" (default) or "right"
     */
    labelAlignment: PropTypes.string,
    /**
     * String representing the unit of measure. Example: 'Km'
     */
    labelUnitOfMeasure: PropTypes.string,
    /**
     * Callback fired on every change event
     */
    onChange: PropTypes.func,
    /**
     * Callback fired when new min and/or max are computed.
     */
    onChangeCommitted: PropTypes.func,
    /**
     * Behave as a disabled input, ignoring mouse and keyboard events
     */
    disabled: PropTypes.bool,
    /**
     * Initial value to be displayed
     */
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    /**
     * Maximum value of the slider range.
     */
    max: PropTypes.number,
    /**
     * Minimum value of the slider range.
     */
    min: PropTypes.number,
    /**
     * Amount to increment values with increment/decdrement actions.
     */
    step: PropTypes.number,
    /**
     * Invert "selected" values and highlight the outer ranges of the slider.
     */
    invertedTrack: PropTypes.bool,
    /**
     * Ref
     */
    ref: PropTypes.oneOfType([
      // Either a function
      PropTypes.func,
      // Or the instance of a DOM native element (see the note about SSR)
      PropTypes.shape({ current: PropTypes.element }),
    ]),
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    className: PropTypes.string,
  },
  defaultProps: {
    label: '',
    labelAlignment: 'left',
    labelUnitOfMeasure: '',
    onChange: () => {},
    onChangeCommitted: () => {},
    defaultValue: 0,
    disabled: false,
    ref: () => {},
    max: 100,
    min: 0,
    step: 1,
    invertedTrack: false,
    style: {},
    className: '',
  },
};
Slider.propTypes = props.propTypes;
Slider.defaultProps = props.defaultProps;

export default Slider;
