import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '@prism/tooltip';

const IndicatorTask = (props) => {
  const { complete, icon, iconOnly, name } = props;

  const iconNode = React.createRef();

  const cname = 'prism-indicator--task';
  const className = classNames({
    [cname]: true,
    [`${cname}--complete`]: complete,
  });

  const status = complete ? 'Complete' : 'Incomplete';

  return (
    <span className={className} role="note">
      <i ref={iconNode} className={`icon prism-icon-${icon}`} />
      { iconOnly ? (
        <Tooltip className={`${cname}--tooltip`} target={iconNode}>
          {`${name} ${status}`.trim()}
        </Tooltip>
      ) : (
        <span className={`${cname}--content`}>
          {`${status} ${name}`.trim()}
        </span>
      )}
    </span>
  );
};

IndicatorTask.propTypes = {
  complete: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  iconOnly: PropTypes.bool,
  name: PropTypes.string,
};

IndicatorTask.defaultProps = {
  complete: false,
  iconOnly: false,
  name: '',
};

export default IndicatorTask;
