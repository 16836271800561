import React, {
  useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Fade from '@prism/fade';
import Portal from './Portal';
import {
  getOriginalBodyPadding, conditionallyUpdateScrollbar, setScrollbarWidth,
} from './utils';

const Modal = (props) => {
  const {
    autoFocus,
    backdrop,
    backdropClassName,
    backdropTransition,
    centered,
    children,
    className,
    contentClassName,
    external,
    fade,
    innerRef,
    isOpen: initialIsOpen,
    keyboard,
    labelledBy,
    modalClassName,
    modalTransition,
    onClosed,
    onEnter,
    onExit,
    onOpened,
    role,
    size,
    toggle,
    wrapClassName,
    zIndex,
    ...attributes
  } = props;

  Modal.COMPONENT_CNAME = 'modal';
  Modal.BACKDROP_CNAME = 'modal-backdrop';
  Modal.OPEN_CNAME = 'modal-open';
  Modal.DIALOG_CNAME = 'modal-dialog';
  Modal.CONTENT_CNAME = 'modal-content';

  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [container, setContainer] = useState(null);

  const triggeringElement = useRef(null);
  const originalBodyPadding = useRef(null);
  const dialog = useRef(false);
  const mouseDownElement = useRef(false);

  const init = () => {
    try {
      triggeringElement.current = document.activeElement;
    } catch (err) {
      triggeringElement.current = null;
    }

    let containerEl = null;

    containerEl = document.createElement('div');
    containerEl.setAttribute('tabindex', '-1');
    containerEl.style.position = 'relative';
    containerEl.style.zIndex = zIndex;
    containerEl.className = 'prism-sandbox';
    originalBodyPadding.current = getOriginalBodyPadding();

    conditionallyUpdateScrollbar();

    document.body.appendChild(containerEl);

    if (Modal.openCount === 0) {
      document.body.classList.add(Modal.OPEN_CNAME);
    }
    Modal.openCount += 1;

    setContainer(containerEl);
  };

  const destroy = () => {
    if (container) {
      document.body.removeChild(container);
      setContainer(null);
    }

    if (triggeringElement.current) {
      if (typeof triggeringElement.current.focus === 'function') {
        triggeringElement.current.focus();
      }

      triggeringElement.current = null;
    }

    if (Modal.openCount <= 1) {
      document.body.classList.remove(Modal.OPEN_CNAME);
    }

    Modal.openCount -= 1;

    setScrollbarWidth(originalBodyPadding.current);

    setIsOpen(false);
  };

  const setFocus = () => {
    if (
      dialog.current
      && dialog.current.parentNode
      && typeof dialog.current.parentNode.focus === 'function'
    ) {
      dialog.current.parentNode.focus();
    }
  };

  // componentDidMount
  useEffect(() => {
    if (onEnter) {
      onEnter();
    }

    if (initialIsOpen && autoFocus) {
      setFocus();
    }

    // componentWillUnmount
    return () => {
      if (onExit) {
        onExit();
      }

      if (isOpen) {
        destroy();
      }
    };
  }, [isOpen, onExit]);

  // componentWillReceiveProp
  useEffect(() => {
    if (initialIsOpen || (initialIsOpen && !isOpen)) {
      init();
    }
    setIsOpen(initialIsOpen);
  }, [initialIsOpen]);

  useEffect(() => {
    if (autoFocus && isOpen) {
      setFocus();
    }
  }, [isOpen, autoFocus]);

  // when zIndex prop is updated
  useEffect(() => {
    if (container) {
      container.style.zIndex = zIndex;
    }
  }, [zIndex]);

  const handleOnEntered = (node, isAppearing) => {
    onOpened();
    if (modalTransition.onEntered) {
      modalTransition.onEntered(node, isAppearing);
    }
  };

  const handleOnExited = (node) => {
    // so all methods get called before it is unmounted
    onClosed();
    if (modalTransition.onExited) {
      modalTransition.onExited(node);
    }
    destroy();
  };

  const getFocusableChildren = () => {
    const focusableElements = [
      'a[href]',
      'area[href]',
      'input:not([disabled]):not([type=hidden])',
      'select:not([disabled])',
      'textarea:not([disabled])',
      'button:not([disabled])',
      'object',
      'embed',
      'audio[controls]',
      'video[controls]',
      '[contenteditable]:not([contenteditable="false"])',
      '[tabindex]:not(.modal)',
    ];
    return container.querySelectorAll(focusableElements.join(', '));
  };

  const getFocusedChild = () => {
    let currentFocus;
    const focusableChildren = getFocusableChildren();

    try {
      currentFocus = document.activeElement;
    } catch (err) {
      [currentFocus] = focusableChildren;
    }

    return currentFocus;
  };

  const handleBackdropMouseDown = (e) => {
    mouseDownElement.current = e.target;
  };

  const handleEscape = (e) => {
    const { isOpen: isCurrentStateOpen } = props;
    if (isCurrentStateOpen && keyboard && e.keyCode === 27 && toggle) {
      toggle(e);
    }
  };

  const handleBackdropClick = (e) => {
    if (e.target === mouseDownElement.current) {
      e.stopPropagation();
      if (!initialIsOpen || backdrop !== true) return;

      if (e.target && !dialog.current.contains(e.target) && toggle) {
        toggle(e);
      }
    }
  };

  const handleTab = (e) => {
    if (e.which !== 9) return;

    const focusableChildren = getFocusableChildren();
    const totalFocusable = focusableChildren.length;
    const currentFocus = getFocusedChild();

    let focusedIndex = -1;

    for (let i = 0; i < totalFocusable; i += 1) {
      if (focusableChildren[i] === currentFocus) {
        focusedIndex = i;
        break;
      }
    }

    focusedIndex += e.shiftKey ? focusableChildren.length - 1 : 1;

    focusedIndex %= focusableChildren.length;

    e.preventDefault();
    focusableChildren[focusedIndex].focus();
  };

  const modalAttributes = {
    onClick: handleBackdropClick,
    onMouseDown: handleBackdropMouseDown,
    onKeyUp: handleEscape,
    onKeyDown: handleTab,
    style: { display: 'block' },
    'aria-labelledby': labelledBy,
    role,
    tabIndex: '-1',
  };

  const computedModalTransition = {
    ...Fade.defaultProps,
    ...modalTransition,
    baseClass: fade ? modalTransition.baseClass : '',
    timeout: fade ? modalTransition.timeout : 0,
  };

  const computedBackdropTransition = {
    ...Fade.defaultProps,
    ...backdropTransition,
    baseClass: fade ? backdropTransition.baseClass : '',
    timeout: fade ? backdropTransition.timeout : 0,
  };

  const renderModalDialog = () => (
    <div
      {...attributes}
      className={classNames('modal-dialog', className, { 'modal-dialog-centered': centered })}
      role="document"
      ref={dialog}
    >
      <div className={classNames('modal-content', contentClassName)}>{children}</div>
    </div>
  );

  const Backdrop = backdrop
    && (fade ? (
      <Fade
        {...computedBackdropTransition}
        in={isOpen && !!backdrop}
        className={classNames('modal-backdrop', backdropClassName)}
      />
    ) : (
      <div className={classNames('modal-backdrop', 'show', backdropClassName)} />
    ));

  if (!isOpen || !container) {
    return null;
  }

  return (
    <Portal node={container}>
      <div className={wrapClassName}>
        <Fade
          {...modalAttributes}
          {...computedModalTransition}
          in={isOpen}
          onEntered={handleOnEntered}
          onExited={handleOnExited}
          className={classNames({
            modal: true,
            [modalClassName]: modalClassName,
            [`modal-${size}`]: size,
          })}
          innerRef={innerRef}
        >
          {external}
          {renderModalDialog()}
        </Fade>
        {Backdrop}
      </div>
    </Portal>
  );
};

const FadePropTypes = PropTypes.shape(Fade.propTypes);

Modal.propTypes = {
  autoFocus: PropTypes.bool,
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['static'])]),
  backdropClassName: PropTypes.string,
  backdropTransition: FadePropTypes,
  centered: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  external: PropTypes.node,
  fade: PropTypes.bool,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func]),
  isOpen: PropTypes.bool,
  keyboard: PropTypes.bool,
  labelledBy: PropTypes.string,
  modalClassName: PropTypes.string,
  modalTransition: FadePropTypes,
  onClosed: PropTypes.func,
  onEnter: PropTypes.func,
  onExit: PropTypes.func,
  onOpened: PropTypes.func,
  role: PropTypes.string,
  size: PropTypes.string,
  toggle: PropTypes.func,
  wrapClassName: PropTypes.string,
  zIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Modal.defaultProps = {
  isOpen: false,
  autoFocus: true,
  centered: false,
  size: '',
  toggle: null,
  role: 'dialog',
  labelledBy: '',
  backdrop: true,
  onEnter: null,
  onExit: null,
  keyboard: true,
  zIndex: 10001,
  children: null,
  className: '',
  wrapClassName: '',
  modalClassName: '',
  backdropClassName: '',
  contentClassName: '',
  external: null,
  fade: true,
  onOpened: () => {},
  onClosed: () => {},
  modalTransition: { timeout: 300 }, // matches $modal-transition (bootstrap _variables.scss)
  backdropTransition: {
    mountOnEnter: true,
    timeout: 500, // matches $transition-fade (bootstrap _variables.scss)
  },
  innerRef: null,
};

Modal.openCount = 0;

export default Modal;
