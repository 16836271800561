import React from 'react';
import PropTypes from 'prop-types';
import Button from '@prism/button';

const ModalCancel = ({ text, size, ...props }) => (
  <Button variant="textonly" size={size} {...props}>
    {text}
  </Button>
);

ModalCancel.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
};

ModalCancel.defaultProps = {
  text: 'CANCEL',
  size: null,
};

export default ModalCancel;
