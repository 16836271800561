import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FormText = (props) => {
  const {
    className,
    inline,
    color,
    tag: Tag,
    ...attributes
  } = props;

  const classes = classNames({
    className,
    'form-text': !inline,
    [`text-${color}`]: color,
  });

  return <Tag {...attributes} className={classes} />;
};

FormText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  inline: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  color: PropTypes.string,
  className: PropTypes.string,
};

FormText.defaultProps = {
  tag: 'small',
  color: 'muted',
  children: null,
  inline: false,
  className: '',
};

export default FormText;
