import {
  useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { usePopper } from 'react-popper2';
import useTooltipTimers from './useTooltipTimers';

const useTooltip = (props) => {
  const {
    className,
    delay,
    hideArrow,
    isOpen,
    modifiers,
    placement,
    size,
    target,
    targetIsFunctional,
    toggle: toggleCallback,
  } = props;

  const popperRef = useRef();

  const [arrowElement, setArrowElement] = useState(null);

  const { styles, attributes } = usePopper(target, popperRef.current, {
    placement,
    modifiers: [
      {
        name: 'arrow',
        options: { element: arrowElement },
      },
      {
        name: 'offset',
        options: { offset: [0, 8] },
      },
      ...modifiers,
    ],
  });

  const [showTooltip, setShowTooltip] = useState(isOpen);

  const toggle = (e) => {
    setShowTooltip(!showTooltip);
    toggleCallback(e, !showTooltip);
  };

  const isTouchDevice = typeof document.body.ontouchstart !== 'undefined';
  const events = [];
  if (!(targetIsFunctional && isTouchDevice)) {
    events.push('keydown', 'focusin', 'focusout', 'mouseover', 'mouseout');
  }
  if (!targetIsFunctional) {
    events.push('click');
  }

  const eventHandler = useTooltipTimers({
    delay,
    setShowTooltip,
    toggle,
    toggleCallback,
  });

  const addEventListeners = () => {
    events.forEach((event) => {
      target.addEventListener(event, eventHandler, true);
    });
  };

  const removeEventListeners = () => {
    events.forEach((event) => {
      target.removeEventListener(event, eventHandler, true);
    });
  };

  useEffect(() => {
    addEventListeners();
    return () => removeEventListeners();
  }, []);

  const computedPopperClassName = classNames({
    'prism-tooltip': true,
    [className]: className,
    [`tooltip-${size}`]: size,
  });

  return {
    showTooltip,
    tooltipProps: {
      ref: popperRef,
      style: styles.popper,
      className: computedPopperClassName,
      ...attributes.popper,
    },
    arrowProps: {
      ref: setArrowElement,
      style: {
        ...styles.arrow,
        display: hideArrow ? 'none' : 'block'
      },
      className: 'tooltip-arrow',
    },
  };
};

export default useTooltip;
