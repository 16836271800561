/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const NavLink = (props) => {
  const {
    disabled, href, onClick, className, active, tag: Tag, innerRef, ...attributes
  } = props;

  const classes = classNames(className, 'nav-link', {
    disabled,
    active,
  });
  const handleClick = (e) => {
    if (disabled) {
      e.preventDefault();
    } else {
      if (!href || href === '#') {
        e.preventDefault();
      }
      onClick(e);
    }
  };

  return (
    <Tag
      {...attributes}
      ref={innerRef}
      onClick={handleClick}
      className={classes}
    />
  );
};

NavLink.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
};

NavLink.defaultProps = {
  tag: 'a',
  innerRef: null,
  disabled: false,
  active: false,
  className: '',
  onClick: () => { },
  href: null,
};

export default NavLink;
