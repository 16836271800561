import React from 'react';
import classNames from 'classnames';
import TooltipWrapper from './TooltipWrapper';

const useTable = (props) => {
  const {
    children,
    colorHeader,
    condensed,
    darkHeader,
    headerSticky,
    lightHeader,
    ...attributes
  } = props;

  let tableHead;
  let tableBody;
  let tableFoot;
  const sortFields = [];

  const parseTableHead = (thead) => {
    // Only 1 row expected
    const row = thead.props.children.props.children;
    tableHead = row.map((th, i) => {
      const { children: thChildren, sort, sorttype, ...thAttributes } = th.props;
      if (sort) {
        sortFields.push({
          index: i,
          label: thChildren,
          sorttype: sorttype || 'alpha',
        });
      }
      return {
        attributes: thAttributes,
        label: thChildren,
        content: thChildren,
      };
    });
  };

  const parseTableBody = (tbody) => {
    tableBody = tbody.props.children.map((tr) => ( // row iterator
      tr.props.children.map((td, i) => { // cell iterator
        const { children: tdChildren, className, ...tdAttributes } = td.props;
        const hasHeader = tableHead?.length >= i;
        const isNowrap = hasHeader && tableHead[i].nowrap;
        return {
          attributes: {
            className: classNames(
              className,
              hasHeader && tableHead[i].align,
              hasHeader && tableHead[i].nowrap,
            ),
            'data-heading': hasHeader && tableHead[i].label,
            sortvalue: isNowrap && tdChildren,
            ...tdAttributes,
          },
          content: isNowrap ? <TooltipWrapper>{tdChildren}</TooltipWrapper> : tdChildren,
        };
      })
    ));
  };

  const parseTableFoot = (tfoot) => {
    // Only 1 row expected
    const row = tfoot.props.children.props.children;
    tableFoot = row.map((td, i) => {
      const { children: tdChildren, className, ...tdAttributes } = td.props;
      const hasHeader = tableHead?.length >= i;
      return {
        attributes: {
          className: classNames(className, hasHeader && tableHead[i].attributes.className),
          'data-heading': hasHeader && tableHead[i].label,
          ...tdAttributes,
        },
        content: tdChildren,
      };
    });
  };

  const thead = React.Children.toArray(children).find(
    (child) => child.key === 'tableHeader' || child.type === 'thead'
  );
  if (thead) {
    parseTableHead(thead);
  }

  const tbody = React.Children.toArray(children).find(
    (child) => child.key === 'tableBody' || child.type === 'tbody'
  );
  if (tbody) {
    parseTableBody(tbody);
  }

  const tfoot = React.Children.toArray(children).find(
    (child) => child.key === 'tableFooter' || child.type === 'tfoot'
  );
  if (tfoot) {
    parseTableFoot(tfoot);
  }

  const computedClassName = classNames({
    table: true,
    'light-header': lightHeader,
    'color-header': colorHeader,
    'dark-header': darkHeader,
    'header-sticky': headerSticky,
    condensed,
  });

  return {
    className: computedClassName,
    sortFields,
    tableBody,
    tableFoot,
    tableHead,
    ...attributes,
  };
};

export default useTable;
