import React from 'react';
import PropTypes from 'prop-types';
import useTooltip from './useTooltip';

const Tooltip = ({ children, ...props }) => {
  const { showTooltip, tooltipProps, arrowProps } = useTooltip(props);

  return showTooltip ? (
    <div role="tooltip" {...tooltipProps}>
      {children}
      {<div {...arrowProps} />}
    </div>
  ) : null;
};

export const TooltipSizes = ['sm', 'lg'];

Tooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  delay: PropTypes.oneOfType([
    PropTypes.shape({ show: PropTypes.number, hide: PropTypes.number }),
    PropTypes.number,
  ]),
  hideArrow: PropTypes.bool,
  isOpen: PropTypes.bool,
  modifiers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end',
  ]),
  size: PropTypes.oneOf(TooltipSizes),
  target: PropTypes.node.isRequired,
  targetIsFunctional: PropTypes.bool,
  toggle: PropTypes.func,
};

Tooltip.defaultProps = {
  children: null,
  className: '',
  delay: 0,
  hideArrow: false,
  isOpen: false,
  modifiers: [],
  placement: 'bottom',
  size: 'lg',
  targetIsFunctional: false,
  toggle: () => {},
};

export default Tooltip;
