/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

const SlideOutWrapper = ({ isOpen }) => (
  <CSSTransition
    classNames={{
      appear: 'slide-out-wrapper-appear',
      appearActive: 'slide-out-wrapper-appear-active',
      appearDone: 'slide-out-wrapper-appear-done',
      enter: 'slide-out-wrapper-enter',
      enterActive: 'slide-out-wrapper-enter-active',
      enterDone: 'slide-out-wrapper-enter-done',
      exit: 'slide-out-wrapper-exit',
      exitActive: 'slide-out-wrapper-exit-active',
      exitDone: 'slide-out-wrapper-exit-done',
    }}
    in={isOpen}
    timeout={100}
    unmountOnExit
  >
    <div className="slide-out-wrapper" />
  </CSSTransition>
);

SlideOutWrapper.propTypes = { isOpen: PropTypes.bool };

SlideOutWrapper.defaultProps = { isOpen: false };

export default SlideOutWrapper;
