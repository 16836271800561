import React from 'react';
import PropTypes from 'prop-types';
import { IconSvg } from '@prism/icon';

export const IconSizes = {
  xs: '15px',
  sm: '19px',
  md: '25px',
  lg: '33px',
  xl: '47px',
  xxl: '47px',
};

const Spinner = ({ color, size }) => {
  let iconSize;
  if (IconSizes[size]) {
    iconSize = IconSizes[size];
  } else {
    const parsedSize = parseInt(size, 10);
    if (!Number.isNaN(parsedSize)) {
      const oddSize = parsedSize % 2 === 0 ? parsedSize - 1 : parsedSize;
      iconSize = `${oddSize}px`;
    } else {
      iconSize = IconSizes.md;
    }
  }

  return (
    <span className="spinner-wrapper" role="presentation">
      <IconSvg
        className="spinner"
        glyph="spinner"
        color={color}
        height={iconSize}
      />
    </span>
  );
};

Spinner.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Spinner.defaultProps = {
  color: '#000000',
  size: 'md',
};

export default Spinner;
