import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const NavItem = (props) => {
  const { className, active, tag: Tag, ...attributes } = props;

  const classes = classNames(className, 'nav-item', active ? 'active' : false);

  return <Tag {...attributes} className={classes} />;
};

NavItem.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  active: PropTypes.bool,
  className: PropTypes.string,
};

NavItem.defaultProps = {
  tag: 'li',
  active: false,
  className: '',
};

export default NavItem;
