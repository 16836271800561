import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'pure-react-carousel';
import { LoadingScreen } from '@prism/imageviewer';
import classNames from 'classnames';
import {
  getTextOverlay, isSelected, textOffset,
} from './utils';
import { CATEGORIES, TIMEOUT_VALUE_IN_MS } from '../../types/constants';
import { MediaAsset } from '../../types';

const Thumbnail = (props) => {
  const {
    updateCurrentSlide,
    selectedFilmstripSlide,
    mediaAsset,
    disabled,
    filmstripCarouselStore,
    index,
    prefix,
    arrowButtonOffsetText,
  } = props;
  const [imageIsLoaded, setImageIsLoaded] = useState(undefined);
  const [maxLoadTimeExceeded, setMaxLoadTimeExceeded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (imageIsLoaded === false || imageIsLoaded === undefined) {
        setMaxLoadTimeExceeded(true);
      }
    }, TIMEOUT_VALUE_IN_MS);
  });

  const handleOnClick = () => {
    updateCurrentSlide(index, ['imageViewerCarouselStore']);
  };

  const handleOnLoad = () => {
    setImageIsLoaded(true);
  };

  const handleOnError = () => {
    setImageIsLoaded(false);
  };

  const ThumbnailIconOverlay = () => {
    const hasError = (
      maxLoadTimeExceeded === true && imageIsLoaded === undefined
    ) || imageIsLoaded === false;

    if (hasError) {
      // Error Screen
      return (
        <div className="thumbnail-not-available">
          <div className="icon-wrapper">
            <i
              className={
                mediaAsset.category === CATEGORIES.AUDIO
                  ? 'icon prism-icon-sound-off'
                  : 'icon prism-icon-image'
              }
            />
          </div>
        </div>
      );
    }
    if (maxLoadTimeExceeded === false && imageIsLoaded === undefined) {
      return <LoadingScreen classNamePrefix={prefix} text="" />;
    }
    return <div />;
  };

  const ThumbnailTextOverlay = () => {
    const imageIsSelected = isSelected(selectedFilmstripSlide, index);
    const offsetClass = textOffset(selectedFilmstripSlide, filmstripCarouselStore, prefix);
    return (
      <div
        data-testid="thumbnail-text"
        className={classNames(
          `${prefix}-selected-text`,
          {
            'text-overlay-hidden': !imageIsSelected,
            [offsetClass]: arrowButtonOffsetText,
          }
        )}
      >
        {getTextOverlay(mediaAsset.category)}
      </div>
    );
  };

  return (
    <>
      <ThumbnailIconOverlay />
      <Image
        aria-label="thumbnail-image"
        className={`${prefix}-image`}
        disabled={disabled}
        hasMasterSpinner={false}
        src={mediaAsset.src.thumbnail}
        onClick={handleOnClick}
        onLoad={handleOnLoad}
        onError={handleOnError}
      />
      <div
        className={classNames({ [`${prefix}-selected`]: isSelected(selectedFilmstripSlide, index) })}
        id={`${prefix}-${index}`}
      >
        <ThumbnailTextOverlay />
      </div>
    </>
  );
};

Thumbnail.propTypes = {
  /**
   * Callback for when the thumbnail is clicked
   */
  updateCurrentSlide: PropTypes.func,
  /**
   * Index of the currently selected slide
   */
  selectedFilmstripSlide: PropTypes.number,
  /**
   * Object containing media data for the thumbnail
   */
  mediaAsset: MediaAsset.isRequired,
  /**
   * If true, the thumbnail image is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Context object
   */
  filmstripCarouselStore: PropTypes.shape({ getStoreState: PropTypes.func })
    .isRequired,
  /**
   * Index of this thumbnail used for identification
   */
  index: PropTypes.number.isRequired,
  /**
   * Optional prefix for HTML class naming
   */
  prefix: PropTypes.string,
  /**
   * Optional prop to disable the offset text when arrow buttons are hidden, true by default
   */
  arrowButtonOffsetText: PropTypes.bool,
};

Thumbnail.defaultProps = {
  updateCurrentSlide: () => {},
  selectedFilmstripSlide: 0,
  disabled: false,
  prefix: 'thumbnail',
  arrowButtonOffsetText: true,
};

export default Thumbnail;
