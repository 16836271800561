import React from 'react';
import PropTypes from 'prop-types';
import { AccordionContext } from './AccordionCtx';

function Accordion({ children, onChange, collapseSiblings, open }) {
  const [openItem, setOpenItem] = React.useState([]);

  function getAccordionState() {
    return React.Children.map(children, (child) => {
      const childId = child.props.id;
      return { id: childId, open: openItem.includes(childId) };
    });
  }

  const ctx = {
    openItem,
    setOpenItem,
    isItemOpen: (id) => openItem.includes(id),
    toggleItem: (id) => {
      // Conditionally check for single or array value presence of self.
      const isOpen = openItem.includes(id);
      // If collapseSiblings is true, add or remove this ID as appropriate.
      if (collapseSiblings) {
        const removeCurrent = openItem.filter((i) => i !== id);
        const addCurrent = [...openItem, id];
        setOpenItem(isOpen ? removeCurrent : addCurrent);
      } else {
        setOpenItem(openItem.includes(id) ? [] : [id]);
      }
    },
  };
  // Inject an ID and our toggle function into children
  const childrenWithIdentifier = React.Children.map(
    children, (child, idx) => React.cloneElement(child, {
      id: child.props.id || `item-${idx}`,
      toggle: ctx.toggleItem,
      defaultOpen: child.props.defaultOpen === true || open,
    })
  );

  // Fire our callbacks as provided
  React.useEffect(() => {
    onChange(getAccordionState());
  }, [openItem]);

  return (
    <AccordionContext.Provider value={ctx}>
      {childrenWithIdentifier}
    </AccordionContext.Provider>
  );
}

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  open: PropTypes.bool,
  collapseSiblings: PropTypes.bool,
  onChange: PropTypes.func,
};

const defaultProps = {
  children: null,
  collapseSiblings: true,
  open: false,
  onChange: () => {},
};
Accordion.propTypes = propTypes;
Accordion.defaultProps = defaultProps;
export default Accordion;
