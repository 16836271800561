import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SortIcon from './SortIcon';

const TableHeadCell = (props) => {
  const {
    canSort,
    children,
    className,
    headerStyle,
    isSorted,
    isSortedDesc,
    onAfterSort,
    onBeforeSort,
    onClick,
    sortByProps,
    textAlign,
    toggleSortBy,
    ...attributes
  } = props;

  const handleClick = (e) => {
    e.preventDefault();

    if (canSort) {
      onBeforeSort(e);
      sortByProps?.onClick(e);
      onAfterSort(e);
    }

    onClick(e);
  };

  const computedClassName = classNames({
    'table-head-cell': true,
    [className]: className,
    'can-sort': canSort,
  });

  return (
    <th {...attributes} className={computedClassName} onClick={handleClick}>
      {children}
      {canSort && <SortIcon isSorted={isSorted} isSortedDesc={isSortedDesc} />}
    </th>
  );
};

TableHeadCell.propTypes = {
  canSort: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  headerStyle: PropTypes.oneOf(['light', 'dark', 'color']),
  isSorted: PropTypes.bool,
  isSortedDesc: PropTypes.bool,
  onAfterSort: PropTypes.func,
  onBeforeSort: PropTypes.func,
  onClick: PropTypes.func,
  sortByProps: PropTypes.shape({ onClick: PropTypes.func }),
  textAlign: PropTypes.oneOf(['left', 'right', 'center']),
  toggleSortBy: PropTypes.func,
};

TableHeadCell.defaultProps = {
  canSort: false,
  children: null,
  className: undefined,
  headerStyle: undefined,
  isSorted: undefined,
  isSortedDesc: undefined,
  onAfterSort: () => {},
  onBeforeSort: () => {},
  onClick: () => {},
  sortByProps: null,
  textAlign: 'left',
  toggleSortBy: () => {},
};

TableHeadCell.displayName = 'TableHeadCell';

export default TableHeadCell;
