import {
  isValid,
  min as dateMin,
  max as dateMax,
} from 'date-fns';

/**
 * Converts `date` to a Date object
 *
 * @param {Date | string} date
 * @returns Date
 */
export const toDate = (date) => {
  if (date) {
    const d = new Date(date);
    return isValid(d) ? d : new Date();
  }
  return new Date();
};

/**
 * Constrain the date between the min and maximum date
 * @param {Date} date
 * @param {object} options
 * @param {Date} options.min minimum date
 * @param {Date} options.max maximum date
 * @returns
 */
export const constrainDate = (
  date,
  { min = new Date(-8640000000000000), max = new Date(8640000000000000) }
) => dateMin([dateMax([date, min]), max]);

/**
 * Returns a new Function-wrapper that first executes preventDefault on the event argument
 *   and then runs the initial function.
 * @param {Function} func for wrapping
 * @returns Function
 */
export const preventDefaultEvent = (func) => (e, ...args) => {
  e.preventDefault();
  func(e, ...args);
};
