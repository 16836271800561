import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import Chip, { ChipColors } from '@prism/chip';
import Checkbox from './Checkbox';

const CheckboxList = (props) => {
  const { chipColor, data, disabled, onChange } = props;
  return (
    <div className="check-list">
      {data.map((item) => {
        const { disabled: itemDisabled, label, name, ...attributes } = item;
        return (
          <div key={uuid()} className="check-list__item">
            <Checkbox
              disabled={disabled || itemDisabled}
              name={item.name || item.label || ''}
              label={item.label || item.name || ''}
              onChange={onChange}
              className="check-list__item"
              {...attributes}
            />
            {item.count && item.count > 0 && (
              <Chip color={chipColor} className="filter-count">
                {item.count}
              </Chip>
            )}
          </div>
        );
      })}
    </div>
  );
};

CheckboxList.propTypes = {
  chipColor: PropTypes.oneOf(ChipColors),
  data: PropTypes.oneOfType([PropTypes.array]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

CheckboxList.defaultProps = {
  chipColor: 'medium',
  data: [],
  disabled: false,
  onChange: () => {},
};

export default CheckboxList;
