import React from 'react';
import PropTypes from 'prop-types';
import DrOption from './DrOption';

const propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  selectOption: PropTypes.func,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
};
const defaultProps = { label: 'Select One ...' };
function DrOptionGroup(props) {
  const { children, label, selectOption, selected, options } = props;

  const normalizeOptions = (options, label, selected, selectOption) => options.map((option) => (
    <DrOption
      selectOption={selectOption}
      selected={selected}
      group={label}
      name={option.name}
      value={option.value}
      key={option.name}
      disabled={option.disabled}
    />
  ));
  const childrenWithProps = React.Children.map(children, (child) => React.cloneElement(child, {
    selectOption,
    selected,
    name: child.props.name,
    group: label,
    value: child.props.value,
    disabled: child.props.disabled,
    children: child.props.children,
  }));

  // if passed children, render children, else if passed options render options, else null
  const renderOptions = children
    ? childrenWithProps
    : options && normalizeOptions(options, label, selected, selectOption);

  return (
    <div className="prism-select__option-group">
      <span className="prism-select__option-group-label">{label}</span>
      {renderOptions}
    </div>
  );
}
DrOptionGroup.propTypes = propTypes;
DrOptionGroup.defaultProps = defaultProps;
export default DrOptionGroup;
