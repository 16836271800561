import Searchable from './Searchable';
import { SearchableContext } from './context/SearchableContext';
import SearchableList from './SearchableList';
import SearchableSearch from './SearchableSearch';

const SearchableProvider = SearchableContext.Provider;

Searchable.Context = SearchableContext;
Searchable.Provider = SearchableContext.Provider;
Searchable.List = SearchableList;
Searchable.Search = SearchableSearch;
export {
  SearchableContext,
  SearchableProvider,
  SearchableList,
  SearchableSearch,
};

export default Searchable;
