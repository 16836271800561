import React, {
  useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import usePanel from './usePanel';

const Panel = (props) => {
  const { adaTimeoutAfter, ...rest } = props;

  const timeoutTimerRef = useRef(null);
  const [isTimedOut, setIsTimedOut] = useState(false);

  const clearTimer = () => {
    if (timeoutTimerRef.current) {
      clearTimeout(timeoutTimerRef.current);
    }
    timeoutTimerRef.current = null;
  };

  const startTimer = () => {
    clearTimer();

    if (adaTimeoutAfter && adaTimeoutAfter > 0) {
      setIsTimedOut(false);

      timeoutTimerRef.current = setTimeout(() => {
        setIsTimedOut(true);
      }, adaTimeoutAfter);
    }
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearTimer();
    };
  }, []);

  const {
    panelClassName,
    graphic,
    showMask,
    maskClassName,
    showLabel,
    label,
    timeout,
  } = usePanel({ ...rest });

  return (
    <>
      {showMask && <span className={maskClassName} />}
      <span className={panelClassName}>
        {isTimedOut ? timeout.graphic : graphic}
        {showLabel && (
        <span className="interstitial-label">
          {isTimedOut ? timeout.label : label}
        </span>
        )}
      </span>
    </>
  );
};

export const PanelColors = ['light', 'dark'];
export const PanelSizes = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

Panel.propTypes = {
  adaTimeoutAfter: PropTypes.number,
  adaTimeoutGlyph: PropTypes.string,
  adaTimeoutLabel: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.oneOf(PanelColors),
  glyph: PropTypes.string,
  inline: PropTypes.bool,
  isStatic: PropTypes.bool,
  label: PropTypes.string,
  showMask: PropTypes.bool,
  size: PropTypes.oneOf(PanelSizes),
};

Panel.defaultProps = {
  adaTimeoutAfter: 5000, // ms
  adaTimeoutGlyph: 'clock',
  adaTimeoutLabel: 'One Moment',
  className: undefined,
  color: 'dark',
  glyph: 'clock',
  inline: false,
  isStatic: false,
  label: 'Loading',
  showMask: false,
  size: undefined,
};

Panel.displayName = 'Panel';

export default Panel;
