import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

const Checkbox = (props) => {
  const {
    checked,
    className,
    disabled,
    id,
    indeterminate,
    innerRef,
    label,
    name,
    onChange,
    readonly,
    value,
    ...attributes
  } = props;

  const computedClassName = classNames(
    className,
    indeterminate && 'checkbox-indeterminate'
  );

  const handleOnChange = (e) => {
    if (onChange) {
      const targetObj = {
        id,
        checked,
        disabled,
        label,
        name,
        value,
        indeterminate,
      };
      onChange({ ...e, targetObj });
    }
  };
  const uid = uuid();
  return (
    <div className="checkbox--custom">
      <label htmlFor={name} className="itemCol itemContent checkbox-label">
        <input
          id={id || uid}
          name={name}
          type="checkbox"
          className={computedClassName}
          defaultChecked={checked}
          disabled={disabled}
          value={value}
          indeterminate={indeterminate}
          ref={innerRef}
          onChange={handleOnChange}
          {...attributes}
        />
        <span className="checkbox-label">{label}</span>
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  indeterminate: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.func,
  ]),
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
  value: PropTypes.string,
};

Checkbox.defaultProps = {
  checked: false,
  className: undefined,
  disabled: false,
  id: null,
  indeterminate: undefined,
  innerRef: null,
  label: undefined,
  name: undefined,
  onChange: () => {},
  readonly: false,
  value: undefined,
};

export default Checkbox;
