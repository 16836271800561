/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import { CardActions } from './CardActions';

export const CardHead = ({ className, id, actions, title }) => {
  const [parentClass, titleClass, actionProp] = [
    classNames('card-head', className),
    classNames('card-title', className),
    {
      actions,
      id,
      props: { show: 1, iconOnly: true },
    },
  ];
  return (
    <div className={parentClass} id={id}>
      {title && <h3 className={titleClass}>{title}</h3>}
      {actions && <CardActions {...actionProp} />}
    </div>
  );
};

CardHead.propTypes = {
  actions: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.node),
  ]),
  id: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
};
CardHead.defaultProps = {
  actions: null,
  id: uuid(),
  title: undefined,
  className: '',
};

export default CardHead;
