import React from 'react';
import PropTypes from 'prop-types';
import { InteractiveInput } from '@prism/input';
import { SearchableContext } from './context/SearchableContext';

const propTypes = {
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  disabled: PropTypes.bool,
};

const defaultProps = {
  onChange: () => {},
  onClear: () => {},
  disabled: false,
};

function SearchableSearch({ ...props }) {
  const { onChange, onClear, ...attributes } = props;
  const {
    searchValue,
    searchClass,
    handleClear,
    handleSearchChange,
    handleFocus,
  } = React.useContext(SearchableContext);

  const handleOnChange = (e) => {
    handleSearchChange(e);
    onChange(e);
  };

  const handleClickRight = (e) => {
    handleClear(e);
    onClear(e);
  };

  return (
    <div className="searchable-search " role="searchbox">
      <InteractiveInput
        placeholder="Search the list below"
        className="searchable-search__input"
        onClickRight={handleClickRight}
        onChange={handleOnChange}
        onFocus={handleFocus}
        iconRight={searchValue.length > 0 ? 'icon prism-icon-cancel-circle' : 'icon prism-icon-search'}
        value={searchValue}
        {...attributes}
      />
    </div>
  );
}
SearchableSearch.propTypes = propTypes;
SearchableSearch.defaultProps = defaultProps;

export default SearchableSearch;
