import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import Button from '@prism/button';
import Input from './Input';

const NumberInput = (props) => {
  const {
    className, id, max, min, onChange, readonly, value: initialValue,
  } = props;

  const [value, setValue] = useState(initialValue);

  const handleChange = (newValue) => {
    if (newValue >= min && newValue <= max) {
      setValue(newValue);
      onChange(newValue);
    }
  };

  const handleDecrementClick = (e) => {
    e.preventDefault();
    handleChange(value - 1);
  };

  const handleIncrementClick = (e) => {
    e.preventDefault();
    handleChange(value + 1);
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    handleChange(Number(e.target.value));
  };

  const classes = classNames(className, 'input-group number-input__group', readonly && 'is-readonly');

  return (
    <div className={classes}>
      {!readonly && (
        <div className="input-group-prepend">
          <Button
            data-test="decrement-button"
            aria-label="decrement-button"
            disabled={value <= min}
            color="secondary"
            onClick={handleDecrementClick}
            className="number-input__decrement"
            id={`${id}-decrement`}
          >
            <i className="icon prism-icon-minus3" />
          </Button>
        </div>
      )}
      <Input
        onChange={handleInputChange}
        type="text"
        className="form-control"
        id={id}
        value={value}
        placeholder="0"
        readonly={readonly}
        aria-label="number input"
        aria-describedby="basic-addon1"
      />
      {!readonly && (
        <div className="input-group-append">
          <Button
            data-test="increment-button"
            aria-label="increment-button"
            disabled={value >= max}
            color="secondary"
            onClick={handleIncrementClick}
            className="number-input__increment"
            id={`${id}-increment`}
          >
            <i className="icon prism-icon-plus" />
          </Button>
        </div>
      )}
    </div>
  );
};

NumberInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
  value: PropTypes.number,
};

NumberInput.defaultProps = {
  className: '',
  id: uuid(),
  max: 5,
  min: 0,
  onChange: () => {},
  readonly: false,
  value: 0,
};

export default NumberInput;
