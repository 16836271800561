/* eslint-disable react/jsx-filename-extension */
import classNames from 'classnames';
import Fade from '@prism/fade';

export function useMessage(props) {
  const {
    className,
    closeClassName,
    closeAriaLabel,
    tag: Tag,
    color,
    isOpen,
    toggle,
    children,
    transition,
    fade,
    title,
    innerRef,
    ...attributes
  } = props;

  const classes = classNames(
    className,
    'alert',
    'prism-message',
    `prism-message--${color}`,
    toggle && 'alert-dismissible'
  );

  const closeClasses = classNames('close', closeClassName);

  const textClasses = classNames(
    title ? 'prism-message__text--with-title' : 'prism-message__text'
  );

  const alertTransition = {
    ...Fade.defaultProps,
    ...transition,
    baseClass: fade ? transition.baseClass : '',
    timeout: fade ? transition.timeout : 0,
  };
  const tagProps = {
    ...attributes,
    ...alertTransition,
    tag: Tag,
    className: classes,
    in: isOpen,
    role: 'alert',
    innerRef,
  };

  return {
    tagProps,
    closeAriaLabel,
    closeClasses,
    toggle,
    title,
    textClasses,
    children,
    color,
  };
}

export default useMessage;
