import React from 'react';
import PropTypes from 'prop-types';

const TableRow = (props) => {
  const { children, headerGroup, numVisibleColumns, row } = props;

  const rowProps = (headerGroup && headerGroup.getHeaderGroupProps())
    || (row && row.getRowProps())
    || {};

  return (
    <>
      <tr className="table-row" {...rowProps}>
        {children}
      </tr>
      {row && row.isExpanded && row.original.expandedRow && (
        <tr className="expanded-row">
          <td colSpan={numVisibleColumns}>
            {row.original.expandedRow}
          </td>
        </tr>
      )}
    </>
  );
};

TableRow.propTypes = {
  children: PropTypes.node,
  headerGroup: PropTypes.shape({ getHeaderGroupProps: PropTypes.func }),
  numVisibleColumns: PropTypes.number,
  row: PropTypes.shape({
    getRowProps: PropTypes.func,
    isExpanded: PropTypes.bool,
    original: PropTypes.shape({ expandedRow: PropTypes.node }),
  }),
};

TableRow.defaultProps = {
  children: null,
  headerGroup: null,
  numVisibleColumns: undefined,
  row: undefined,
};

TableRow.displayName = 'TableRow';

export default TableRow;
