/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { nanoid } from 'nanoid';
import { CardHead } from './CardHead';

const propTypes = {
  actions: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.node),
  ]),
  children: PropTypes.node,
  gutter: PropTypes.oneOf([null, 'full', 'thin', 'none']),
  id: PropTypes.string,
  title: PropTypes.string,
  hasBody: PropTypes.bool,
};

export const CardBody = ({
  actions, children, gutter, id, title, hasBody,
}) => {
  if (!hasBody) return null;
  const className = classnames('card-body', {
    'gutter-none': !gutter || gutter === 'none',
    'gutter-thin': gutter === 'thin',
    'gutter-full': gutter === 'full',
  });

  return (
    <div className={className} id={id}>
      {title || actions ? (
        <CardHead actions={actions} id={`${id}-head`} title={title} />
      ) : null}
      {children}
    </div>
  );
};

CardBody.propTypes = propTypes;
CardBody.defaultProps = {
  actions: null,
  children: null,
  gutter: 'full',
  id: nanoid(),
  title: null,
  hasBody: true,
};
CardBody.displayName = 'CardBody';

export default CardBody;
