import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableContainer, TableHead, TableTop, TableBottom, TableFoot,
} from '.';
import useDataTable from '../useDataTable';

const DataTable = (props) => {
  const {
    caption, contain, height, scrollable, width, ...attributes
  } = props;

  const {
    tableTopProps,
    tableProps,
    tableHeadProps,
    tableBodyProps,
    tableFootProps,
    tableBottomProps,
  } = useDataTable(attributes);

  const containerProps = {
    contain,
    height,
    isScrollable: scrollable,
    width,
  };

  return (
    <>
      <TableTop {...tableTopProps} />
      <TableContainer {...containerProps}>
        {caption && <caption>{caption}</caption>}
        <Table {...tableProps}>
          <TableHead {...tableHeadProps} />
          <TableBody {...tableBodyProps} />
          <TableFoot {...tableFootProps} />
        </Table>
      </TableContainer>
      <TableBottom {...tableBottomProps} />
    </>
  );
};

DataTable.propTypes = {
  caption: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  columns: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  condensed: PropTypes.bool,
  contain: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  footer: PropTypes.shape({
    children: PropTypes.node,
    className: PropTypes.string,
  }),
  headerSticky: PropTypes.bool,
  headerStyle: PropTypes.oneOf(['light', 'dark', 'color']),
  height: PropTypes.string,
  manualSortBy: PropTypes.bool,
  onAfterSort: PropTypes.func,
  onBeforeSort: PropTypes.func,
  pagination: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      label: PropTypes.string,
      pageIndex: PropTypes.number,
      pageSize: PropTypes.number,
      pageSizes: PropTypes.arrayOf(PropTypes.number),
    }),
  ]),
  responsive: PropTypes.bool,
  scrollable: PropTypes.bool,
  singleSort: PropTypes.bool,
  /**
   * If true, will disables some default behaviors in favor of more control from parent via props.
   * This is useful when updating data externally.
   * May cause unintended behavior if used incorrectly. Defaults to false.
   */
  skipReset: PropTypes.bool,
  sortable: PropTypes.bool,
  sortBy: PropTypes.shape({
    id: PropTypes.string,
    desc: PropTypes.bool,
  }),
  width: PropTypes.string,
};

DataTable.defaultProps = {
  caption: null,
  columns: [],
  condensed: false,
  contain: false,
  data: [],
  footer: null,
  headerSticky: false,
  headerStyle: undefined,
  height: undefined,
  manualSortBy: false,
  onAfterSort: () => {},
  onBeforeSort: () => {},
  pagination: false,
  responsive: false,
  scrollable: false,
  singleSort: false,
  skipReset: false,
  sortable: false,
  sortBy: {},
  width: undefined,
};

DataTable.displayName = 'DataTable';

export default DataTable;
