import Input from './Input';
import InteractiveInput from './InteractiveInput';
import Label from './Label';
import NumberInput from './NumberInput';
import PasswordInput from './PasswordInput';
import PhoneInput from './PhoneInput';
import TextCounter from './TextCounter';

Input.Interactive = InteractiveInput;
Input.Label = Label;
Input.Number = NumberInput;
Input.NumberInput = NumberInput;
Input.Password = PasswordInput;
Input.Phone = PhoneInput;
Input.Text = Input;
Input.TextCounter = TextCounter;

export {
  InteractiveInput,
  Label,
  NumberInput,
  PasswordInput,
  PhoneInput,
  TextCounter,
};

export default Input;
