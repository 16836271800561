/* eslint-disable react/jsx-filename-extension */
import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import Anchor from '@prism/anchor';

export function useAvatar(props) {
  const {
    size,
    image,
    href,
    inlineText,
    name,
    firstName,
    lastName,
    initials,
  } = props;

  const [hasImage, setHasImage] = useState(image != null);
  const imageRef = useRef(null);

  const handleError = () => {
    setHasImage(false);
  }

  /**
   * @package @prism/avatar
   * @name useAvatar
   * @returns A memoized object representing computed Avatar values (rather than logic)
   * @remark - this is generally intended for "expensive computations" but is included
   * This code is executed *only* when the dependent values mutate - returning a computed
   * value from memory rather than evaluating the hook on each render.
   * here in order to document best practices.
   * */

  return React.useMemo(() => {
    const titleName = name || `${firstName} ${lastName}`;
    const [f, l] = titleName.split(' ').map((i) => i.charAt(0));
    const letters = initials || `${f}${l}`;
    const isUnidentified = !image && !letters;
    const Tag = href ? Anchor : 'div';
    const classes = classNames(
      'avatar',
      image && hasImage && 'avatar-image',
      size && `avatar-${size}`,
      !hasImage && letters && 'avatar-initials',
      isUnidentified && 'avatar-unidentfied'
    );
    const contents = (
      <span className="avatar-contents">
        {(image && hasImage) && (
          <img src={image} alt={titleName} onError={handleError} ref={imageRef} />
        )}
        {!hasImage && letters}
        {isUnidentified && <i className="icon prism-icon-user" />}
      </span>
    );
    const avatarContent = <span className={classes}>{contents}</span>;
    const textContent = (
      <span className="avatar-inline-text">{inlineText}</span>
    );
    const tagProps = {
      title: 'View account information',
      href: props.href,
      size,
    };

    return {
      href,
      letters,
      titleName,
      classes,
      contents,
      avatarContent,
      textContent,
      tagProps,
      Tag,
    };
  }, [props, hasImage]); // run this again only if props is mutated
}

export default useAvatar;
