import React, {
  useLayoutEffect, useRef, useState,
} from 'react';
import Tooltip from '@prism/tooltip';
import classNames from 'classnames';
import {
  LETTER_K, LETTER_M, TIMEOUT_VALUE_IN_MS,
} from '../../types/constants';
import MediaNotAvailable from './MediaNotAvailable';
import { naturalCountingNumber } from './utils';
import FullScreenToggle from './FullScreenToggle';
import useAgentDetect from '../../hooks/useAgentDetect';

const { IS_IOS, IS_TOUCH } = useAgentDetect();

let videoLoaded = false;
const timeout = (setMaxLoadTimeExceeded) => {
  if (videoLoaded === false) {
    setMaxLoadTimeExceeded(true);
  }
};

const VideoViewer = ({
  src,
  currentSlide,
  totalSlides,
  fullscreenHidden,
  handleFullScreenClick,
}) => {
  const progBarRef = useRef();
  const progressRef = useRef();
  const volSliderRef = useRef();
  const vidRef = useRef();
  const ratioRef = useRef();
  const [play, setPlay] = useState(false);
  const [muted, setMuted] = useState(true);
  const [hideVol, setHideVol] = useState(true);
  const [playTooltipOpen, setPlayTooltip] = useState(false);
  const [muteTooltipOpen, setMuteTooltip] = useState(false);
  const [, setMaxLoadTimeExceeded] = useState(false);
  const [, setIsVideoLoaded] = useState(undefined);
  const [hasIosPlayed, setHasIosPlayed] = useState(false);
  const [isVideoError, setIsVideoError] = useState(false);
  const [hasVideoEnded, setHasVideoEnded] = useState(false);
  const volBarInitialFrontWidthPx = 20;
  const [volBarFrontWidthPx, setVolBarFrontWidthPx] = useState(
    volBarInitialFrontWidthPx
  );

  const playPause = () => {
    const video = vidRef.current;
    if (video.paused || video.ended) {
      video.play();
      setPlay(true);
      setHasVideoEnded(false);
    } else {
      video.pause();
      setPlay(false);
    }
  };

  const toggleMute = () => {
    const video = vidRef.current;
    setMuted(!muted);
    video.muted = !muted;
  };

  const getVideoRatio = () => {
    if (vidRef.current === null) {
      return;
    }
    const video = vidRef.current;
    const seconds = new Date(video.currentTime * 1000);
    const currentProgress = seconds.toISOString().substr(14, 5);
    const durationMinutesSeconds = new Date(video.duration * 1000)
      .toISOString()
      .substr(14, 5);
    ratioRef.current.innerHTML = `${currentProgress} / ${durationMinutesSeconds}`;
  };

  const handleKeyPress = (e) => {
    if (e.key === LETTER_K) {
      playPause();
    } else if (e.key === LETTER_M) {
      toggleMute();
    }
  };

  const setVolume = (newVol) => {
    vidRef.current.volume = newVol;
    if (newVol <= 0) {
      setMuted(true);
      video.muted = true;
    } else if (newVol > 0 && muted) {
      setMuted(false);
      video.muted = false;
    }
  };

  const handleSlide = (e) => {
    const newVol = volSliderRef.current.value / 100;
    const newVolBarFrontWidthPx = newVol * 40;
    setVolume(newVol);
    setVolBarFrontWidthPx(newVolBarFrontWidthPx);
  };

  const handleMuteMouseEnter = () => {
    setHideVol(false);
  };

  const handleMuteMouseLeave = () => {
    setHideVol(true);
  };

  const toggle = (buttonName) => {
    if (buttonName === 'play') setPlayTooltip(!playTooltipOpen);
    else if (buttonName === 'mute') setMuteTooltip(!muteTooltipOpen);
  };

  const handleVideoError = () => {
    setIsVideoError(true);
  };

  const handleVideoEnded = () => {
    setHasVideoEnded(true);
  };

  const handleVideoLoadedData = () => {
    videoLoaded = true;
    setIsVideoLoaded(true);
  };

  const handleVideoLoadStart = (event) => {
    event.target.removeAttribute('autoPlay');
    if (IS_IOS) {
      vidRef.current.controls = true;
    }
  };

  useLayoutEffect(() => {
    videoLoaded = false;
    setTimeout(() => timeout(setMaxLoadTimeExceeded), TIMEOUT_VALUE_IN_MS);

    const video = vidRef.current;
    const progressBar = progBarRef.current;
    const progress = progressRef.current;

    if (video) {
      ratioRef.current.innerHTML = '00:00 / 00:00';
      vidRef.current.addEventListener('timeupdate', () => {
        progress.setAttribute('max', video.duration);
        progress.value = video.currentTime;
        progressBar.style.width = `${Math.floor(
          (video.currentTime / video.duration) * 100
        )}%`;
        getVideoRatio();
      });

      progressRef.current.addEventListener('click', function (e) {
        const pos = (e.offsetX - (this.offsetLeft + this.offsetParent.offsetLeft))
          / this.offsetWidth;
        video.currentTime = pos * video.duration;
      });

      vidRef.current.addEventListener('keyup', (e) => {
        handleKeyPress(e);
      });
    }
  }, []);

  const renderVideoComponent = () => {
    if (isVideoError) {
      return (
        <MediaNotAvailable
          classNamePrefix="imageviewer-video"
          mediaType="Video"
          iconType="image"
        />
      );
    }
    const vidControlClasses = classNames({
      'video-control-button-play': true,
      icon: true,
      'prism-icon-pause': play && !hasVideoEnded,
      'prism-icon-play': !play || hasVideoEnded,
    });
    return (
      <div className="video-viewer">
        <div className="video-viewer-wrapper">
          <video
            muted
            autoPlay
            playsInline
            id="video"
            width="100%"
            ref={vidRef}
            onPlay={(event) => {
              if (IS_IOS) {
                vidRef.current.controls = false;
                setHasIosPlayed(true);
              }
              setPlay(true);
            }}
            onError={handleVideoError}
            onEnded={handleVideoEnded}
            onLoadedData={handleVideoLoadedData}
            onLoadStart={handleVideoLoadStart}
          >
            <source src={src} type="video/mp4" />
            Your browser does not support the video element
          </video>
          <div
            id="video-controls"
            className={`
                controls
                ${IS_IOS && !hasIosPlayed ? 'd-none' : ''}
              `}
            data-state="visible"
          >
            <div>
              <div className="progress">
                <progress id="progress" value="0" min="0" ref={progressRef}>
                  <span id="progress-bar" ref={progBarRef} />
                </progress>
              </div>
            </div>
            <div className="control-buttons">
              <div onClick={playPause} className="video-player-control">
                <i className={vidControlClasses} id="play-tooltip" />
                {!window._sessionHistory && (
                  <Tooltip
                    size="sm"
                    target="play-tooltip"
                    toggle={() => toggle('play')}
                    isOpen={playTooltipOpen && !IS_TOUCH}
                  >
                    {!play ? 'Play (k)' : 'Pause (k)'}
                  </Tooltip>
                )}
              </div>
              <div
                onMouseEnter={handleMuteMouseEnter}
                onMouseLeave={handleMuteMouseLeave}
                className="video-player-control mute-btn"
              >
                <i
                  onClick={toggleMute}
                  className={`video-control-button-mute icon prism-icon-${
                    muted ? 'sound-off' : 'sound-on'
                  }`}
                  id="mute-tooltip"
                />
                {!window._sessionHistory && (
                  <Tooltip
                    targetIsFunctional
                    size="sm"
                    target="mute-tooltip"
                    toggle={() => toggle('mute')}
                    isOpen={muteTooltipOpen}
                  >
                    {muted ? 'Unmute (m)' : 'Mute (m)'}
                  </Tooltip>
                )}
                <span
                  className={`${hideVol ? 'video-player-volume-hide' : ''}`}
                >
                  <span
                    className="vol-bar-front"
                    style={{ width: `${volBarFrontWidthPx}px` }}
                  />
                  <input
                    type="range"
                    min="0"
                    max="100"
                    ref={volSliderRef}
                    onChange={handleSlide}
                    className="video-player-volume"
                  />
                </span>
              </div>
              <div id="ratio" ref={ratioRef} className="video-player-timer" />
              <span className="video-mediacount-container">
                <div className="video-mediacount-text">
                  {`${naturalCountingNumber(currentSlide)} of ${totalSlides}`}
                </div>
                <FullScreenToggle
                  fullScreenHidden={fullscreenHidden}
                  handleFullScreenClick={handleFullScreenClick}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return renderVideoComponent();
};

export default VideoViewer;
