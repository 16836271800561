import React from 'react';
import classNames from 'classnames';
import Button from '@prism/button';
import Chip from '@prism/chip';
import PropTypes from 'prop-types';
import { MultiMediaViewerConsumer } from './MultimediaViewerCtx';
import {
  CATEGORIES_LIST,
  TAB_FOCUSABLE,
  OPTIONAL_CATEGORIES_LIST,
} from '../../types/constants';

const Filters = ({
  filterMediaAssets,
  disabled,
  hidden,
  activeFilter,
  setActiveFilter,
  categoryCounts,
  showFilters,
}) => {
  const handleClick = (category, updateCurrentSlide) => {
    setActiveFilter(category);
    filterMediaAssets(category);
    updateCurrentSlide(0, [
      'imageViewerCarouselStore',
      'filmstripCarouselStore',
    ]);
  };

  const isFilterActive = (category) => activeFilter === category;

  const isDisabled = (categoryCount) => {
    const allFiltersDisabled = disabled;
    const zeroFilterMedia = categoryCount === 0;
    return allFiltersDisabled || zeroFilterMedia;
  };

  const hideEmptyOptionalFilters = (category, categoryCount) => {
    const zeroFilterMedia = categoryCount === 0;
    return OPTIONAL_CATEGORIES_LIST.indexOf(category) !== -1 && zeroFilterMedia;
  };

  const filterButtonsWithCallback = (cb) => ['ALL', ...CATEGORIES_LIST].map((category) => (
    <Button
      className={
          hideEmptyOptionalFilters(category, categoryCounts[category])
            ? 'hidden'
            : ''
        }
      key={category}
      disabled={isDisabled(categoryCounts[category])}
      tabIndex={TAB_FOCUSABLE}
      onClick={() => {
        handleClick(category, cb);
      }}
      active={isFilterActive(category)}
    >
      {category.toUpperCase()}
      {' '}
      <Chip label={`${categoryCounts[category]}`} color="dark" />
    </Button>
  ));

  const classes = classNames(
    'filters',
    disabled && 'filters-disabled',
    hidden && 'filters-hidden',
    showFilters === 'left' && 'filters-show-left'
  );

  return (
    <MultiMediaViewerConsumer>
      {({ updateCurrentSlide }) => (
        <div className={classes}>
          {filterButtonsWithCallback(updateCurrentSlide)}
        </div>
      )}
    </MultiMediaViewerConsumer>
  );
};

Filters.propTypes = {
  filterMediaAssets: PropTypes.func,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  activeFilter: PropTypes.string,
  showFilters: PropTypes.string,
  setActiveFilter: PropTypes.func,
  categoryCounts: PropTypes.object,
};

export default Filters;
