import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@prism/button';
import Input from './Input';

const PasswordInput = (props) => {
  const { readonly } = props;

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordMask = () => {
    setShowPassword(!showPassword);
  };

  const iconClassNames = classNames(
    'text-input__icon-right',
    'prism-icon',
    'icon',
    'prism-icon-md',
    'password-toggle__icon',
    {
      'prism-icon-eye-blocked text-charcoal': showPassword,
      'prism-icon-eye icon-secondary': !showPassword,
    }
  );

  return (
    <div className="d-flex align-items-center inline">
      <Input
        {...props}
        type={showPassword ? 'text' : 'password'}
        className="font-size-md form-control text-input__has-icon-right"
        placeholder="Password"
      />
      { !readonly && (
        <Button icononly className="btn-input-icononly btn-input-icononly__right" onClick={togglePasswordMask}>
          <span
            className={iconClassNames}
            name={showPassword ? 'eye' : 'eye-blocked'}
          />
        </Button>
      )}
    </div>
  );
};

PasswordInput.propTypes = { readonly: PropTypes.bool };

PasswordInput.defaultProps = { readonly: false };

export default PasswordInput;
