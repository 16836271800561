import React from 'react';
import classNames from 'classnames';

const defaultStep = { title: '' };
export function useStepper(props) {
  const { size, steps: propSteps, children, currentStep } = props;
  const className = classNames(['stepper', size === 'sm' && 'stepper-sm']);
  const createChildrenFromSteps = (_steps) => _steps.map((step, index) => {
    const prop = {
      index,
      active: currentStep === index,
      ...step,
    };

    if (prop.index < currentStep && !prop.complete) {
      prop.complete = true;
    }

    return { ...prop };
  });
  const normalizeSteps = () => {
    let steps = propSteps;

    if (children && children.length) {
      steps = React.Children.toArray(children).map((child) => {
        const { active, index, ...cProps } = child.props;
        return { ...defaultStep, ...cProps };
      });
    } else {
      steps = steps.map((s) => {
        const step = typeof s === 'string' ? { title: s } : s;
        return { ...defaultStep, ...step };
      });
    }

    const stepNodes = createChildrenFromSteps(steps);
    return stepNodes;
  };

  const getActiveStepTitle = (steps) => {
    const step = steps.find((_step) => _step.props.active) || steps[0];

    return step ? step.props.title || step.props.children : '';
  };
  return {
    normalizeSteps,
    getActiveStepTitle,
    className,
  };
}

export default useStepper;
