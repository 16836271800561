import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FormFeedback = (props) => {
  const {
    className,
    valid,
    tooltip,
    tag: Tag,
    ...attributes
  } = props;

  const validMode = tooltip ? 'tooltip' : 'feedback';

  const classes = classNames(
    className,
    valid ? `valid-${validMode}` : `invalid-${validMode}`
  );

  if (!valid) {
    attributes.invalid = 'true';
  }

  return <Tag {...attributes} className={classes} />;
};

FormFeedback.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  tag: PropTypes.string,
  className: PropTypes.string,
  valid: PropTypes.bool,
  tooltip: PropTypes.bool,
};

FormFeedback.defaultProps = {
  tag: 'div',
  valid: false,
  children: null,
  className: '',
  tooltip: false,
};

export default FormFeedback;
