import React from 'react';
import PropTypes from 'prop-types';
import PageSize from './PageSize';
import SingleSort from './SingleSort';

const TableTop = (props) => {
  const {
    columns,
    pagination,
    setSortBy,
    setPageSize,
    singleSort,
    sortBy,
  } = props;

  if (!singleSort && !pagination) {
    return null;
  }

  return (
    <div className="table-top">
      {pagination && <PageSize {...pagination} setPageSize={setPageSize} />}
      <span style={{ flexGrow: 2 }} />
      {singleSort && <SingleSort columns={columns} sortBy={sortBy} setSortBy={setSortBy} />}
    </div>
  );
};

TableTop.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    canSort: PropTypes.bool,
    Header: PropTypes.string,
    id: PropTypes.string,
    sortable: PropTypes.bool,
  })),
  pagination: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      label: PropTypes.string,
      pageIndex: PropTypes.number,
      pageSize: PropTypes.number,
    }),
  ]),
  setSortBy: PropTypes.func,
  setPageSize: PropTypes.func,
  singleSort: PropTypes.bool,
  sortBy: PropTypes.shape({
    id: PropTypes.string,
    desc: PropTypes.bool,
    label: PropTypes.string,
  }),
};

TableTop.defaultProps = {
  columns: [],
  pagination: false,
  setSortBy: () => {},
  setPageSize: () => {},
  singleSort: false,
  sortBy: null,
};

TableTop.displayName = 'TableTop';

export default TableTop;
