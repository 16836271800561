import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Nav = (props) => {
  const {
    className,
    tabs,
    pills,
    vertical,
    horizontal,
    justified,
    fill,
    navbar,
    card,
    tag: Tag,
    scroll,
    ...attributes
  } = props;

  const classes = classNames({
    scroll,
    [className]: className,
    'navbar-nav': navbar,
    nav: !navbar,
    [`justify-content-${horizontal}`]: horizontal,
    'flex-column': vertical && (vertical === true || vertical === 'xs'),
    [`flex-${vertical}-column`]: vertical && (vertical !== true && vertical !== 'xs'),
    'nav-tabs': tabs,
    'card-header-tabs': card && tabs,
    'nav-pills': pills,
    'card-header-pills': card && pills,
    'nav-justified': justified,
    'nav-fill': fill,
  });

  return <Tag {...attributes} className={classes} role="navigation" />;
};

Nav.propTypes = {
  tabs: PropTypes.bool,
  pills: PropTypes.bool,
  vertical: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  horizontal: PropTypes.string,
  justified: PropTypes.bool,
  fill: PropTypes.bool,
  navbar: PropTypes.bool,
  card: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  scroll: PropTypes.bool,
};

Nav.defaultProps = {
  tag: 'ul',
  vertical: false,
  tabs: false,
  pills: false,
  horizontal: '',
  justified: false,
  fill: false,
  navbar: false,
  card: false,
  className: '',
  scroll: false,
};

export default Nav;
