/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import Icon from '@prism/icon';
import {
  useExpanded, usePagination, useSortBy, useTable,
} from 'react-table';
import { columnFactory } from './utils';

const findSortable = (columns = [], sortable = false) => {
  let canSort = null;

  if (columns.columns) {
    canSort = findSortable(columns.columns);
  } else {
    canSort = columns.find((c) => (typeof c === 'object' && 'sortable' in c ? c.sortable : sortable)) || canSort;
  }

  return canSort;
};

const useDataTable = (props) => {
  const {
    columns,
    condensed,
    data,
    footer,
    headerSticky,
    headerStyle,
    manualSortBy,
    onAfterSort,
    onBeforeSort,
    pagination,
    responsive,
    singleSort,
    skipReset,
    sortable,
    sortBy,
  } = props;

  const canSort = findSortable(columns, sortable); // boolean or obj w/ `accessor` attr
  const isExpandableTable = data.some((row) => row.expandedRow); // boolean

  const getExpanderCell = (row) => (
    <span {...row.getToggleRowExpandedProps()}>
      <Icon
        glyph={row.isExpanded ? 'chevron-down' : 'chevron-right'}
        color="secondary"
      />
    </span>
  );

  const computedColumns = useMemo(() => {
    const inputCols = columns.map(
      columnFactory({
        sortable,
        onBeforeSort,
        onAfterSort,
      })
    );
    if (isExpandableTable) {
      const expanderCol = {
        Header: '',
        id: 'expander',
        Cell: ({ row }) => (row.original.expandedRow ? getExpanderCell(row) : null),
      };
      return [expanderCol, ...inputCols];
    }
    return inputCols;
  }, [columns]);

  let initialSortBy;
  if (sortBy.id) {
    initialSortBy = sortBy;
  } else {
    initialSortBy = canSort && canSort.accessor
      ? {
        sortBy: {
          id: canSort.accessor,
          desc: false,
        },
      }
      : {};
  }

  const initialPagination = pagination
    ? {
      pageSize: pagination.pageSize || 25,
      pageIndex: pagination.pageIndex || 0,
    }
    : {};

  const initialState = {
    sortBy: [initialSortBy],
    ...initialPagination,
  };

  const {
    flatHeaders,
    headerGroups,
    getTableProps,
    getTableBodyProps,
    gotoPage,
    page,
    pageCount,
    prepareRow,
    rows,
    state,
    setSortBy,
    setPageSize,
    visibleColumns,
  } = useTable(
    {
      columns: computedColumns,
      data: useMemo(() => data, [data]),
      defaultCanSort: false,
      disableSortRemove: true,
      manualSortBy,
      initialState,
      autoResetPage: !skipReset,
      autoResetExpanded: !skipReset,
      autoResetGroupBy: !skipReset,
      autoResetSelectedRows: !skipReset,
      autoResetSortBy: !skipReset,
      autoResetFilters: !skipReset,
      autoResetRowState: !skipReset,
    },
    useSortBy,
    isExpandableTable && useExpanded,
    usePagination
  );

  const tableTopProps = React.useMemo(
    () => ({
      columns: flatHeaders,
      setSortBy,
      setPageSize,
      singleSort,
      sortBy: state.sortBy[0] || {},
      pagination: pagination && {
        label: pagination.label,
        pageIndex: state.pageIndex,
        pageSize: state.pageSize,
        pageSizes: pagination.pageSizes,
      },
    }),
    [state.pageIndex, state.pageSize, state.sortBy, pagination]
  );

  const tableProps = {
    getTableProps,
    headerStyle,
    condensed,
    headerSticky,
    responsive,
  };

  const tableHeadProps = { headerGroups, singleSort };

  const tableBodyProps = {
    rows: pagination ? page : rows,
    getTableBodyProps,
    prepareRow,
    numVisibleColumns: visibleColumns.length,
  };

  const tableBottomProps = React.useMemo(
    () => ({
      pagination: pagination && {
        pageIndex: state.pageIndex,
        pageCount,
      },
      gotoPage,
    }),
    [pagination, state.pageIndex, pageCount]
  );

  return {
    tableTopProps,
    tableProps,
    tableHeadProps,
    tableBodyProps,
    tableFootProps: { ...footer },
    tableBottomProps,
  };
};

export default useDataTable;
