import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import Input from './Input';
import Label from './Label';

const TextCounter = (props) => {
  const {
    className,
    initialValue,
    label,
    maxLength,
    onChange,
    readonly,
    ...attributes
  } = props;

  const [value, setValue] = React.useState(initialValue);
  const remaining = maxLength - value.length;

  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(e);
  };

  const counterClassName = classNames('form-text mt-8 textarea-counter__count', { 'text-danger': remaining < 0 });

  const inputProps = {
    ...attributes,
    className: classNames('form-control text-area__textarea', className),
    defaultValue: value,
    onChange: handleChange,
    readonly,
    type: 'textarea',
  };

  return (
    <div className="textarea-counter">
      {label && <Label>{label}</Label>}
      <Input {...inputProps} />
      {!readonly && (
      <small id="textCounter" className={counterClassName}>
        {remaining}
        {' '}
        characters remaining
      </small>
      )}
    </div>
  );
};

TextCounter.propTypes = {
  /**
   * Optionally include additional classnames to be appended after default ones.
   */
  className: PropTypes.string,
  /**
   * Optionally pass a specific ID. Otherwise a UUID is generated.
   */
  id: PropTypes.string,
  /**
   * Optionally set a starting value. Unlike placeholder, this is applied to any character limits.
   */
  initialValue: PropTypes.string,
  /**
   * Optionally apply a form label
   */
  label: PropTypes.string,
  /**
   * The maximum number of characters allowed in the input.
   * Exceeding this number will yield a visual warning. Defaults to 120.
   */
  maxLength: PropTypes.number,
  /**
   * Optionally include a callback function to be called when updating the current value.
   */
  onChange: PropTypes.func,
  /**
   * Optionally set a placeholder other than the default.
   */
  placeholder: PropTypes.string,
  /**
   * Optionally set input as readonly - hides character remaining count
   */
  readonly: PropTypes.bool,
  /**
   * Textarea HTML attributes, how many rows of text tall is the input?
   */
  rows: PropTypes.string,
  /**
   * Optionally override the input type. Defaults to textarea.
   */
  type: PropTypes.string,
};

TextCounter.defaultProps = {
  className: '',
  id: uuid(),
  initialValue: '',
  label: undefined,
  maxLength: 120,
  onChange: () => {},
  placeholder: 'Enter some text',
  readonly: false,
  rows: '5',
  type: 'textarea',
};

TextCounter.displayName = 'TextCounter';

export default TextCounter;
