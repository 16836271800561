/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  className: PropTypes.string,
  selectOption: PropTypes.func,
  isSelected: PropTypes.bool,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  disabled: PropTypes.bool,
  group: PropTypes.string,
  handleClick: PropTypes.func,
};

const defaultProps = {
  selected: [],
  selectOption: () => {},
  disabled: false,
  isSelected: false,
  handleClick: () => {},
};

const DrOption = (props) => {
  const buttonRef = React.useRef(null);
  // gets a name prop.string, value prop.string, selectOption prop.func, selected prop.sting || prop.number
  const {
    name,
    children,
    value,
    selectOption,
    isSelected,
    disabled,
    group,
    id,
    handleClick,
  } = props;

  const getName = name || children;
  const getValue = value || getName;
  const getId = id || getName;

  // when prop.selected === prop.value, add active class modifier.

  const optionClass = classNames(
    'prism-select__option',
    isSelected && 'prism-select__option-selected',
    disabled && 'prism-select__option-disabled'
  );

  return (
    <button
      type="button"
      className={optionClass}
      onClick={() => handleClick(value)}
      ref={buttonRef}
      selected={isSelected}
      value={getValue}
      disabled={disabled}
      name={getName}
      title={getName}
      id={getId}
      group={group}
    >
      <span title={getName} className="prism-select__label">
        {getName}
      </span>
    </button>
  );
};

DrOption.propTypes = propTypes;
DrOption.defaultProps = defaultProps;

export default DrOption;
