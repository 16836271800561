import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

const ControlledCheckbox = (props) => {
  const {
    checked,
    className,
    id,
    indeterminate,
    innerRef,
    label,
    onChange,
    ...attributes
  } = props;

  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleOnChange = (e) => {
    setIsChecked(!isChecked);
    onChange(e);
  };

  const computedClassName = classNames(className, indeterminate && 'checkbox-indeterminate');

  return (
    <div className="checkbox--custom">
      <label htmlFor={id} className="itemCol itemContent checkbox-label">
        <input
          id={id}
          type="checkbox"
          className={computedClassName}
          checked={isChecked}
          indeterminate={indeterminate}
          onChange={handleOnChange}
          ref={innerRef}
          {...attributes}
        />
        <span className="checkbox-label">{label}</span>
      </label>
    </div>
  );
};

ControlledCheckbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  indeterminate: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.func,
  ]),
  label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.func,
  ]),
  onChange: PropTypes.func,
};

ControlledCheckbox.defaultProps = {
  checked: false,
  className: undefined,
  id: uuid(),
  indeterminate: false,
  innerRef: null,
  label: null,
  onChange: () => {},
};

export default ControlledCheckbox;
