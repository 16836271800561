import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Row = (props) => {
  const {
    className,
    noGutters,
    tag: Tag,
    form,
    ...attributes
  } = props;

  const computedClassName = classNames(
    className,
    {
      'no-gutters': noGutters,
      'form-row': form,
      row: !form,
    }
  );

  return <Tag {...attributes} className={computedClassName} />;
};

Row.propTypes = {
  className: PropTypes.string,
  form: PropTypes.bool,
  noGutters: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

};

Row.defaultProps = {
  className: undefined,
  form: false,
  noGutters: false,
  tag: 'div',
};

export default Row;
