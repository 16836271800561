import Tabs from './components/Tabs';
import TabBar from './components/TabBar';
import TabItem from './components/TabItem';
import TabPane from './components/TabPane';

Tabs.TabBar = TabBar;
Tabs.TabItem = TabItem;
Tabs.TabPane = TabPane;

export { TabBar, TabItem, TabPane };

export default Tabs;
