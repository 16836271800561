/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { deprecated } from '../Utilities/utils';
import SlideOutContent from './SlideOutContent';
import SlideOutHeader from './SlideOutHeader';
import SlideOutTrigger from './SlideOutTrigger';
import SlideOutWrapper from './SlideOutWrapper';

const SlideOut = (props) => {
  const {
    setToggle, // deprecated
    actionable,
    children,
    headerText,
    maxWidth,
    onCloseButtonClick,
    open,
    placement,
    triggerClassName,
    triggerHref,
    triggerTag,
    triggerText,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const toggleOpen = () => {
    setIsOpen(true);
  };

  const toggleClose = () => {
    if (actionable && isOpen) {
      // setToggle is deprecated - give onCloseButtonClick precedence
      if (onCloseButtonClick) {
        onCloseButtonClick();
      } else if (setToggle) {
        setToggle();
      }
    } else {
      setIsOpen(false);
    }
  };

  return (
    <>
      <SlideOutWrapper headerText={headerText} isOpen={isOpen} />
      <SlideOutContent
        role="document"
        toggleClose={toggleClose}
        maxWidth={maxWidth}
        isOpen={isOpen}
        placement={placement}
        headerText={headerText}
      >
        {children}
      </SlideOutContent>
      {!actionable && (
        <SlideOutTrigger
          tag={triggerTag}
          text={triggerText}
          className={triggerClassName}
          onClick={toggleOpen}
          href={triggerHref}
        />
      )}
    </>
  );
};

SlideOut.Header = SlideOutHeader;

SlideOut.propTypes = {
  setToggle: deprecated(PropTypes.func, 'Please use the prop `onCloseButtonClick'),
  actionable: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  headerText: PropTypes.string,
  maxWidth: PropTypes.string,
  onCloseButtonClick: PropTypes.func,
  open: PropTypes.bool,
  placement: PropTypes.string,
  triggerClassName: PropTypes.string,
  triggerHref: PropTypes.string,
  triggerTag: PropTypes.string,
  triggerText: PropTypes.string,
};

SlideOut.defaultProps = {
  actionable: false,
  children: null,
  headerText: undefined,
  maxWidth: undefined,
  onCloseButtonClick: () => {},
  open: false,
  placement: 'left',
  triggerClassName: 'btn btn-outline',
  triggerHref: undefined,
  triggerTag: 'button',
  triggerText: undefined,
};

export default SlideOut;
