import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

const TabSizes = ['sm', 'md'];
export function useTabs(props) {
  const {
    activeIndex: propActiveIndex,
    border,
    children,
    className: classNameFromProps,
    color,
    data,
    iconOnly,
    id,
    onChange,
    size,
    textOnly,
  } = props;
  const [activeIndex, setActiveIndex] = useState(propActiveIndex);
  const [childArray, setChildArray] = useState(
    React.Children.toArray(children)
  );
  const normalizeTabProps = (tabProps, index = -1) => {
    const newProps = {
      iconOnly,
      textOnly,
      id: `${id}-${index}`,
      size: size || TabSizes[1],
      ...tabProps,
    };

    delete newProps.children;
    delete newProps.isAnimated;
    delete newProps.panelIndex;

    return newProps;
  };

  const dataToTabPropsAndContent = (config) => {
    const {
      content,
      disabled,
      label,
      tabClassName,
      tabId,
      ...args
    } = config;
    const contentProps = {
      className: classNames(tabClassName),
      disabled,
      id: args.id,
    };

    if (typeof label === 'string') {
      contentProps.label = label;
    } else {
      if (label.text) {
        contentProps.label = label.text;
      }
      if (label.icon) {
        contentProps.addOnPrepend = label.icon;
      }
    }

    return [
      normalizeTabProps(contentProps),
      <div className={classNames(args.className)} id={tabId}>
        {content}
      </div>,
    ];
  };

  const tabPropsFromChildrenOrData = ({ children: childrenProp, data: dataObj }) => {
    if (dataObj && Array.isArray(dataObj)) {
      return dataObj.map(dataToTabPropsAndContent).map((t) => t[0]);
    }

    return React.Children.toArray(childrenProp).map((ch, idx) => normalizeTabProps(ch.props, idx));
  };

  const [tabs, setTabs] = useState(
    tabPropsFromChildrenOrData({ children, data })
  );

  useEffect(() => {
    const childArrayObj = React.Children.toArray(children);
    setTabs(childArrayObj.map((ch, idx) => normalizeTabProps(ch.props, idx)));
    setChildArray(childArrayObj);
  }, [size, children]);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      const tabPropsAndContent = data.map(dataToTabPropsAndContent);

      setTabs(tabPropsAndContent.map((t) => t[0]));
      setChildArray(tabPropsAndContent.map((t) => t[1]));
    }
  }, [data]);

  const updateHandleClick = (index) => (e) => {
    if (index === activeIndex) {
      return;
    }

    setActiveIndex(index);

    if (tabs[index].onClick) {
      tabs[index].onClick(e);
    }

    if (onChange) {
      onChange({
        ...e,
        prevActiveIndex: index,
        activeIndex,
      });
    }
  };

  const className = classNames(
    'tabs',
    'tab-container',
    { [`tabs-${color}`]: !!color },
    classNameFromProps,
  );

  const childrenProps = tabs.length
    ? tabs
    : tabPropsFromChildrenOrData({ children, data });

  return {
    childrenProps,
    className,
    childArray,
    updateHandleClick,
    id,
    tabs,
    border,
    activeIndex,
  };
}

export default useTabs;
