import React from 'react';
import PropTypes from 'prop-types';
import Button from '@prism/button';
import ButtonGroup from '@prism/buttongroup';

const PageSize = ({ label, pageSize, pageSizes, setPageSize }) => (
  <div className="table-row-size">
    <span className="mr-2">{label}</span>
    <ButtonGroup>
      {pageSizes.map((size) => (
        <Button
          key={size}
          variant="outline"
          size="sm"
          active={pageSize === size}
          onClick={() => setPageSize(size)}
        >
          {size}
        </Button>
      ))}
    </ButtonGroup>
  </div>
);

PageSize.propTypes = {
  label: PropTypes.string,
  pageSize: PropTypes.number,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  setPageSize: PropTypes.func,
};

PageSize.defaultProps = {
  label: 'Results per page',
  pageSize: 25,
  pageSizes: [25, 50, 100],
  setPageSize: () => {},
};

PageSize.displayName = 'PageSize';

export default PageSize;
