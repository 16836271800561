import { useRef } from 'react';

const useTooltipTimers = (props) => {
  const { delay, setShowTooltip, toggle, toggleCallback } = props;

  const getDelay = (key) => {
    if (typeof delay === 'object') {
      return Number.isNaN(delay[key]) ? 0 : delay[key];
    }
    return Number.isNaN(delay) ? 0 : delay;
  };

  const showTimer = useRef();
  const hideTimer = useRef();

  const clearTimers = () => {
    clearTimeout(showTimer.current);
    showTimer.current = null;
    clearTimeout(hideTimer.current);
    hideTimer.current = null;
  };

  const show = (e) => {
    setShowTooltip(true);
    toggleCallback(e, true);
  };

  const hide = (e) => {
    setShowTooltip(false);
    toggleCallback(e, false);
  };

  const eventHandler = (e) => {
    switch (e.type) {
      case 'mouseover':
        clearTimers();
        showTimer.current = setTimeout(() => {
          show(e);
        }, getDelay('show'));
        break;

      case 'mouseout':
        clearTimers();
        showTimer.current = setTimeout(() => {
          hide(e);
        }, getDelay('hide'));
        break;

      case 'focusin':
        show(e);
        break;

      case 'focusout':
        hide(e);
        break;

      case 'click':
        toggle(e);
        break;

      case 'keydown':
        if (e.key === 'Escape') {
          setShowTooltip(false);
          toggleCallback(e, false);
        }
        break;

      default:
        break;
    }
  };

  return eventHandler;
};

export default useTooltipTimers;
