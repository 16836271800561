import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ModalFooter = (props) => {
  const {
    className,
    tag: Tag,
    children,
    size,
    ...attributes
  } = props;

  const classes = classNames('modal-footer', className);

  return (
    <Tag {...attributes} className={classes}>
      {children}
    </Tag>
  );
};

ModalFooter.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  size: PropTypes.string,
};

ModalFooter.defaultProps = {
  tag: 'div',
  className: '',
  children: null,
  size: null,
};

export default ModalFooter;
