import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { deprecated } from '../Utilities/utils';

const Step = ({
  index,
  title,
  children,
  active,
  complete,
  optional,
  href,
  onClick,
}) => {
  const props = { onClick };
  const isComplete = complete || props.incomplete === false;
  const className = classNames(
    'step',
    active && 'active',
    isComplete && 'complete',
    (href || onClick) && 'interactive'
  );

  const optionalText = optional ? ' (optional)' : '';
  const content = children || title;

  let stepNode = (
    <span className="title-text">
      {content}
      {optionalText}
    </span>
  );

  if (href) {
    stepNode = (
      <a href={href} className="title-text">
        {stepNode}
      </a>
    );
  }

  props.className = className;

  return (
    <li {...props} data-index={index} data-label={index + 1}>
      {stepNode}
    </li>
  );
};

Step.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  active: PropTypes.bool,
  optional: PropTypes.bool,
  incomplete: deprecated(
    PropTypes.bool,
    '`incomplete` prop has been deprecated. Please use `complete:false` instead '
  ),
  complete: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Step.defaultProps = {
  index: 0,
  active: false,
  optional: false,
  incomplete: undefined, // deprecated
  complete: false,
  href: '',
  onClick: null,
  title: '',
  children: null,
};

export default Step;
