import React from 'react';
import PropTypes from 'prop-types';
import Button from '@prism/button';

const ModalOkay = ({ text, size, ...props }) => (
  <Button color="primary" size={size} {...props}>
    {text}
  </Button>
);

ModalOkay.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
};

ModalOkay.defaultProps = {
  text: 'Okay',
  size: null,
};

export default ModalOkay;
