import React, { useContext } from 'react';
import ImageViewerContext from './ImageViewerContext';

export default ({ zoomMax }) => {
  const [{ zoomLevel }, { setZoomLevel }] = useContext(ImageViewerContext);

  const handleZoomOutClick = (e) => {
    setZoomLevel(Math.max(1, zoomLevel - 0.2));
  };

  const handleZoomInClick = (e) => {
    setZoomLevel(zoomLevel + 0.2);
  };

  return (
    <span className="incremental-zoom-controls">
      <button
        className="no-button-styling media-button"
        disabled={zoomLevel <= 1}
        onClick={handleZoomOutClick}
      >
        <span className="icon prism-icon-zoom-out" />
      </button>

      <button
        className="no-button-styling media-button"
        disabled={zoomLevel >= zoomMax}
        onClick={handleZoomInClick}
      >
        <span className="icon prism-icon-zoom-in" />
      </button>
    </span>
  );
};
