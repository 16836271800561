/* eslint-disable import/prefer-default-export */
export const getConstrainedBounds = (currentPage, totalPages, rangeLength) => {
  let leftBound = 1;
  let rightBound = 1;
  if (totalPages <= rangeLength) {
    rightBound = totalPages;
  } else {
    const leftOffset = Math.max(1, currentPage - Math.floor(rangeLength / 2));
    const rightOffset = Math.min(totalPages, leftOffset + rangeLength - 1);

    if (rightOffset === totalPages && totalPages - rangeLength > 0) {
      leftBound = rightOffset - rangeLength + 1;
    } else {
      leftBound = leftOffset;
    }
    rightBound = rightOffset;
  }
  return { rightBound, leftBound };
};
