import React from 'react';
import PropTypes from 'prop-types';

const generateHeaderTitle = (date, locale = 'en-US') => `${date.toLocaleString(locale, { month: 'long' })} ${date.getFullYear()}`;

const Header = (props) => {
  const {
    decreaseMonth,
    decreaseYear,
    increaseMonth,
    increaseYear,
    isMonthPicker,
    selectedDate,
    showMonthPicker,
  } = props;

  const currentSelectedDate = new Date(selectedDate);

  if (isMonthPicker) {
    return (
      <div className="picker-header-navigation">
        <button
          type="button"
          onClick={decreaseYear}
          className="navigation-button link"
          aria-label="decrease-year"
        >
          <i className="icon prism-icon-chevron-left" />
        </button>
        <div className="current-month">{currentSelectedDate.getFullYear()}</div>
        <button
          type="button"
          onClick={increaseYear}
          className="navigation-button link"
          aria-label="increase-year"
        >
          <i className="icon prism-icon-chevron-right" />
        </button>
      </div>
    );
  }
  return (
    <div className="picker-header-navigation">
      <button
        type="button"
        onClick={decreaseMonth}
        className="navigation-button link"
        aria-label="decrease-month"
      >
        <i className="icon prism-icon-chevron-left-light" />
      </button>
      <button
        type="button"
        onClick={showMonthPicker}
        className="current-month link"
        aria-label="month-picker"
      >
        {generateHeaderTitle(currentSelectedDate)}
      </button>
      <button
        type="button"
        onClick={increaseMonth}
        className="navigation-button link"
        aria-label="increase-month"
      >
        <i className="icon prism-icon-chevron-right-light" />
      </button>
    </div>
  );
};

Header.propTypes = {
  decreaseMonth: PropTypes.func.isRequired,
  decreaseYear: PropTypes.func.isRequired,
  increaseMonth: PropTypes.func.isRequired,
  increaseYear: PropTypes.func.isRequired,
  isMonthPicker: PropTypes.bool,
  selectedDate: PropTypes.instanceOf(Date),
  showMonthPicker: PropTypes.func.isRequired,
};

Header.defaultProps = {
  isMonthPicker: false,
  selectedDate: new Date(),
};

Header.displayName = 'DatePicker.Header';

export default Header;
