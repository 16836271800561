/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@prism/button';
import Chip from '@prism/chip';
import SlideOut from '@prism/slideout';
import classnames from 'classnames';

export function AdaptiveSelect({
  className,
  disabled,
  label,
  labelIcon,
  multiselect,
  onChange,
  options,
  placeholder,
  required,
  selections,
  viewName,
}) {
  const [slideoutOpen, setSlideoutOpen] = React.useState(false);
  const [selected, setSelected] = React.useState([...selections]);
  const [hasError, setHasError] = React.useState(false);

  React.useEffect(() => {
    if (required) {
      setHasError(selected.length < 1);
    }
  }, [selected]);

  const numberWithCommas = (x) => {
    if (!x || !x.toString) {
      return x;
    }

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const toggleSlideout = () => setSlideoutOpen(!slideoutOpen);

  const toggleSelected = (value) => {
    if (selected.includes(value)) {
      setSelected(selected.filter((s) => s !== value));
    } else if (multiselect) {
      setSelected([...selected, value]);
    } else {
      setSelected([value]);
    }
  };

  const clearSelected = () => {
    setSelected([]);
  };

  const selectAll = () => {
    const all = options.map((item) => item.label);
    setSelected(all);
  };

  React.useEffect(() => {
    onChange(selected);
  }, [selected]);

  const showPlaceholder = () => !hasError && selected?.length < 1 && placeholder;

  const parentClasses = classnames('adaptive-select', { disabled }, className);
  const inputClasses = classnames(
    'd-flex',
    'align-items-center',
    'adaptive-select-selection-block',
    { 'has-error': hasError }
  );
  return (
    <div className={parentClasses}>
      <button
        type="button"
        disabled={disabled}
        className="adaptive-select-actions d-flex justify-content-between align-items-center"
        onClick={toggleSlideout}
      >
        <span className="adaptive-select-label mr-3">
          {labelIcon && <i className={`icon prism-icon-${labelIcon} mr-2`} />}
          {label}
        </span>
        <div className={inputClasses}>
          {hasError && (
            <>
              <i className="icon prism-icon-error-triangle mr-2" />
              <span className="adaptive-select-selection">Required</span>
            </>
          )}

          <span
            className={classnames(
              'adaptive-select-selection',
              { 'adaptive-select-selection-placeholder': showPlaceholder() }
            )}
          >
            {showPlaceholder()}
            {selected?.length === 1 ? selected[0] : null}
            {selected?.length > 1
              ? `${selected.length} selections`
              : null}
          </span>

          <i className="icon prism-icon-chevron-right-light ml-2" />
        </div>
      </button>

      <SlideOut
        actionable
        onCloseButtonClick={toggleSlideout}
        open={slideoutOpen}
        placement="right"
      >
        <div className="d-flex p-3 bg-light justify-content-between align-items-center">
          <div className="flex-1 mr-2">
            <Button variant="textonly" size="sm" onClick={toggleSlideout}>
              <i className="icon prism-icon-chevron-left-light mr-2" />
              {viewName && viewName.length <= 12 ? viewName : 'Back'}
            </Button>
          </div>

          <h5 className="text-center mb-0">{label}</h5>

          <div className="flex-1 ml-2 text-right">
            {multiselect ? (
              <Button variant="textonly" size="sm" onClick={toggleSlideout}>
                Done
              </Button>
            ) : null}
          </div>
        </div>

        <div className="adaptive-select-options">
          {multiselect ? (
            <div>
              {selected.length === options.length ? (
                  <div
                    role="presentation"
                    className="adaptive-select-option d-flex justify-content-between align-items-center border-bottom-0"
                    onClick={clearSelected}
                  >
                    <span className="option-label" aria-label="clear-selection">
                      Clear All
                    </span>
                  </div>
                ) : (
                  <div
                    role="presentation"
                    className="adaptive-select-option d-flex justify-content-between border-bottom-0"
                    onClick={selectAll}
                  >
                    <span className="option-label" aria-label="select-all">
                      Select All
                    </span>
                  </div>
                )}
            </div>
          ) : null}

          {options.map((option, i) => (
            <div
              role="presentation"
              className={classnames('adaptive-select-option d-flex justify-content-between', option.disabled && 'disabled')}
              key={option.id}
              onClick={() => {
                if (!option.disabled) {
                  toggleSelected(option.label);
                  if (!multiselect) {
                    toggleSlideout();
                  }
                }
              }}
            >
              <span
                className="option-label"
                aria-roledescription={`item-${i}-label`}
              >
                {option.label}
              </span>

              <div className="option-decorators">
                {typeof option.count === 'number' && (
                  <span className="ml-3">
                    <Chip
                      transparent
                      label={numberWithCommas(option.count)}
                    />
                  </span>
                )}

                <i
                  className={`icon prism-icon-checkmark ml-2 option-check ${
                    selected.indexOf(option.label) >= 0 ? 'selected' : ''
                  }`}
                />
              </div>
            </div>
          ))}
        </div>
      </SlideOut>
    </div>
  );
}

AdaptiveSelect.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  labelIcon: PropTypes.string,
  multiselect: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  selections: PropTypes.arrayOf(PropTypes.string),
  viewName: PropTypes.string,
};

AdaptiveSelect.defaultProps = {
  className: '',
  disabled: false,
  label: '',
  labelIcon: '',
  multiselect: false,
  onChange: () => {},
  options: [],
  placeholder: '',
  required: false,
  selections: [],
  viewName: '',
};

export default AdaptiveSelect;
