import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import Actions from '@prism/actions';

const propTypes = {
  actions: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.node),
  ]),
  id: PropTypes.string,
  props: PropTypes.oneOfType([PropTypes.object]),
};

export const CardActions = ({ actions = null, id = uuid(), props = {} }) => {
  if (!actions) {
    return null;
  }

  let actionsNode = null;

  if (isValidElement(actions)) {
    actionsNode = actions;
  } else if (Array.isArray(actions)) {
    const actionsProps = {
      menuAlignment: 'right',
      ...props,
    };

    if (isValidElement(actions[0])) {
      actionsNode = (
        <Actions key={id} {...actionsProps}>
          {actions}
        </Actions>
      );
    } else {
      actionsNode = <Actions key={id} {...actionsProps} actions={actions} />;
    }
  }

  return actionsNode;
};

CardActions.propTypes = propTypes;
CardActions.displayName = 'CardActions';

export default CardActions;
