import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Chip from '@prism/chip';
import Button from '@prism/button';

function RefinementsHeader(props) {
  const {
    className,
    onSaveAll,
    saveAllLabel,
    onClearAll,
    clearAllLabel,
    onItemClick,
    items,
    title,
  } = props;

  const classes = classNames('refinements-header', className);

  const handleItemClick = (e, item) => {
    if (onItemClick) {
      onItemClick(e, item);
    } else {
      e.preventDefault();
    }
  };

  const handleSaveAllClick = (e) => onSaveAll && onSaveAll(e);
  const handleClearAllClick = (e) => onClearAll && onClearAll(e);

  const titleTag = title ? (
    <h2 className="refinements-header-title">{title}</h2>
  ) : null;

  const saveAll = onSaveAll ? (
    <Button size="sm" variant="textonly" onClick={handleSaveAllClick}>
      {saveAllLabel}
    </Button>
  ) : null;

  const clearAll = onClearAll ? (
    <Button size="sm" variant="textonly" onClick={handleClearAllClick}>
      {clearAllLabel}
    </Button>
  ) : null;

  const getItems = () => items.map((item) => (
    <Chip
      type="filter"
      onClick={(e) => handleItemClick(e, item)}
      key={`refinment-header-item-${Math.random()}`}
    >
      {item.label}
    </Chip>
  ));

  return (
    <div className={classes}>
      <header className="refinements-header-section">
        {titleTag}
        <div className="refinements-header-actions">
          {saveAll}
          {clearAll}
        </div>
      </header>
      <div className="refinements-header-items-list">{getItems()}</div>
    </div>
  );
}

RefinementsHeader.propTypes = {
  className: PropTypes.string,
  onSaveAll: PropTypes.func,
  onClearAll: PropTypes.func,
  onItemClick: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired })
  ),
  title: PropTypes.string,
  saveAllLabel: PropTypes.string,
  clearAllLabel: PropTypes.string,
};

RefinementsHeader.defaultProps = {
  title: null,
  items: [],
  saveAllLabel: 'Save',
  clearAllLabel: 'Clear All',
  className: '',
  onSaveAll: () => { },
  onClearAll: () => { },
  onItemClick: () => { },
};

export default RefinementsHeader;
