import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useClickAway } from 'react-use';
import Button from '@prism/button';
import ButtonGroup from '@prism/buttongroup';
import { Icon } from '@prism/icon';
import { titleCase } from '../../Utilities/utils';

const SingleSort = (props) => {
  const { columns, label, setSortBy, sortBy } = props;

  const [sortOpen, setSortOpen] = React.useState(false);

  const popupRef = useRef();
  useClickAway(popupRef, () => {
    setSortOpen(false);
  });

  const sortableFields = columns.reduce((fields, col) => {
    if (col.sortable && col.canSort) {
      fields.push({
        id: col.id,
        label: col.Header,
      });
    }
    return fields;
  }, []);

  if (!sortableFields.length) {
    return null;
  }

  const handleSortClick = (col, desc) => () => {
    setSortOpen(false);
    setSortBy([{ id: col, desc: !!desc }]);
  };

  return (
    <div className="table-single-sort sortControls popup-container" aria-label="single-sort">
      <span className="mr-2">{label}</span>
      <Button
        variant="actionLink"
        onClick={() => setSortOpen(true)}
        aria-label="current-sort"
      >
        {sortBy.label
            || (columns.find((col) => col.id === sortBy.id) || {}).Header
            || titleCase(sortBy.id)}
        <i
          className={`btn-icon__right icon prism-icon-arrow-${
            sortBy.desc ? 'down' : 'up'
          }`}
        />
      </Button>
      <div ref={popupRef} className={classNames('popup', sortOpen && 'open')}>
        {sortableFields.map((field) => (
          <div key={`table-sortby-${field.id}`} className="table-single-sort-row">
            <span className="table-single-sort-row-label">{field.label}</span>
            <ButtonGroup>
              <Button
                icononly
                variant="outline"
                size="sm"
                active={sortBy.id === field.id && !sortBy.desc}
                onClick={handleSortClick(field.id)}
                aria-label={`${field.label
                  .toLowerCase()
                  .split(' ')
                  .join('-')}-ascending-button`}
              >
                <Icon glyph="arrow-up" />
              </Button>
              <Button
                icononly
                variant="outline"
                size="sm"
                active={sortBy.id === field.id && sortBy.desc}
                onClick={handleSortClick(field.id, true)}
                aria-label={`${field.label
                  .toLowerCase()
                  .split(' ')
                  .join('-')}-descending-button`}
              >
                <Icon glyph="arrow-down" />
              </Button>
            </ButtonGroup>
          </div>
        ))}
      </div>
    </div>
  );
};

SingleSort.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    canSort: PropTypes.bool,
    Header: PropTypes.string,
    id: PropTypes.string,
    sortable: PropTypes.bool,
  })),
  label: PropTypes.string,
  setSortBy: PropTypes.func,
  sortBy: PropTypes.shape({
    desc: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
  }),
};
SingleSort.defaultProps = {
  columns: [],
  label: 'Sort by',
  setSortBy: () => {},
  sortBy: {},
};

export default SingleSort;
