import classNames from 'classnames';

const useCol = (props) => {
  const {
    className, widths, xs, sm, md, lg, xl, ...attributes
  } = props;

  // Aggregate classnames for each supported width
  const colClasses = widths.map((sizeName, i) => {
    // Get the props for the given size
    let sizeProps;
    switch (sizeName) {
      case 'xs': sizeProps = xs; break;
      case 'sm': sizeProps = sm; break;
      case 'md': sizeProps = md; break;
      case 'lg': sizeProps = lg; break;
      case 'xl': sizeProps = xl; break;
      default: break;
    }

    if (sizeProps || sizeProps === '') {
      const isXs = !i; // apparently the first entry is always xs??
      const nameCls = isXs ? '' : `-${sizeName}`;
      const size = typeof sizeProps === 'object' ? sizeProps.size : sizeProps;
      const valueCls = size && size !== true ? `-${size}` : '';
      const { order, offset } = sizeProps;

      return classNames({
        [`col${nameCls}${valueCls}`]: size || size === '',
        [`order${nameCls}-${order}`]: order || order === 0,
        [`offset${nameCls}-${offset}`]: offset || offset === 0,
      });
    }
    return null;
  });

  const filteredColClasses = colClasses.filter((_class) => _class !== null);
  const computedClassName = classNames(className, filteredColClasses.length ? filteredColClasses : 'col');

  const result = { className: computedClassName, ...attributes };
  return result;
};

export default useCol;
