import React, {
  useRef, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';
import { FormGroup, FormFeedback } from '@prism/form';
import Icon from '@prism/icon';
import { Label } from '@prism/input';
import InputGroup from '@prism/inputgroup';
import {
  UNDEFINED, CNAME,
  SIZE_SM,
  SIZE_LG,
} from '../CONSTANTS';
import {
  toTime, generateTime, constrainTime,
} from '../utils';

const TimePicker = ({
  afterClose,
  afterClickOutside,
  className: propClassName,
  format,
  defaultValue,
  inputClassName: propInputClassName,
  invalid,
  label,
  maxTime,
  minTime,
  onChange,
  placeholder,
  size,
  timeIntervals,
  triggerClassName: propTriggerClassName,
  valid,
  readonly,
  ...attrs
}) => {
  const timeValue = constrainTime(toTime(defaultValue), {
    min: minTime,
    max: maxTime,
  });

  const [showTime, setShowTime] = useState(!!defaultValue);
  const [selectedTime, setSelectedTime] = useState(timeValue);
  const timePickerRef = useRef(null);

  const hasMessage = typeof invalid === 'string';

  useEffect(() => {
    if (timeValue !== selectedTime) {
      setSelectedTime(timeValue);
    }
  }, [defaultValue]);

  const handleTimeChange = (time) => {
    setSelectedTime(time);
    setShowTime(true);
    if (onChange) {
      onChange(time);
    }
  };

  const focusOnSelectedTime = () => {
    const cname = '.react-datepicker__time-list-item--selected';
    if (!requestAnimationFrame) return;
    requestAnimationFrame(() => {
      const { componentNode } = (timePickerRef.current && timePickerRef.current.calendar) || {};
      const domSelectied = componentNode && componentNode.querySelector(cname);
      if (domSelectied) {
        domSelectied.focus();
      } else {
        timePickerRef.current.setFocus();
      }
    });
  };

  const openTimePicker = () => {
    timePickerRef.current.setOpen(true);
    focusOnSelectedTime();
  };

  const { className, inputClassName, triggerClassName } = {
    className: classnames({
      [CNAME]: true,
      picker: true,
      [`picker-${size}`]: size === SIZE_SM,
      [propClassName]: propClassName,
      'has-message': !!hasMessage,
      'is-valid': valid,
      'is-invalid': invalid,
    }),
    inputClassName: classnames({
      [`${CNAME}-input`]: true,
      'form-control': true,
      [`form-control-${size}`]: size === SIZE_SM,
      'inset-right': true,
      [propInputClassName]: propInputClassName,
      'has-validation': !!hasMessage,
      'is-valid': valid,
      'is-invalid': invalid,
      'is-readonly': readonly,
    }),
    triggerClassName: classnames({
      [`${CNAME}-trigger`]: true,
      btn: true,
      'input-group-action': true,
      [`btn-${size}`]: size === SIZE_SM,
      [propTriggerClassName]: propTriggerClassName,
    }),
  };

  return (
    <>
      <FormGroup className={className} aria-label="time-picker">
        {label ? <Label fontSize={size}>{label}</Label> : null}
        <InputGroup>
          <DatePicker
            {...attrs}
            className={inputClassName}
            dateFormat={format}
            maxTime={generateTime(toTime(maxTime), selectedTime)}
            minTime={generateTime(toTime(minTime), selectedTime)}
            onCalendarClose={afterClose}
            onChange={handleTimeChange}
            onClickOutside={afterClickOutside}
            placeholderText={placeholder}
            ref={timePickerRef}
            selected={showTime ? selectedTime : null}
            readOnly={readonly}
            showTimeSelect
            showTimeSelectOnly
            timeCaption="Time"
            timeIntervals={timeIntervals}
            wrapperClassName="time-picker-container"
          />
          {!readonly && (
            <button
              type="button"
              className={triggerClassName}
              onClick={openTimePicker}
              disabled={attrs.disabled}
            >
              <Icon glyph="clock" />
            </button>
          )}
          {!readonly && invalid && hasMessage ? (
            <FormFeedback>
              <Icon glyph="notification-circle" className="small mr-1" />
              {invalid}
            </FormFeedback>
          ) : null}
        </InputGroup>
      </FormGroup>
    </>
  );
};

TimePicker.propTypes = {
  afterClickOutside: PropTypes.func,
  afterClose: PropTypes.func,
  className: PropTypes.string,
  format: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  disabled: PropTypes.bool,
  inputClassName: PropTypes.string,
  invalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  label: PropTypes.string,
  maxTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  minTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf([SIZE_SM, SIZE_LG]),
  timeIntervals: PropTypes.number,
  triggerClassName: PropTypes.string,
  valid: PropTypes.bool,
  readonly: PropTypes.bool,
};

TimePicker.defaultProps = {
  afterClose: () => {},
  className: '',
  inputClassName: '',
  label: '',
  onChange: () => {},
  triggerClassName: '',
  afterClickOutside: () => {},
  format: 'h:mm aaa',
  defaultValue: UNDEFINED,
  disabled: false,
  invalid: false,
  maxTime: new Date('1/1/0100 23:59:59'),
  minTime: new Date('1/1/0100 00:00:00'),
  placeholder: 'hh:mm',
  timeIntervals: 15,
  size: SIZE_LG,
  valid: false,
  readonly: false,
};

TimePicker.displayName = 'TimePicker';

export default TimePicker;
