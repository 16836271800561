import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import Step from './Step';
import { useStepper } from './useStepper';

const Stepper = (props) => {
  const { getActiveStepTitle, normalizeSteps, className } = useStepper(props);

  let steps = normalizeSteps(props);
  steps = steps.map((p) => <Step {...p} key={uuid()} />);
  const activeStep = (
    <span className="active-step title-text">
      {getActiveStepTitle(steps)}
    </span>
  );

  return (
    <div className={className}>
      <ol className="steps">{steps}</ol>
      {activeStep}
    </div>
  );
};

Stepper.propTypes = {
  currentStep: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.string,
};

Stepper.defaultProps = {
  currentStep: 1,
  size: 'lg',
};

export default Stepper;
