import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TableDataCell = (props) => {
  const { className, headerLabel, textAlign, ...attributes } = props;

  const computedClassName = classNames({
    'table-data-cell': true,
    [className]: className,
    [`text-${textAlign}`]: textAlign,
  });

  return <td className={computedClassName} data-heading={headerLabel} {...attributes} />;
};

TableDataCell.propTypes = {
  className: PropTypes.string,
  headerLabel: PropTypes.node,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

TableDataCell.defaultProps = {
  className: undefined,
  headerLabel: null,
  textAlign: undefined,
};

TableDataCell.displayName = 'TableDataCell';

export default TableDataCell;
