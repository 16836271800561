import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ModalHeader = (props) => {
  const {
    className,
    children,
    toggle,
    tag: Tag,
    wrapTag: WrapTag,
    closeAriaLabel,
    charCode,
    close,
    ...attributes
  } = props;

  const classes = classNames('modal-header', className);

  let closeButton;

  if (!close && toggle) {
    closeButton = (
      <button
        type="button"
        onClick={toggle}
        className="modal-header--close close"
        aria-label={closeAriaLabel}
      >
        <span className="icon prism-icon-cross" />
      </button>
    );
  }

  return (
    <WrapTag {...attributes} className={classes}>
      <Tag className="modal-header-title">{children}</Tag>
      {close || closeButton}
    </WrapTag>
  );
};

ModalHeader.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  wrapTag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  toggle: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  closeAriaLabel: PropTypes.string,
  charCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  close: PropTypes.oneOfType([PropTypes.object]),
};

ModalHeader.defaultProps = {
  tag: 'h3',
  wrapTag: 'div',
  toggle: null,
  className: '',
  children: null,
  closeAriaLabel: 'Close',
  charCode: 215,
  close: null,
};

export default ModalHeader;
