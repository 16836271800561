import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '@prism/dropdown';

const InputGroupButtonDropdown = (props) => <Dropdown {...props} />;

InputGroupButtonDropdown.propTypes = {
  addonType: PropTypes.oneOf(['prepend', 'append']).isRequired,
  children: PropTypes.node,
};

InputGroupButtonDropdown.defaultProps = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default InputGroupButtonDropdown;
