import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@prism/tooltip';

const TooltipWrapper = (props) => {
  const { children } = props;

  const [showTooltip, setShowTooltip] = useState(false);

  const triggerRef = useRef();

  const toggleTooltip = () => setShowTooltip(!showTooltip);

  return (
    <div
      className="TooltipWrapper"
      onMouseEnter={toggleTooltip}
      onMouseLeave={toggleTooltip}
      ref={triggerRef}
    >
      {children}
      <Tooltip
        size="lg"
        target={triggerRef.current}
        isOpen={showTooltip}
      >
        {children}
      </Tooltip>
    </div>
  );
};

TooltipWrapper.propTypes = { children: PropTypes.node };

TooltipWrapper.defaultProps = { children: null };

export default TooltipWrapper;
