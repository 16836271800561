import { Card as CardRoot } from './components/Card';
import {
  CardActions,
  CardBody,
  CardFooter,
  CardHead,
  CardLayout,
  CardMedia,
} from './components';

export {
  CardActions, CardBody, CardFooter, CardHead, CardLayout, CardMedia,
};

export const Card = Object.assign(CardRoot, {
  Actions: CardActions,
  Body: CardBody,
  Footer: CardFooter,
  Head: CardHead,
  Layout: CardLayout,
  Media: CardMedia,
});
export { useCard } from './useCard';
export default Card;
