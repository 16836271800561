import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const InputGroupText = (props) => {
  const { className, tag: Tag, ...attributes } = props;

  const classes = classNames(
    className
  );

  return <Tag {...attributes} className={classes} />;
};

InputGroupText.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  className: PropTypes.string,
};

InputGroupText.defaultProps = {
  tag: 'span',
  className: 'input-group-text',
};

export default InputGroupText;
