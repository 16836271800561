/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

function RadioButton(props) {
  const {
    name,
    id = uuid(),
    counts,
    className,
    disabled,
    label,
    onChange,
    value,
  } = props;

  const { checked, ...otherProps } = { ...props };
  const inputProps = {
    ...otherProps,
    defaultChecked: checked,
    name: name || label,
  };

  const classes = classNames(
    className,
    'prism-radio ',
    disabled && 'prism-radio--disabled'
  );

  return (
    <div className={classes} role="option" aria-selected={value}>
      <label
        onChange={onChange}
        className=" align-items-center d-flex"
        htmlFor={id}
      >
        <input type="radio" {...inputProps} />
        <label htmlFor={name || label} role="term" className="itemCol">
          {label || name}
        </label>
        {counts && (
          <div className="d-flex ml-auto">
            <span className="prism-radio__counts">{counts}</span>
          </div>
        )}
      </label>
    </div>
  );
}

RadioButton.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  required: PropTypes.bool,
  counts: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

RadioButton.defaultProps = {
  id: undefined,
  checked: false,
  disabled: false,
  label: null,
  value: undefined,
  name: 'radio',
  required: false,
  counts: null,
  className: '',
  onChange: () => { },
  onClick: () => { },
};

export default RadioButton;
