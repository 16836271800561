import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@prism/accordion';

function RefinementsPanel(props) {
  const {
    children,
    title,
    onSubmit,
    heading,
    onClear,
  } = props;
  const accordionProps = {
    onSubmit,
    onClear,
    title,
  };

  return (
    <Accordion>
      <Accordion.Input {...accordionProps}>
        <div className="refinement-item__heading">{heading && heading}</div>
        {children}
      </Accordion.Input>
    </Accordion>
  );
}

RefinementsPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClear: PropTypes.func,
  title: PropTypes.string,
  heading: PropTypes.string,
};

RefinementsPanel.defaultProps = {
  children: null,
  onChange: () => { },
  onSubmit: () => { },
  onClear: () => { },
  title: null,
  heading: null,
};

export default RefinementsPanel;
