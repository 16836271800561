/* eslint-disable react/jsx-filename-extension */
import React, {
  useMemo, useRef, useState,
} from 'react';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useClickAway } from 'react-use';
import DropToggle from './DropToggle';
import DropdownMenu from './DropdownMenu';
import DrOption from './DrOption';
import DrOptionGroup from './DrOptionGroup';

const Dropdown = (props) => {
  const {
    multiple,
    placeholder,
    onChange: propOnChange,
    options: propOptions,
    valid: propsValid,
    invalid: propsInvalid,
    isOpen: propIsOpen,
    defaultSelection,
    disabled,
    readonly,
    containerClasses,
    size,
    children: propChildren,
    style,
  } = props;
  const [selected, setSelected] = useState(defaultSelection);
  const [isOpen, setIsOpen] = useState(propIsOpen); // props.isOpen
  const [isFocused, setIsFocused] = useState(false);

  const ref = useRef(null);

  function dismiss() {
    setIsOpen(false);
    setIsFocused(false);
  }

  function getPropsFromArg() {
    if (!propOptions) return [];
    const stringAsProps = (option, sIdx) => ({
      value: `${option}`,
      label: `${option}`,
      id: nanoid(),
      name: `${option}`,
      key: `${sIdx} - ${option}`,
      isSelected: selected.includes(option),
    });
    const objAsProps = (option, osIdx) => ({
      ...option,
      value: option.value,
      label: option.name || option.value,
      id: nanoid(),
      name: option.name || option.value,
      key: `${osIdx} - ${option.id || option.value}`,
      isSelected: selected.includes(option),
    });
    return propOptions.map((opt, idx) => {
      if (typeof opt === 'string') {
        return stringAsProps(opt, idx);
      }
      return objAsProps(opt, idx);
    });
  }
  function getPropsFromChild(child, idx) {
    const { label, children } = child.props;
    const isGroup = label && children;

    const childAsProps = (elem, elemIdx) => {
      const { id, name, value, children: innerText } = elem.props;
      return {
        ...elem.props,
        id: id || value,
        key: `${elemIdx} - ${id || value}-${nanoid()}`,
        name: name || innerText || value,
        value: value || innerText || name || id,
        isSelected: selected.includes(value),
      };
    };
    const groupAsProps = (groupLabel, groupChildren) => {
      const list = React.Children.toArray(groupChildren);
      const items = list.map(childAsProps);
      return { label: groupLabel, key: nanoid(), items };
    };

    return isGroup ? groupAsProps(label, children) : childAsProps(child, idx);
  }

  function toggleSelection(val) {
    const alreadySelected = selected.includes(val);
    if (multiple) {
      if (alreadySelected) {
        setSelected(selected.filter((s) => s !== val));
      } else {
        setSelected([...selected, val]);
      }
    } else if (!alreadySelected) {
      setSelected([val]);
      dismiss();
    }
  }

  const tree = useMemo(() => {
    const jsxChildren = React.Children.toArray(propChildren);
    const jsxOptions = jsxChildren.map(getPropsFromChild);
    const argOptions = getPropsFromArg();
    return [...jsxOptions, ...argOptions];
  }, [selected]);

  const { options, renderList, heading } = useMemo(() => {
    function handleOptionClick(val) {
      toggleSelection(val);
      const { name, isSelected } = options.find((i) => i.value === val);
      propOnChange({
        val, name, isSelected, multiple,
      });
    }
    const newOptions = [].concat(
      ...tree.map((item) => (item.items ? item.items : item))
    );
    return {
      options: newOptions,
      renderList: (
        <>
          {tree.map((i) => {
            if (i.items) {
              return (
                <DrOptionGroup key={`group-${i.label}`} label={i.label}>
                  {i.items.map((item) => (
                    <DrOption
                      key={item.key}
                      {...item}
                      handleClick={(v) => handleOptionClick(v)}
                    />
                  ))}
                </DrOptionGroup>
              );
            }
            return (
              <DrOption
                key={i.key}
                {...i}
                handleClick={(v) => handleOptionClick(v)}
              />
            );
          })}
        </>
      ),
      heading: newOptions
        .filter((i) => selected.includes(i.value))
        .map((i) => i.name)
        .join(', '),
    };
  }, [tree]);

  /** Always close the dropdown when clicking outside */
  useClickAway(ref, () => {
    dismiss();
  });
  const dropdownClass = classNames('prism-select', `prism-select-${size}`, [
    ...containerClasses,
  ]);

  return (
    <div ref={ref} className={dropdownClass} style={style}>
      <DropToggle
        isFocused={isFocused}
        hasSelection={JSON.stringify(heading) !== '""'}
        heading={heading || placeholder}
        disabled={disabled}
        readonly={readonly}
        invalid={propsInvalid}
        valid={propsValid}
        toggleMenu={() => setIsOpen(!isOpen)}
      />
      <DropdownMenu
        key={nanoid()}
        open={isOpen}
        heading={placeholder}
        placeholder={placeholder || 'Select One...'}
      >
        {renderList}
      </DropdownMenu>
      <input type="hidden" title="dropdown-input-value" value={selected} />
    </div>
  );
};

Dropdown.propTypes = {
  /**
   * Standard HTML ID attribute.
   */
  id: PropTypes.string,
  children: PropTypes.node,
  /**
   * Standard HTML placeholder text to display before user input.
   */
  placeholder: PropTypes.string,
  /**
   * If true, the dropdown will be disabled and interaction limited.
   */
  disabled: PropTypes.bool,
  /**
   * If true, the dropdown will be readonly and interaction limited.
   */
  readonly: PropTypes.bool,
  /**
   * If true, behavior will be adjusted to allow multiple selections.
   */
  multiple: PropTypes.bool,
  /**
   * Allow the dropdown to initially display open. Has no effect after initial rendering. **Controlled**
   */
  isOpen: PropTypes.bool,
  /**
   * If true, the dropdown will act as if it has a valid input initially. **Controlled**
   */
  valid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * If true, the dropdown will act as if it has an invalid input initially. **Controlled**
   */
  invalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * Optionally append classnames to the container element - this does not override the default classnames.
   */
  containerClasses: PropTypes.string,
  /**
   * Optional callback for when the selected values change. Four arguments are passed:
   * onChange({ value, name, selected, multiple });
   * value of item, name of item, is it selected, are multiple allowed (true/false)
   * */
  onChange: PropTypes.func,
  /**
   * Optionally indicate initial select by passing an array of values found in children.
   */
  defaultSelection: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  /** Rather than children, options can be passed as an array. Recommended. */
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  /** Size, optionally render a smaller version */
  size: PropTypes.oneOf(['sm', 'md']),
  /** Optionally inject CSS as modules */
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Dropdown.defaultProps = {
  multiple: false,
  isOpen: false,
  id: nanoid(),
  defaultSelection: [],
  containerClasses: '',
  children: [],
  options: undefined,
  onChange: () => true,
  valid: null,
  invalid: null,
  disabled: false,
  readonly: false,
  placeholder: 'Select One...',
  size: 'md',
  style: {},
};

export default Dropdown;
