/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';
import { CardActions } from './CardActions';

const propTypes = {
  actions: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.node),
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  gutter: PropTypes.oneOf([null, 'none', 'thin', 'full']),
  id: PropTypes.string,
  mediaIsBackground: PropTypes.bool,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.oneOfType([PropTypes.object]),
  hasMedia: PropTypes.bool,
};

export const CardMedia = ({
  actions,
  children,
  hasMedia,
  className: propClassName,
  gutter,
  id,
  mediaIsBackground,
  src,
  style: propStyle,
}) => {
  if (!hasMedia) return null;
  const mediaNode = typeof src === 'string' ? (
    <img
      alt=""
      src={src}
      style={{ maxWidth: '100%' }}
    />
  ) : (
    src
  );
  const backgroundImageSrc = typeof src === 'string' ? src : null;

  const className = classnames('card-media', propClassName, {
    'gutter-none': !gutter || gutter === 'none',
    'gutter-thin': gutter === 'thin',
    'gutter-full': gutter === 'full',
  });

  const style = {
    ...propStyle,
    backgroundImage:
      mediaIsBackground
      && typeof src === 'string'
      && `url(${backgroundImageSrc}`,
  };

  return (
    <div className={className} style={style} id={id}>
      {mediaNode}
      {actions ? (
        <CardActions
          actions={actions}
          id={`${id}-actions`}
          props={{ show: 1, iconOnly: true }}
        />
      ) : null}
      {children ? <div className="media-content">{children}</div> : null}
    </div>
  );
};

CardMedia.propTypes = propTypes;
CardMedia.displayName = 'CardMedia';
CardMedia.defaultProps = {
  actions: null,
  children: null,
  hasMedia: true,
  className: null,
  gutter: 'none',
  id: uuid(),
  mediaIsBackground: false,
  src: null,
  style: null,
};

export default CardMedia;
