import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SearchableList from './SearchableList';
import SearchableSearch from './SearchableSearch';
import { SearchableContext } from './context/SearchableContext';

function Searchable({ children }) {
  const [search, setSearch] = useState({
    value: '',
    isSearching: false,
  });
  const setIsSearching = (s) => setSearch((val) => ({ ...val, isSearching: s }));
  const setSearchValue = (v) => setSearch((sta) => ({ ...sta, value: v }));

  useEffect(() => {
    setIsSearching(search.value !== '');
  }, [search.value]);

  const searchClass = classnames(
    search.isSearching && 'icon prism-icon-cancel-circle searchable-search__input-clear'
  );

  const getInputProps = () => ({
    searchClass,
    value: search.value,
    handleBlur: () => setIsSearching(false),
    handleFocus: () => setIsSearching(true),
    handleClear: () => setSearchValue(''),
    handleSearchChange: (e) => {
      setSearchValue(e.target.value);
    },
  });
  const ctx = {
    search,
    setSearch,
    isSearching: search.isSearching,
    searchValue: search.value,
    inputProps: getInputProps(),
    ...getInputProps(),
  };

  return (
    <SearchableContext.Provider value={ctx}>
      {children}
    </SearchableContext.Provider>
  );
}
Searchable.propTypes = { children: PropTypes.node };
Searchable.defaultProps = { children: null };
Searchable.List = SearchableList;
Searchable.Search = SearchableSearch;

export default Searchable;
