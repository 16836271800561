import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import Button from '@prism/button';
import { InputGroupAddon } from '@prism/inputgroup';
import { deprecated } from '../Utilities/utils';
import Label from './Label';
import Input from './Input';
import PhoneInput from './PhoneInput';
import FormGroup from '../Form/FormGroup';
import useInput from './useInput';

const InteractiveInput = (props) => {
  const {
    state, // deprecated
    static: staticInput, // deprecated
    addon,
    bsSize,
    className,
    iconAfter,
    iconBefore,
    iconLeft,
    iconRight,
    id,
    inline,
    innerRef,
    invalid,
    label,
    name,
    onClickAfter,
    onClickBefore,
    onClickLeft,
    onClickRight,
    plaintext,
    readonly,
    size,
    tag,
    type,
    valid,
    ...attributes
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordMask = () => {
    setShowPassword(!showPassword);
  };

  const { className: useInputClasses } = useInput(props);

  const inputClasses = classNames(
    useInputClasses,
    {
      'text-input__has-icon-left': !readonly && iconLeft,
      'text-input__has-icon-right': !readonly && (iconRight || type === 'password'),
    }
  );

  const passwordIconClasses = classNames({
    'text-input__icon-right': true,
    icon: true,
    'prism-icon-md': true,
    'password-toggle__icon': true,
    'prism-icon-eye icon-secondary': !showPassword,
    'prism-icon-eye-blocked text-charcoal': showPassword,
  });

  const iconLeftClasses = classNames({
    'text-input__icon-left': true,
    icon: true,
    'prism-icon-mobile': type === 'phone',
    [iconLeft]: type !== 'phone',
  });
  const iconRightClasses = classNames('text-input__icon-right', iconRight);
  const iconBeforeClasses = classNames(iconBefore);
  const iconAfterClasses = classNames(iconAfter);

  const labelClasses = classNames({
    'interactive-input__label': true,
    'icon-before': iconBefore && !inline,
  });

  const inputType = type === 'password' && showPassword ? 'text' : type;

  return (
    <FormGroup inline={inline}>
      {label && <Label className={labelClasses} for={id}>{label}</Label>}
      <span className="d-flex align-items-center inline">
        {(!readonly && iconBefore) && (
          <InputGroupAddon onClick={onClickBefore} addonType="prepend">
            <span className={iconBeforeClasses} />
          </InputGroupAddon>
        )}
        {(!readonly && iconLeft) && (
          <Button icononly className="btn-input-icononly btn-input-icononly__left" onClick={onClickLeft}>
            <span className={iconLeftClasses} />
          </Button>
        )}
        {type === 'phone' ? (
          <PhoneInput
            {...attributes}
            id={id}
            name={name && name}
            className={inputClasses}
            readonly={readonly}
          />
        ) : (
          <Input
            {...attributes}
            id={id}
            name={name && name}
            innerRef={innerRef}
            className={inputClasses}
            readonly={readonly}
            type = {inputType}
          />
        )}
        {!readonly && iconRight && (
          <Button icononly className="btn-input-icononly btn-input-icononly__right" role="button" onClick={onClickRight}>
            <span className={iconRightClasses} />
          </Button>
        )}
        {(!readonly && type === 'password') && (
          <Button icononly className="btn-input-icononly btn-input-icononly__right" onClick={togglePasswordMask}>
            <span className={passwordIconClasses} />
          </Button>
        )}
        {!readonly && iconAfter && (
          <InputGroupAddon onClick={onClickAfter} addonType="append" role="option">
            <span className={iconAfterClasses} />
          </InputGroupAddon>
        )}
      </span>
    </FormGroup>
  );
};

InteractiveInput.propTypes = {
  state: deprecated(
    PropTypes.string,
    'Please use the props "valid" and "invalid" to indicate the state.'
  ),
  static: deprecated(PropTypes.bool, 'Please use the prop "plaintext"'),
  addon: PropTypes.bool,
  bsSize: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  iconAfter: PropTypes.string,
  iconBefore: PropTypes.string,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  id: PropTypes.string,
  inline: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  invalid: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClickAfter: PropTypes.func,
  onClickBefore: PropTypes.func,
  onClickLeft: PropTypes.func,
  onClickRight: PropTypes.func,
  plaintext: PropTypes.bool,
  readonly: PropTypes.bool,
  size: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  type: PropTypes.string,
  valid: PropTypes.bool,
};

InteractiveInput.defaultProps = {
  state: undefined, // deprecated
  static: undefined, // deprecated
  addon: false,
  bsSize: '',
  children: null,
  className: '',
  iconAfter: undefined,
  iconBefore: undefined,
  iconLeft: undefined,
  iconRight: undefined,
  id: uuid(),
  inline: false,
  innerRef: null,
  invalid: undefined,
  label: undefined,
  name: undefined,
  onChange: () => {},
  onClickAfter: () => {},
  onClickBefore: () => {},
  onClickLeft: () => {},
  onClickRight: () => {},
  plaintext: false,
  readonly: false,
  size: '',
  tag: 'input',
  type: 'text',
  valid: undefined,
};

export default InteractiveInput;
