/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ButtonGroup = ({
  className,
  size,
  vertical,
  tag: Tag,
  ...attributes
}) => {
  const classes = classNames(
    className,
    `btn-group-${size}`,
    vertical ? 'btn-group-vertical' : 'btn-group'
  );

  return <Tag {...attributes} className={classes} />;
};

ButtonGroup.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  className: PropTypes.string,
  role: PropTypes.string,
  size: PropTypes.string,
  vertical: PropTypes.bool,
};

ButtonGroup.defaultProps = {
  tag: 'div',
  role: 'group',
  className: '',
  size: 'md',
  vertical: false,
};

export default ButtonGroup;
