import React, {
  useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import Panel from './Panel';

const Interstitial = (props) => {
  const {
    displayDelay,
    isActive,
    target,
    ...rest
  } = props;

  const delayTimerRef = useRef(null);
  const targetRef = target && !target.tagName ? target : useRef(target || null);

  const [showInterstitial, setShowInterstitial] = useState(isActive && !displayDelay);

  const clearTimer = () => {
    if (delayTimerRef.current) {
      clearTimeout(delayTimerRef.current);
      delayTimerRef.current = null;
    }
  };

  const startTimer = () => {
    clearTimer();

    if (displayDelay && displayDelay > 0) {
      delayTimerRef.current = setTimeout(() => {
        setShowInterstitial(true);
      }, displayDelay);
    }
  };

  const unmount = () => {
    clearTimer();
    if (targetRef.current) {
      targetRef.current.classList.remove('position-relative', 'interstitial-target');
    }
    setShowInterstitial(false);
  };

  // delay displaying component
  useEffect(() => {
    if (isActive) {
      startTimer();
    } else {
      unmount();
    }

    return () => {
      unmount();
    };
  }, [isActive]);

  if (showInterstitial) {
    if (targetRef.current) {
      targetRef.current.classList.add('position-relative', 'interstitial-target');
      return createPortal(<Panel {...rest} targetRef={targetRef} showMask />, targetRef.current);
    }
    return <Panel {...rest} />;
  }
  return null;
};

Interstitial.propTypes = {
  displayDelay: PropTypes.number,
  isActive: PropTypes.bool,
  target: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.shape({ current: PropTypes.element }),
  ]),
};

Interstitial.defaultProps = {
  displayDelay: 250,
  isActive: true,
  target: null,
};

Interstitial.displayName = 'Interstitial';

export default Interstitial;
