import React, {
  useContext, useEffect, useState,
} from 'react';
import { v4 as uuid } from 'uuid';
import ImageViewerContext from './ImageViewerContext';
import MediaNotAvailable from './MediaNotAvailable';
import { TIMEOUT_VALUE_IN_MS } from '../../types/constants';

let isImageLoaded = false;

const timeout = (setMaxLoadTimeExceeded) => {
  if (isImageLoaded === false) {
    setMaxLoadTimeExceeded(true);
  }
};

const OSDWrapper = ({ dzi, src, className, updateZoomCB, zoomMax }) => {
  const [{ zoomLevel }, { setZoomLevel, setInstance }] = useContext(
    ImageViewerContext
  );
  const id = React.useMemo(() => uuid(), []);
  const tileSource = dzi ? src : { type: 'image', url: src };
  const [osdIsLoaded, setOsdIsLoaded] = useState(undefined);
  const [maxLoadTimeExceeded, setMaxLoadTimeExceeded] = useState(false);

  useEffect(() => {
    isImageLoaded = false;
    setTimeout(() => timeout(setMaxLoadTimeExceeded), TIMEOUT_VALUE_IN_MS);

    const OpenSeadragon = require('openseadragon');
    try {
      const viewer = OpenSeadragon({
        id: `imageviewer-${id}`,
        tileSources: tileSource,
        visibilityRatio: 1.0,
        constrainDuringPan: true,
        homeFillsViewer: true,
        showNavigator: false,
        showNavigationControl: false,
        defaultZoomLevel: 1,
        navigatorDisplayRegionColor: '#FFC20E',
        gestureSettingsMouse: { zoomToRefPoint: false },
        gestureSettingsTouch: { zoomToRefPoint: false },
        gestureSettingsUnknown: { zoomToRefPoint: false },
      });

      setInstance(viewer.viewport);

      viewer.addHandler('open-failed', () => {
        setOsdIsLoaded(false);
        viewer.element.removeChild(viewer.container);
      });
      viewer.addHandler('open', () => {
        setOsdIsLoaded(true);
      });

      viewer.addHandler('zoom', (e) => {
        if (zoomLevel !== e.zoom) {
          setZoomLevel(e.zoom);
        }
      });
    } catch (ignore) {}
  }, []);

  const validatedOSDComponent = () => {
    if (osdIsLoaded === true) {
      isImageLoaded = true;
      return (
        <div
          className={`imageviewer-image ${className}`}
          id={`imageviewer-${id}`}
        />
      );
    }
    if (osdIsLoaded === false && maxLoadTimeExceeded === true) {
      return (
        <MediaNotAvailable
          classNamePrefix="imageviewer-photo"
          mediaType="Photo"
          iconType="image"
        />
      );
    }
    return <div id={`imageviewer-${id}`} />;
  };

  return validatedOSDComponent();
};

export default OSDWrapper;
