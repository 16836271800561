import React, { useEffect } from 'react';
import { tagSetup } from './utils';
import MediaNotAvailable from './MediaNotAvailable';
import { TIMEOUT_VALUE_IN_MS } from '../../types/constants';

let is360Loaded = false;

const timeout = (setMaxLoadTimeExceeded) => {
  if (is360Loaded === false) {
    setMaxLoadTimeExceeded(true);
  }
};

const Fyusion = ({ id, filmstripThumbnail }) => {
  const [overlayVisible, setOverlayVisible] = React.useState(false);
  const [fyusionIsLoaded, setFyusionIsLoaded] = React.useState(undefined);
  const [maxLoadTimeExceeded, setMaxLoadTimeExceeded] = React.useState(false);

  useEffect(() => {
    is360Loaded = false;
    setTimeout(() => timeout(setMaxLoadTimeExceeded), TIMEOUT_VALUE_IN_MS);

    const scriptPromise = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://cdn.fyusion.com/assets/fyu-viewer-latest.js';
      script.async = true;
      script.onload = resolve;
      document.body.appendChild(script);
    });

    Promise.all([scriptPromise]).then(() => {
      FYU.add(id, `element_id-${id}`, {
        autoplay: true,
        preload: true,
        nologo: true,
        fullscreen: false,
        tags: true,
        intro: false,
        pauseOutOfViewPort: false,
      })
        .on('complete', setFyusionIsLoaded(true))
        .on('complete', () => {
          setOverlayVisible(true);
        })
        .on('load-tags', tagSetup(id, filmstripThumbnail))
        .on('error', () => setFyusionIsLoaded(false));
    });
  }, []);

  const validatedFyusionComponent = () => {
    if (fyusionIsLoaded === true && maxLoadTimeExceeded === false) {
      is360Loaded = true;
      return (
        <>
          <div
            className={`fyusion-overlay ${
              !overlayVisible && 'fyusion-overlay-hidden'
            }`}
            onClick={() => {
              setOverlayVisible(false);
            }}
          >
            <div className="fyusion-overlay-background">
              <span className="fyusion-overlay-icon icon prism-icon-360" />
              <span className="fyusion-overlay-text">Click and Drag</span>
            </div>
          </div>
          <div
            id={`element_id-${id}`}
            className="fy_auto"
            key={`mmv-fyusion-${id}`}
          />
        </>
      );
    }
    if (fyusionIsLoaded === false || maxLoadTimeExceeded === true) {
      return (
        <MediaNotAvailable
          classNamePrefix="imageviewer-360"
          mediaType="360"
          iconType="360"
        />
      );
    }
    return null;
  };

  return validatedFyusionComponent();
};

export default Fyusion;
