export const stringifyAttrs = (attributes) => Object.entries(attributes).reduce(
  (acc, [key, value]) => ({
    ...acc,
    ...(typeof value === 'boolean'
      ? { [key]: String(value) }
      : { [key]: value }),
  }),
  {}
);

export const titleCase = (str = '') => str.replace(
  /\w\S*/g,
  (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
);

const warned = {};
export const warnOnce = (message) => {
  if (!warned[message]) {
    /* istanbul ignore else */
    if (typeof console !== 'undefined') {
      console.error(message); // eslint-disable-line no-console
    }
    warned[message] = true;
  }
};

export const deprecated = (propType, explanation) => (props, propName, componentName, ...rest) => {
  if (props[propName] !== null && typeof props[propName] !== 'undefined') {
    warnOnce(
      `"${propName}" property of "${componentName}" has been deprecated.\n${explanation}`
    );
  }

  return propType(props, propName, componentName, ...rest);
};

// In NODE_ENV=production the Transition.propTypes are wrapped which results in an
// empty object "{}". This is the result of the `react-transition-group` babel
// configuration settings. Therefore, to ensure that production builds work without
// error, we can either explicitly define keys or use the Transition.defaultProps.
// Using the Transition.defaultProps excludes any required props. Thus, the best
// solution is to explicitly define required props in our utilities and reference these.
// This also gives us more flexibility in the future to remove the prop-types
// dependency in distribution builds (Similar to how `react-transition-group` does).
// Note: Without omitting the `react-transition-group` props, the resulting child
// Tag component would inherit the Transition properties as attributes for the HTML
// element which results in errors/warnings for non-valid attributes.
export const factorOutTransitionProps = (allProps) => {
  // Duplicated Transition.propType keys to ensure that Reactstrap builds
  // for distribution properly exclude these keys for nested child HTML attributes
  // since `react-transition-group` removes propTypes in production builds.
  const transitionPropTypeKeys = [
    'in',
    'mountOnEnter',
    'unmountOnExit',
    'appear',
    'enter',
    'exit',
    'timeout',
    'onEnter',
    'onEntering',
    'onEntered',
    'onExit',
    'onExiting',
    'onExited',
  ];

  const transitionProps = {};
  const otherProps = {};

  Object.keys(allProps).forEach((key) => {
    if (transitionPropTypeKeys.indexOf(key) >= 0) {
      transitionProps[key] = allProps[key];
    } else {
      otherProps[key] = allProps[key];
    }
  });

  return { transitionProps, otherProps };
};

export const canUseDOM = !!(
  typeof window !== 'undefined'
  && window.document
  && window.document.createElement
);
