import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TableFoot = (props) => {
  const { className, ...attributes } = props;
  const computedClassName = classNames('table-foot', className);
  return <tfoot className={computedClassName} {...attributes} />;
};

TableFoot.propTypes = { className: PropTypes.string };
TableFoot.defaultProps = { className: '' };

TableFoot.displayName = 'TableFoot';

export default TableFoot;
