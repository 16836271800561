import React from 'react';
import Icon from '@prism/icon';
import PropTypes from 'prop-types';

const FullScreenToggle = ({

  isClosed,
  onToggle,
}) => {
  const glyph = isClosed ? 'enlarge' : 'shrink';

  return (
    <button
      type="button"
      className="no-button-styling media-button enlarge-button"
      onClick={onToggle}
    >
      <Icon glyph={glyph} />
    </button>
  );
};
FullScreenToggle.propTypes = {
  isClosed: PropTypes.bool,
  onToggle: PropTypes.func,
};
FullScreenToggle.defaultProps = {
  isClosed: true,
  onToggle: () => {},
};

export default FullScreenToggle;
