/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';

const SlideOutTrigger = (props) => {
  const { className, tag: Tag, text, ...attributes } = props;
  return (
    <Tag
      className={className}
      role="button"
      aria-label="slide-out-trigger"
      {...attributes}
    >
      {text}
    </Tag>
  );
};

SlideOutTrigger.propTypes = {
  className: PropTypes.string,
  tag: PropTypes.string,
  text: PropTypes.string,
};

SlideOutTrigger.defaultProps = {
  className: 'btn btn-secondary',
  tag: 'button',
  text: '',
};

export default SlideOutTrigger;
