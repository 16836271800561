import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { canUseDOM } from '../Utilities/utils';

const Portal = (props) => {
  const { children, node } = props;

  const defaultNode = useRef();

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      if (defaultNode.current) {
        document.body.removeChild(defaultNode.current);
      }
      defaultNode.current = null;
    };
  });

  if (!canUseDOM) {
    return null;
  }

  if (!node && !defaultNode.current) {
    const newNode = document.createElement('div', { ref: defaultNode });
    document.body.appendChild(newNode);
  }

  return ReactDOM.createPortal(
    children,
    node || defaultNode.current
  );
};

Portal.propTypes = {
  children: PropTypes.node.isRequired,
  node: PropTypes.node,
};

export default Portal;
