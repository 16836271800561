/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputGroupText from './InputGroupText';

const InputGroupAddon = (props) => {
  const {
    className,
    tag: Tag,
    addonType,
    children,
    ...attributes
  } = props;

  const classes = classNames(className, `input-group-${addonType}`);

  // Convenience to assist with transition
  if (typeof children === 'string') {
    return (
      <Tag {...attributes} className={classes}>
        <InputGroupText>{children}</InputGroupText>
      </Tag>
    );
  }

  return <Tag {...attributes} className={classes}>{children}</Tag>;
};

InputGroupAddon.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  addonType: PropTypes.oneOf(['prepend', 'append']).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

InputGroupAddon.defaultProps = {
  tag: 'div',
  children: null,
  className: '',
};

export default InputGroupAddon;
