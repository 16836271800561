/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { nanoid } from 'nanoid';
import { CardActions } from './CardActions';

const propTypes = {
  actionsLeft: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.node),
  ]),
  actionsRight: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  id: PropTypes.string,
  hasFooter: PropTypes.bool,
};

export const CardFooter = ({
  actionsLeft,
  actionsRight,
  hasFooter,
  id,
  className: propClassName,
}) => {
  if (!hasFooter) return null;
  const className = classnames('card-footer', propClassName);

  const leftProps = {
    show: 2,
    textOnly: true,
  };

  const rightProps = {
    show: 1,
    iconOnly: true,
  };

  return (
    <div className={className} id={id} role="toolbar">
      {actionsLeft ? (
        <CardActions
          actions={actionsLeft}
          id={`${id}-left-actions`}
          props={leftProps}
        />
      ) : null}
      {actionsLeft || actionsRight ? (
        <span className="card-footer-spacer" />
      ) : null}
      {actionsRight ? (
        <CardActions
          actions={actionsRight}
          id={`${id}-right-actions`}
          props={rightProps}
        />
      ) : null}
    </div>
  );
};

CardFooter.propTypes = propTypes;
CardFooter.displayName = 'CardFooter';
CardFooter.defaultProps = {
  actionsLeft: null,
  actionsRight: null,
  className: null,
  id: nanoid(),
  hasFooter: true,
};
export default CardFooter;
