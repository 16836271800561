/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  children: PropTypes.node,
  horizontal: PropTypes.bool,
};

export const CardLayout = ({ children, horizontal }) => {
  const className = classnames('card-layout', {
    'layout-vertical': horizontal !== true,
    'layout-horizontal': horizontal === true,
  });

  return <div className={className}>{children}</div>;
};

CardLayout.propTypes = propTypes;

CardLayout.defaultProps = {
  children: null,
  horizontal: false,
};
CardLayout.displayName = 'CardLayout';
export default CardLayout;
