import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import { factorOutTransitionProps } from '../Utilities/utils';

const Collapse = (props) => {
  const {
    tag: Tag,
    isOpen,
    className,
    navbar,
    children,
    innerRef,
    onEntering,
    onEntered,
    onExit,
    onExiting,
    onExited,
    ...otherProps
  } = props;
  const [height, setHeight] = useState(null);
  const transitionStatusToClassHash = {
    entering: 'collapsing',
    entered: 'collapse show',
    exiting: 'collapsing',
    exited: 'collapse',
  };

  const getTransitionClass = (status) => transitionStatusToClassHash[status] || 'collapse';

  const handleEntering = (node, isAppearing) => {
    setHeight(node.scrollHeight);
    onEntering(node, isAppearing);
  };

  const handleEntered = (node, isAppearing) => {
    setHeight(null);
    onEntered(node, isAppearing);
  };

  const handleExit = (node) => {
    setHeight(node.scrollHeight);
    onExit(node);
  };

  const handleExiting = (node) => {
    setHeight(0);
    onExiting(node);
  };

  const handleExited = (node) => {
    setHeight(null);
    onExited(node);
  };

  const { transitionProps, otherProps: childProps } = factorOutTransitionProps(otherProps);

  return (
    <Transition
      {...transitionProps}
      in={isOpen}
      onEntering={handleEntering}
      onEntered={handleEntered}
      onExit={handleExit}
      onExiting={handleExiting}
      onExited={handleExited}
    >
      {(status) => {
        const collapseClass = getTransitionClass(status);
        const classes = classNames(className, collapseClass, navbar && 'navbar-collapse');
        const style = height === null ? null : { height };
        return (
          <Tag
            aria-label="Accordion Collapse"
            {...childProps}
            style={{ ...childProps.style, ...style }}
            className={classes}
            ref={innerRef}
          >
            {children}
          </Tag>
        );
      }}
    </Transition>
  );
};

Collapse.propTypes = {
  ...Transition.propTypes,
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.node,
  navbar: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.object,
  ]),
};

Collapse.defaultProps = {
  ...Transition.defaultProps,
  isOpen: false,
  appear: false,
  enter: true,
  exit: true,
  tag: 'div',
  timeout: 350, // matches $transition-collapse (bootstrap _variables.scss)
};

export default Collapse;
