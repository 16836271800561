import React from 'react';
import { v4 as uuid } from 'uuid';
import {
  CarouselProvider,
  Slide,
  Slider,
  WithStore,
} from 'pure-react-carousel';
import { PropTypes } from 'prop-types';
import { MultiMediaViewerConsumer } from '../MultimediaViewer';
import {
  numberOf,
  totalSlides,
  trayProps,
} from './utils';
import { MediaAssetsType } from '../../types';
import { Provider as ImageViewerProvider } from './ImageViewerContext';
import BackButton from './BackButton';
import NextButton from './NextButton';
import MediaCountWithCarouselState from './MediaCount';
import OSDWrapper from './OSDWrapper';
import Fyusion from './Fyusion';
import VideoViewer from './VideoViewer';
import Audio from './Audio';
import MediaNotAvailable from './MediaNotAvailable';
import LoadingScreen from './LoadingScreen';

const ImageViewer = React.forwardRef((props, ref) => {
  const {
    mediaAssets: propMediaAssets,
    carouselStore,
    fullScreenHidden,
    handleFullScreenClick,
    handleExitFullScreenClick,
    customButtons,
    zoomMax,
    showFullScreenControls,

  } = props;
  const mediaAssetsExist = propMediaAssets.length > 0;
  const multipleMediaAssetsExist = propMediaAssets.length > 1;

  const mediaAssets = propMediaAssets.map((asset) => ({
    ...asset,
    id: asset.id || uuid(),
  }));
  const imageViewerCarouselStore = carouselStore;

  React.useEffect(() => {
    const script = document.createElement('link');
    script.href = 'https://cdn.fyusion.com/0/sdk/web/mh/viewer-0.9.0.min.css';
    document.body.appendChild(script);
  }, []);

  const MediaNotAvailableSlider = () => (
    <Slide className="imageviewer-slide" index={0}>
      <MediaNotAvailable
        classNamePrefix="imageviewer-media"
        mediaType="Media"
        iconType="image"
      />
    </Slide>
  );

  const getMediaSlide = (mediaAsset, index) => {
    const { currentSlide } = carouselStore.getStoreState();
    const onCurrentSlide = index === currentSlide;
    const isFyusion = !!mediaAsset.src[360];
    const isVideo = !!mediaAsset.src.video;
    const isAudio = !!mediaAsset.src.audio;
    const isMedia = isFyusion || isVideo || isAudio;
    const isHighRes = !!mediaAsset.src.highRes && !isMedia;
    const isLowRes = !!mediaAsset.src.lowRes && !isMedia;
    const isPhoto = isHighRes || isLowRes;
    const isLoadable = isFyusion || isPhoto;

    if (!onCurrentSlide) return null;

    const Loader = () => {
      if (!isLoadable) return null;
      return (
        <LoadingScreen classNamePrefix="imageviewer" text="Loading..." />
      );
    };

    const Viewer = () => {
      if (isHighRes || isLowRes) {
        const src = mediaAsset.src.highRes || mediaAsset.src.lowRes;
        return (
          <OSDWrapper
            className="imageviewer-high-res"
            dzi={isHighRes}
            src={src}
            zoomMax={zoomMax || 2}
          />
        );
      }

      if (isFyusion) {
        return (
          <Fyusion
            id={mediaAsset.src[360]}
            filmstripThumbnail={mediaAsset.src.thumbnail}
          />
        );
      }
      if (isVideo) {
        return (
          <VideoViewer
            src={mediaAsset.src.video}
            currentSlide={currentSlide}
            totalSlides={mediaAssets.length}
            fullscreenHidden={fullScreenHidden}
            handleFullScreenClick={handleFullScreenClick}
          />
        );
      }
      if (isAudio) {
        return (
          <Audio
            mediaAsset={mediaAsset.src}
            currentSlide={currentSlide}
            totalSlides={mediaAssets.length}
            fullscreenHidden={fullScreenHidden}
            handleFullScreenClick={handleFullScreenClick}
          />
        );
      }
      return null;
    };

    return (
      <>
        <Loader />
        <Viewer />
      </>
    );
  };

  return (
    <MultiMediaViewerConsumer>
      {({ testLoad, updateCurrentSlide }) => (
        <div
          className="imageviewer"
          ref={ref}
          onLoad={testLoad({ imageViewerCarouselStore })}
        >
          <div className="imageviewer-multimediaviewerarea">
            <Slider
              ref={ref}
              disableKeyboard
              moveThreshold={1.5}
              trayProps={trayProps(updateCurrentSlide, imageViewerCarouselStore)}
            >
              {!mediaAssetsExist ? (
                <MediaNotAvailableSlider />
              ) : mediaAssets.map((mediaAsset, index) => (
                <Slide
                  className="imageviewer-slide"
                  index={index}
                  key={`${mediaAsset.id}-imgv`}
                >
                  {getMediaSlide(mediaAsset, index)}
                </Slide>
              ))}
            </Slider>

            <BackButton
              controlsHidden={false}
              carouselStore={carouselStore}
              multipleMediaAssetsExist={multipleMediaAssetsExist}
            />
            <NextButton
              controlsHidden={false}
              carouselStore={carouselStore}
              multipleMediaAssetsExist={multipleMediaAssetsExist}
            />
            <MediaCountWithCarouselState
              mediaAssets={mediaAssets}
              controlsHidden={!multipleMediaAssetsExist}
              mediaAssetsExist={mediaAssetsExist}
              fullScreenHidden={fullScreenHidden}
              handleFullScreenClick={handleFullScreenClick}
              handleExitFullScreenClick={handleExitFullScreenClick}
              customButtons={customButtons}
              showFullScreenControls={showFullScreenControls}
            />
          </div>
        </div>
      )}
    </MultiMediaViewerConsumer>
  );
});

const ImageViewerWithCarousel = (props) => {
  const { mediaAssets } = props;

  const mediaAssetsExist = Boolean(numberOf(mediaAssets));

  const multipleMediaAssetsExist = numberOf(mediaAssets) > 1;

  const imageViewerProps = {
    mediaAssets,
    mediaAssetsExist,
    multipleMediaAssetsExist,
    ...props,
  };

  const ImageViewerWithCarouselState = WithStore(ImageViewer);

  return (
    <ImageViewerProvider>
      <CarouselProvider
        totalSlides={totalSlides(mediaAssets)}
        naturalSlideWidth={96}
        naturalSlideHeight={72}
        visibleSlides={1}
        dragEnabled={false}
        infinite="true"
        className="imageviewer-carousel-provider"
      >
        <ImageViewerWithCarouselState {...imageViewerProps} />
      </CarouselProvider>
    </ImageViewerProvider>
  );
};

ImageViewer.defaultProps = {
  mediaAssets: [],
  carouselStore: {},
  fullScreenHidden: true,
  handleFullScreenClick: () => {},
  handleExitFullScreenClick: () => {},
  customButtons: [],
  zoomMax: 2,
  showFullScreenControls: false,
};

ImageViewer.propTypes = {
  mediaAssets: PropTypes.arrayOf(MediaAssetsType),
  carouselStore: PropTypes.shape({ getStoreState: PropTypes.func }),
  fullScreenHidden: PropTypes.Bool,
  handleFullScreenClick: PropTypes.func,
  handleExitFullScreenClick: PropTypes.func,
  customButtons: PropTypes.arrayOf(PropTypes.shape({})),
  zoomMax: PropTypes.number,
  showFullScreenControls: PropTypes.Bool,
};

ImageViewerWithCarousel.propTypes = { mediaAssets: MediaAssetsType };
ImageViewerWithCarousel.defaultProps = { mediaAssets: [] };
export default ImageViewerWithCarousel;
